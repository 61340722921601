import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

import ns1 from "./locales/es/translation.json";
import ns2 from "./locales/en/translation.json";

export const defaultNS = "ns1";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "es",
    debug: false,
    defaultNS,
    fallbackLng: "es",
    resources: {
      es: {
        ns1: ns1,
      },
      en: {
        ns1: ns2,
      },
    },
  });
export default i18n;
