import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Styles from "./c-side-menu.module.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectMenu, selectMenuItem } from "../../store/slices/menuSlice";

import Logo from "../../assets/logo@3x.png";
import PersonImg from "../../assets/LoginImg.png"
import Dashboard from "../../assets/icon-dashboard.svg";
import DashboardBlue from "../../assets/icon-dashboard-blue.svg";
import CreateTravelAgent from "../../assets/icon-add.svg";
import CreateTravelAgentBlue from "../../assets/icon-add-blue.svg";
import ManagePassengers from "../../assets/icon-new-passenger.svg";
import ManagePassengersBlue from "../../assets/icon-new-passenger-blue.svg";
import ScheduleExports from "../../assets/icon-schedule-excel.svg";
import ScheduleExportsBlue from "../../assets/icon-schedule-excel-blue.svg";
import DataReport from "../../assets/icon-schedule-excel.svg";
import DataReportBlue from "../../assets/icon-schedule-excel-blue.svg";
import Calendar from "../../assets/icon-calendar.svg";
import CalendarBlue from "../../assets/icon-calendar-blue.svg";
import Coclis from "../../assets/icon-swift.svg";
import CoclisBue from "../../assets/icon-swift-blue.svg";
import LogOutIcon from "../../assets/icon-logout.svg";
import Auth from "../../services/auth/s-auth";
import User from "../../services/utils/s-user";

export default function CSideMenu(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const instanceUser = new User();
  const menu = useAppSelector(selectMenu);
  const [operationSelected, setOperationSelected] = useState(1);
  const [usuario, setUsuario] = useState<any>("");

  const setMenu = async (item: any) => {
    const user = instanceUser.getUser();

    if(item !== operationSelected){
      props.back(false);
    }

    await setOperationSelected(item);
    dispatch(selectMenuItem(item));

    
    if(item === 1 && user.rol === "ROL.SUPER_ADMIN.NAME"){
      navigate('/dashboard')
    }else if(item === 2 && user.rol === "ROL.SUPER_ADMIN.NAME"){
      navigate('/create-ta')
    }else if(item === 3 && user.rol === "ROL.SUPER_ADMIN.NAME"){
      navigate('/manage-passengers')
    }else if(item === 4 && user.rol === "ROL.SUPER_ADMIN.NAME"){
      navigate('/schedules')
    }else if(item === 5 && user.rol === "ROL.SUPER_ADMIN.NAME"){
      navigate('/travel-agents')
    }else if(item === 6 && user.rol === "ROL.SUPER_ADMIN.NAME"){
      navigate('/coclis')
    }else if(item === 1 && user.rol === "ROL.TRAVEL_AGENT.NAME"){
      navigate('/dashboard-ta')
    }else if(item === 2 && user.rol === "ROL.TRAVEL_AGENT.NAME"){
      navigate('/new-passenger')
    }else if(item === 4 && user.rol === "ROL.TRAVEL_AGENT.NAME"){
      navigate('/report')
    }
  };

  const returnRoute = (ruta: string) => {
    switch(ruta){
      case "/dashboard":
      case "/dashboard-ta": {
        return 1;
      }
      case "/create-ta":
      case "/new-passenger": {
        return 2;
      }
      case "/manage-passengers": {
        return 3;
      }
      case "/report":
      case "/schedules": {
        return 4;
      }
      case "/travel-agents":
        return 5;
      case "/coclis":
        return 6;
    }
    return 1;
  }

  const handleBack = () => {
    window.addEventListener("popstate", function(event) {
      setOperationSelected(returnRoute(window.location.pathname));
    });
  }

  const doLogout = () => {
    Auth.doLogout(navigate);
  }

  useEffect(() => {
    if(!instanceUser.getUser()){
      const user = JSON.parse(localStorage.getItem("user")  || '');
      instanceUser.setUser(user);
      setUsuario(user);
    }
    setOperationSelected(props.menuId);
    handleBack();
  }, [menu])
  
  return (
    <div className={Styles.c_side_menu}>
      <div className={Styles.c_side_menu_group}>
        <div className={Styles.c_side_menu_group_top}>
          <img src={Logo} alt="Logo" className={Styles.c_side_menu_logo} />
          <div className={Styles.c_side_menu_items}>
            {props.values.operations.map((operation: any) => (
              <div
                key={operation.position}
                className={
                  operationSelected === operation.position
                    ? Styles.c_side_menu_item_selected
                    : Styles.c_side_menu_item
                }
                onClick={() => {
                  setMenu(operation.position);
                }}
              >
                {operationSelected === operation.position ? (
                  <ReactSVG
                    src={
                      operation.icon === "Dashboard"
                        ? CalendarBlue
                        : operation.icon === "CreateTravelAgent"
                        ? CreateTravelAgentBlue
                        : operation.icon === "ManagePassengers"
                        ? DashboardBlue
                        : operation.icon === "ManageTravelAgents"
                        ? ManagePassengersBlue
                        : operation.icon === "ManageCoclis"
                        ? CoclisBue
                        : operation.icon === "ScheduleExports"
                        ? ScheduleExportsBlue
                        : operation.icon === "DataReport"
                        ? DataReportBlue
                        : DashboardBlue
                    }
                    className={Styles.c_side_menu_icon}
                  />
                ) : (
                  <ReactSVG
                    src={
                      operation.icon === "Dashboard"
                        ? Calendar
                        : operation.icon === "CreateTravelAgent"
                        ? CreateTravelAgent
                        : operation.icon === "ManagePassengers"
                        ? Dashboard
                        : operation.icon === "ManageTravelAgents"
                        ? ManagePassengers
                        : operation.icon === "ManageCoclis"
                        ? Coclis
                        : operation.icon === "ScheduleExports"
                        ? ScheduleExports
                        : operation.icon === "DataReport"
                        ? DataReport
                        : Dashboard
                    }
                    className={Styles.c_side_menu_icon}
                  />
                )}
                
                {operation.name === "SIDE_MENU.DASHBOARD" ? (
                  <p>{t("SIDE_MENU.DASHBOARD")}</p>
                ) : operation.name === "SIDE_MENU.DASHBOARD_TA" ? (
                  <p>{t("SIDE_MENU.DASHBOARD_TA")}</p>
                ) : operation.name === "SIDE_MENU.CREATE_TRAVEL_AGENT" ? (
                  <p>{t("SIDE_MENU.CREATE_TRAVEL_AGENT")}</p>
                ) : operation.name === "SIDE_MENU.MANAGE_PASSENGERS" ? (
                  <p>{t("SIDE_MENU.MANAGE_PASSENGERS")}</p>
                ) : operation.name === "SIDE_MENU.MANAGE_COCLIS" ? (
                  <p>{t("SIDE_MENU.MANAGE_COCLIS")}</p>
                ) : operation.name === "SIDE_MENU.MANAGE_TRAVEL_AGENTS" ? (
                  <p>{t("SIDE_MENU.MANAGE_TRAVEL_AGENTS")}</p>
                ) : operation.name === "SIDE_MENU.SCHEDULE_EXPORTS" ? (
                  <p>{t("SIDE_MENU.SCHEDULE_EXPORTS")}</p>
                ) : operation.name === "SIDE_MENU.DATA_REPORT" ? (
                  <p>{t("SIDE_MENU.DATA_REPORT")}</p>
                ) : operation.name === "SIDE_MENU.NEW_PASSENGER" ? (
                  <p>{t("SIDE_MENU.NEW_PASSENGER")}</p>
                ) : (
                  <p>...</p>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className={Styles.c_side_menu_group_down}>
          <img src={PersonImg} className={Styles.c_side_menu_group_down_image} alt=""/>
          <span className={Styles.c_side_menu_group_down_log_out_group} onClick={() => {doLogout()}}>
            <ReactSVG src={LogOutIcon} />
            <p>{t("SIDE_MENU.LOGOUT")}</p>
          </span>
        </div>
      </div>
    </div>
  );
}
