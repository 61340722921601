import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Styles from "./v-create-travel-agent.module.css";
import CTextInput from "../../components/c-text-input/c-text-input";
import { CButton } from "../../components/c-button/c-button";

import addIcon from "../../assets/icon-add.svg";

import TravelAgent from "../../services/travel-agent/s-travel-agent";
import { TravelAgentModel } from "../../model/m-travel-agent";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { selectMenu, selectMenuItem } from "../../store/slices/menuSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";


export default function VCreateTravelAgent(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const menu = useAppSelector(selectMenu);
  const dispatch = useAppDispatch();
  
  const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
  const nifFormat = /^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$/;
  const alphaFormat = /^([a-zA-Z ]+)$/
  const alphaNumericFormat = /^([a-zA-Z0-9 ]+)$/
  const [clientName, setClientName] = useState("");
  const [nif, setNif] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");

  const [formatEmailError, setFormatEmailError] = useState(false);
  const [formatNifError, setFormatNifError] = useState(false);
  const [formatNameCError, setFormatNameCError] = useState(false);
  const [formatNameError, setFormatNameError] = useState(false);
  const [formatSurnameError, setFormatSurnameError] = useState(false);
  
  const loading = useRef(true);
  const [travelAgent, setTravelAgent] = useState(new TravelAgentModel());
  const [fields, setFields] = useState<any>([]);
  const [taData, setTAData] = useState<any>([]);
  const [cocliList, setCocliList] = useState<any>([]);
  const [cocliListCodes, setCocliListCodes] = useState<any>([]);
  const [allCoclis, setAllCoclis] = useState<any>(false);
  const [cocliListNames, setCocliListNames] = useState<any>([]);

  const saveData = () => {
    travelAgent.email = email;
    travelAgent.name = name;
    travelAgent.lastname = surname;
    travelAgent.organization_cifnif = nif;
    travelAgent.organization_name = clientName; 
  }

  
  const createAgent = () => {
    
    saveData();
    props.openModal(true, "modalCreateTravelAgent",  
    {
      frequency: {}, 
      hour: {},       
      travel_agent: travelAgent,   
      fields: fields
    }, false,
    () => {
      TravelAgent.create(travelAgent, fields, taData, navigate).then((resp: any) => {
        props.openModal(false, "modalCreateTravelAgent");
        props.openModal(true, "modalResponseSuccess", null, false, () => {
          if(menu !== 1){
            dispatch(selectMenuItem(1));
            navigate('/dashboard');
          } 
          props.openModal(false, "modalResponseSuccess");
        }, null, false, null, resp.result.info);
      }).catch((err: any) => {
        props.openModal(false, "modalCreateTravelAgent");
        props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
      })
    });
  };

  const handleMandatory = (value: any) => {
    let tmpTAData: any = taData;
    if(!value.mandatory && tmpTAData){
      tmpTAData = tmpTAData.map((ta: any) => {
        if(ta.field_id === value.id){
          ta.mandatory = !ta.mandatory;
          ta.hide = false;
        }
        return ta;
      }); 
    }
    setTAData(tmpTAData);
  }
  
  const handleHide = (value: any) => {
    if(!value.mandatory && taData){
      let tmpTAData: any = taData;
      tmpTAData = tmpTAData.map((ta: any) => {                      
        if(ta.field_id === value.id){
          if(!ta.mandatory){
            ta.hide = !ta.hide;
          }
        }
        return ta;
      });
      setTAData(tmpTAData);
    }
  }

  const handleMultiSelect = (value: any) => {
    if(taData){
      let tmpTAData: any = taData;
      tmpTAData = tmpTAData.map((ta: any) => {                      
        if(ta.field_id === value.id){
          ta.multiselect = !ta.multiselect;
        }
        return ta;
      });
      setTAData(tmpTAData);
    }
  }

  const updateEmail = (data: any) => {
    setEmail(data);
    if (!emailFormat.test(data)){
      setFormatEmailError(true);
    }else{
      setFormatEmailError(false);
    }
  }
  
  const updateNif = (data: any) => {
    setNif(data);
    if (!nifFormat.test(data)){
      setFormatNifError(true);
    }else{
      setFormatNifError(false);
    }
  }

  const updateClientName = (data: any) => {
    setClientName(data);
    if (!alphaNumericFormat.test(data)){
      setFormatNameCError(true);
    }else{
      setFormatNameCError(false);
    }
  }

  const updateName = (data: any) => {
    setName(data);
    if (!alphaFormat.test(data)){
      setFormatNameError(true);
    }else{
      setFormatNameError(false);
    }
  }
  
  const updateSurname = (data: any) => {
    setSurname(data);
    if (!alphaFormat.test(data)){
      setFormatSurnameError(true);
    }else{
      setFormatSurnameError(false);
    }
  }
  
  const setLoading = (load: boolean) => {
    loading.current = load;
  };
  
  useEffect(() => {
    if(loading.current){
      setLoading(false);
      TravelAgent.getFields(navigate).then((resp: any) => {
        resp.data.forEach((value: any) => {
          let tmpTAData: any = taData;
          if(tmpTAData){
            tmpTAData.push({
              field_id: value.id,
              coclis: [],
            });
          }
          setTAData(tmpTAData);
        });
        setFields(resp.data);
      }).catch((err: any) => {
        props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, "RESPONSE.ERROR");
      });      
    }

  },[travelAgent]);

  return (
    <div className={Styles.v_create_travel_agent}>
      <div className={Styles.v_create_travel_agent_title_group}>
        <h1 className={Styles.v_create_travel_agent_title}>
          {t("CREATE_TRAVEL_AGENT.FIRST_FORM_TITLE")}
        </h1>
        <p className={Styles.v_create_travel_agent_subtitle}>
          {t("CREATE_TRAVEL_AGENT.FIRST_FORM_SUBTITLE")}
        </p>
      </div>
      <div className={Styles.v_create_travel_agent_form}>
        <div className={Styles.v_create_travel_agent_form_group}>
          <div className={Styles.v_create_travel_agent_input_group}>
            <label
              className={Styles.v_create_travel_agent_input_label}
              htmlFor="client-name"
            >
              {t("CREATE_TRAVEL_AGENT.FIRST_FORM_CLIENT_LABEL")}
            </label>
            <CTextInput
              id="client-name"
              type="text"
              placeholder={t("CREATE_TRAVEL_AGENT.FIRST_FORM_CLIENT_LABEL")}
              value={clientName}
              setValue={updateClientName}
              failed={formatNameCError}
              failedText={t("CREATE_TRAVEL_AGENT.FORM_FORMAT_ERROR")}
            />
          </div>
          <div className={Styles.v_create_travel_agent_input_group}>
            <label
              className={Styles.v_create_travel_agent_input_label}
              htmlFor="nif"
            >
              {t("CREATE_TRAVEL_AGENT.FIRST_FORM_NIF_CIF")}
            </label>
            <CTextInput
              id="nif"
              type="text"
              placeholder="NIF / CIF"
              value={nif}
              setValue={updateNif}
              failed={formatNifError}
              failedText={t("CREATE_TRAVEL_AGENT.FORM_FORMAT_ERROR")}
            />
          </div>
        </div>
        <div className={Styles.v_create_travel_agent_form_group}>
          <div className={Styles.v_create_travel_agent_input_group}>
            <label
              className={Styles.v_create_travel_agent_input_label}
              htmlFor="name"
            >
              {t("CREATE_TRAVEL_AGENT.FIRST_FORM_NAME_LABEL")}
            </label>
            <CTextInput
              id="name"
              type="text"
              placeholder={t("CREATE_TRAVEL_AGENT.FIRST_FORM_NAME_LABEL")}
              value={name}
              setValue={updateName}
              failed={formatNameError}
              failedText={t("CREATE_TRAVEL_AGENT.FORM_FORMAT_ERROR")}
            />
          </div>
          <div className={Styles.v_create_travel_agent_input_group}>
            <label
              className={Styles.v_create_travel_agent_input_label}
              htmlFor="surname"
            >
              {t("CREATE_TRAVEL_AGENT.FIRST_FORM_SURNAME_LABEL")}
            </label>
            <CTextInput
              id="surname"
              type="text"
              placeholder={t("CREATE_TRAVEL_AGENT.FIRST_FORM_SURNAME_LABEL")}
              value={surname}
              setValue={updateSurname}
              failed={formatSurnameError}
              failedText={t("CREATE_TRAVEL_AGENT.FORM_FORMAT_ERROR")}
            />
          </div>
        </div>
        <div className={Styles.v_create_travel_agent_form_group}>
          <div className={Styles.v_create_travel_agent_input_group}>
            <label
              className={Styles.v_create_travel_agent_input_label}
              htmlFor="email"
            >
              {t("CREATE_TRAVEL_AGENT.FIRST_FORM_EMAIL_LABEL")}
            </label>
            <CTextInput
              id="email"
              type="email"
              placeholder={t("CREATE_TRAVEL_AGENT.FIRST_FORM_EMAIL_LABEL")}
              value={email}
              setValue={updateEmail}
              failed={formatEmailError}
              failedText={t("CREATE_TRAVEL_AGENT.FORM_FORMAT_ERROR")}
            />
          </div>
        </div>
      </div>
      <div className={Styles.v_create_travel_agent_title_group}>
        <h1 className={Styles.v_create_travel_agent_title}>
          {t("CREATE_TRAVEL_AGENT.SECOND_FORM_TITLE")}
        </h1>
        <p className={Styles.v_create_travel_agent_subtitle}>
          {t("CREATE_TRAVEL_AGENT.SECOND_FORM_SUBTITLE")}
        </p>
      </div>
      <div className={Styles.v_create_travel_agent_data_form}>
        <p
          className={`${Styles.v_create_travel_agent_data_form_field_column},
          ${Styles.v_create_travel_agent_data_form_title}`}
        >
          {t("CREATE_TRAVEL_AGENT.FORM_COLUMN_FIELD_TITLE_TEXT")}
        </p>
        <p
          className={`${Styles.v_create_travel_agent_data_form_content_column},
          ${Styles.v_create_travel_agent_data_form_title}`}
        >
          {t("CREATE_TRAVEL_AGENT.FORM_COLUMN_CONTENT_TITLE_TEXT")}
        </p>
        <p
          className={`${Styles.v_create_travel_agent_data_form_mandatory_column}, ${Styles.v_create_travel_agent_data_form_title}
          `}
        >
          {t("CREATE_TRAVEL_AGENT.FORM_COLUMN_MANDATORY_TITLE_TEXT")}
        </p>
        <p
          className={`${Styles.v_create_travel_agent_data_form_hide_field_column}, ${Styles.v_create_travel_agent_data_form_title}
          `}
        >
          {t("CREATE_TRAVEL_AGENT.FORM_COLUMN_HIDE_FIELD_TITLE_TEXT")}
        </p>
        <p
          className={`${Styles.v_create_travel_agent_data_form_multiselect_field_column}, ${Styles.v_create_travel_agent_data_form_title}
          `}
        >
          {t("CREATE_TRAVEL_AGENT.FORM_COLUMN_MULTISELECT_FIELD_TITLE_TEXT")}
        </p>
      </div>
    
      {fields.map((value: any, index: any) => {
        return (
          <div
            key={value.id}  
            className={Styles.v_create_travel_agent_data_form_item_group}
          >
            <div
              className={Styles.v_create_travel_agent_data_form_field_column_item}
            >
              {value.hasContent ?
              <Tooltip title={t('CREATE_TRAVEL_AGENT.TOOLTIP')}>
                <p>{value.mandatory ? `${value.name}*`  : value.name}</p>
              </Tooltip>
              : 
                <p>{value.mandatory ? `${value.name}*`  : value.name}</p>
              }
            </div>
            {value.cocli ?
            <div className={Styles.v_create_travel_agent_data_form_content_column_item}>
              <div className={Styles.v_create_travel_agent_data_form_content_column_item_values}>
                <CButton 
                  placeholder={t("MANAGE_COCLIS.LIST.BTN_ASOC_COCLI")} 
                  type="secondary"
                  height={36} 
                  width={155}
                  icon={addIcon} 
                  action={() => {
                    props.openModal(true, "selectCocli", 
                    {
                      cocliList: taData ? taData.find((ta: any) => ta.field_id === value.id).coclis : [], 
                      allCoclis: taData ? taData.find((ta: any) => ta.field_id === value.id).all_coclis : false,
                      cocliListObjs: taData ? taData.find((ta: any) => ta.field_id === value.id).cocliListObjs : [], 
                      multiselect: true
                    }, false, (allCoclis: any, cocliList: any, cocliCodes: any) => {
                      
                      if(taData){
                        let tmpTAData = taData;
                        tmpTAData = tmpTAData.map((ta: any) => {
                          if(ta.field_id === value.id){
                            ta.coclis = cocliList;
                            ta.all_coclis = allCoclis;
                            let tmp = cocliCodes;
                            ta.cocliListObjs = tmp;
                            tmp = tmp.map((c: any) => c.name);
                            let tmpNames = tmp.join('@@');
                            ta.cocliListNames = tmpNames;
                            if(tmpNames.length > 30){
                              tmpNames = tmpNames.substring(0,30).concat('...');
                            }
                            ta.cocliListCodes = tmpNames;
                          }
                          return ta;
                        });    
                        setTAData(tmpTAData);
                      }
                      props.openModal(false, "selectCocli");
                    }, 
                    () => {
                      props.openModal(false, "selectCocli");
                    }, false, null, null);
                  }}
                />
               <input
                  className="appearance-none border rounded-[10px] w-full h-[36px] py-2 px-3 text-[#1b1b1e] leading-tight focus:outline-none focus:shadow-outline text-[14px] font-normal"
                  type="text"
                  title={taData ? taData.find((ta: any) => ta.field_id === value.id).cocliListNames : ''}
                  placeholder={value.name}
                  value={taData ? taData.find((ta: any) => ta.field_id === value.id).cocliListCodes : ''}
                  disabled
                  readOnly
                />
              </div>
            </div>
            : value.hasContent ?
              <div
                className={
                  Styles.v_create_travel_agent_data_form_content_column_item
                }
              >
                <input
                  className="appearance-none border rounded-[10px] w-full h-[30px] py-2 px-3 text-[#1b1b1e] leading-tight focus:outline-none focus:shadow-outline text-[14px] font-normal"
                  type="text"
                  placeholder={t('CREATE_TRAVEL_AGENT.PLACEHOLDER')}
                  value={taData ? taData.find((ta: any) => ta.field_id === value.id).content : ''}
                  onChange={(e: any) => {
                    if(taData){
                      let tmpTAData = taData;
                      tmpTAData = tmpTAData.map((ta: any) => {
                        if(ta.field_id === value.id){
                          ta.content = e.target.value;
                        }
                        return ta;
                      });    
                      setTAData(tmpTAData);
                    }
                  }}
                />
              </div>
              : null
            }
            <div
              className={
                Styles.v_create_travel_agent_data_form_mandatory_column_item
              }
            >
              <input
                type="checkbox"
                className="accent-[#3498f5] h-[16px] w-[16px]"
                checked={
                  value.mandatory ?
                  true
                  :
                  (taData ? taData.find((ta: any) => ta.field_id === value.id).mandatory : false)
                }
                disabled={value.mandatory}
                onChange={() => {handleMandatory(value)}}              
                />
              {t("CREATE_TRAVEL_AGENT.FORM_CHECKBOX_TEXT")}
            </div>
            <div
              className={
                Styles.v_create_travel_agent_data_form_hide_field_column_item
              }
            >
              <input
                type="checkbox"
                className="accent-[#3498f5] h-[16px] w-[16px]"
                checked={taData ? taData.find((ta: any) => ta.field_id === value.id).hide : false}
                disabled={value.mandatory ? true : (taData ? taData.find((ta: any) => ta.field_id === value.id).mandatory : false)}
                onChange={() => {handleHide(value)}} 
              />
              {t("CREATE_TRAVEL_AGENT.FORM_CHECKBOX_TEXT")}
            </div>
            {value.hasContent &&
              <div
                className={
                  Styles.v_create_travel_agent_data_form_multiselect_field_column_item
                }
              >
                <input
                  type="checkbox"
                  className="accent-[#3498f5] h-[16px] w-[16px]"
                  checked={taData ? taData.find((ta: any) => ta.field_id === value.id).multiselect : false}
                  disabled={
                    value.cocli && 
                    ((taData && taData.find((ta: any) => ta.field_id === value.id).coclis.length > 1) || 
                    taData.find((ta: any) => ta.field_id === value.id).all_coclis) ? false :
                    (!taData.find((ta: any) => ta.field_id === value.id).content && 
                    taData.find((ta: any) => ta.field_id === value.id).content === '' ? 
                    true 
                    : 
                    (taData.find((ta: any) => ta.field_id === value.id).content && 
                    taData.find((ta: any) => ta.field_id === value.id).content.split(',').length > 1 ? false : true))}

                  onChange={() => {handleMultiSelect(value)}} 
                />
                {t("CREATE_TRAVEL_AGENT.FORM_CHECKBOX_TEXT")}
              </div>
            }
          </div>
        )
      })}
      <div className={Styles.v_create_travel_agent_data_form_button}>
        {clientName &&
        nif &&
        name &&
        surname &&
        email &&
        !formatNifError &&
        !formatNameError &&
        !formatSurnameError &&
        !formatEmailError ? (
          <CButton placeholder={t("CREATE_TRAVEL_AGENT.FORM_BUTTON")} action={createAgent}/>
        ) : (
          <CButton placeholder={t("CREATE_TRAVEL_AGENT.FORM_BUTTON")} type="disabled" />
        )}
      </div>
    </div>
  );
}

const twStyles = {
  v_create_travel_agent_data_form_content_column_item_values: 'md:w-[140px] w-[155px]',
}