import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Styles from "./v-download-template.module.css";
import { CButton } from "../../../components/c-button/c-button";

import downloadIcon from "../../../assets/icon-download.svg"

import Exports from "../../../services/exports/s-exports";
import User from "../../../services/utils/s-user";
import { useNavigate } from "react-router-dom";

export default function VDownloadTemplate(props: any) {
    const instanceUser = new User();
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [user, setUser] = useState<any>({});
    const [loading, setLoading] = useState(true);
    
    const generateExcelTemplate = () => {
        Exports.generateExcelTemplate(user.organizations[0].id, navigate)
        .then(resp => {
            props.download();
        })
        .catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        if(loading){
            setUser(instanceUser.getUser());
            setLoading(false);
        }
    }, [loading, instanceUser]);

  return (
    <div>
      {!loading && 
        <div className={Styles.v_download_template}>
          <div className={Styles.v_download_template_group}>
            <label className={Styles.v_download_template_title}>
              {t("DASHBOARD_TA.TITLE")}
            </label>
            <CButton 
                placeholder={t("MANAGE_PASSENGERS.LIST.BTN_DOWNLOAD_EXCEL")} 
                type="secondary" 
                height={36} 
                icon={downloadIcon}
                action={() => {generateExcelTemplate()}}
            />
            </div>
        </div>
      }
    </div>
  );
}
