import axios from 'axios';
import {BASE_URL, TIMEOUT} from '../utils/s-constants';
class Profile {
    static uploadProfileImg = (img: any, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };
        
        let formData = new FormData();
        formData.append("img", img);

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: '/api/v1/auth/profile/picture',
            method: 'POST',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: 15000,
            maxBodyLength: Infinity,
            data: formData,
            })
            .then(async req => {
                const {data} = req;
                resolve(data);
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    };
    static getAvatar = (navigate: any) => {

      let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

      return new Promise((resolve, reject) => {
      axios
          .create()
          .request({
          url: '/api/v1/auth/profile/picture',
          method: 'GET',
          baseURL: BASE_URL,
          headers: newHeaders,
          timeout: TIMEOUT,
          responseType: 'arraybuffer'
          })
          .then(async req => {
            const base64 = btoa(
              new Uint8Array(req.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
            ));
            resolve(base64);
          })
          .catch(error => {
              if(error.response){
                  if(error.response.status === 401){
                      localStorage.clear();
                      return navigate('/')
                  }
                  else if (error.response.status === 500){
                      return navigate('/500')
                  }
              }
              reject({error});
          });
      });
  };
}
export default Profile;