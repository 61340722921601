export interface UserData {
  id?: string;
  email?: string
  name?: string;
  lastname?: string;
  rol?: string;
  operations?: Operations;
  organizations?: Organizations;
  language?: Language;
  lastUploadStatus?: number
  lastUpload?: string;
}

export interface Operations {
  name: string;
  tag: string;
  icon: string;
  position: number;
  menu: boolean;
}

export interface Organizations {
  id: number;
  name: string;
  cifNif: string;
}

export interface Language {
  id: number;
  name: string;
  code: string;
  flag: string;
}

export class UserModel implements UserData {
  id?: string;
  email?: string
  name?: string; 
  lastname?: string;
  rol?: string;
  operations?: Operations;
  organizations?: Organizations;
  language?: Language;
  lastUploadStatus?: number
  lastUpload?: string;
}
