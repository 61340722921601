import { useEffect, useState } from "react";

import Styles from "./c-modal-table.module.css";
import iconClose from "../../assets/icon-close.svg";
import arrowIcon from "../../assets/icon-arrow.svg";
import sortIcon from "../../assets/icon-order.svg";
import addIcon from "../../assets/icon-add.svg"

import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import { Box, Checkbox, FormControlLabel, TablePagination, TableSortLabel } from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import { TableHead } from "@mui/material";

import { CButton } from "../c-button/c-button";
import CTextInput from "../c-text-input/c-text-input";
import { t } from "i18next";
import Cocli from "../../services/cocli/s-cocli";
import { useNavigate } from "react-router-dom";
import { CheckBox } from "@mui/icons-material";
import { CocliModel } from "../../model/m-cocli";

type Order = 'asc' | 'desc';

interface cocliFilter {
  code?: string, 
  name?: string, 
  pageSize?: number, 
  page?: number
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: any;
  rowCount: number;
}
interface HeadCell {
  disablePadding: boolean;
  id: string;
  label?: string;
  numeric: boolean;
  checkbox?: boolean;
  disabledSort: boolean;
}

const sizeNewCocli = "sm:h-[343px] h-[340px]";

export default function CModal(props: any) {
  const navigate = useNavigate()

  const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
  const alphaFormat = /^([a-zA-Z ]+)$/

  const [openFilterStatus, setOpenFilterStatus] = useState(false);

  const [loading, setLoading] = useState(true);
  const [allRows, setAllRows] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [counts, setCounts] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [cocliName, setCocliName] = useState("");
  const [cocliCode, setCocliCode] = useState("");
  const [cocliList, setCocliList] = useState<any>([]);
  const [cocliListCodes, setCocliListCodes] = useState<any>([]);
  const [allCoclis, setAllCoclis] = useState<any>(false);
  const [newCocli, setNewCocli] = useState<any>(false);
  const [cocli, setCocli] = useState(new CocliModel());

  const [cocliCodeNew, setCocliCodeNew] = useState("");
  const [cocliNameNew, setCocliNameNew] = useState("");

  const [formatCocliNameError, setFormatCocliNameError] = useState(false);
  const [formatCocliError, setCocliError] = useState(false);
 
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      height: "10px"
    },
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#f6f6f8',
      color: '#6c6c6c',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
    borderBottom: "none !important",
    fontFamily: 'Poppins, sans-serif',
    letterSpacing: 0.2,
    color: '#1b1b1e',
    height: "5px"
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({

    [`&.${tableRowClasses.root}`]: {
      height: "16px",
    },
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    backgroundColor: "white !important",
    fontFamily: 'Poppins, sans-serif'
  }));

  const headCells: readonly HeadCell[] = [
    {
      id: 'selectAll',
      numeric: true,
      disablePadding: false,
      checkbox: true,
      disabledSort: true,
    },
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: `${t('MANAGE_COCLIS.LIST.COL_NAME')}`,
      disabledSort: true,
    },
    {
      id: 'code',
      numeric: true,
      disablePadding: false,
      label:`${t('MANAGE_COCLIS.LIST.COL_CODE')}`,
      disabledSort: true,
    },
  ];

  const saveData = () => {
    cocli.code = cocliCodeNew;
    cocli.name = cocliNameNew;
  }

  const createCocli = () => {
    saveData();
    Cocli.createCocli(cocli, navigate)
    .then((resp: any) => {
      props.openModal(true, "modalResponseSuccess", null, false, () => {
        setNewCocli(false);
        props.openModal(false, "modalResponseSuccess");
      }, null, false, null, resp.result.info);
    })
    .catch((error: any) => {
      props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, error.response.data.result.info);
    });
  };

  const updateName = (data: any) => {
    setCocliNameNew(data.trim());
  }
  
  const updateCode = (data: any) => {
    setCocliCodeNew(data);
    if (alphaFormat.test(data)){
      setCocliError(true);
    }else{
      setCocliError(false);
    }
  }

  const filter = (cocli: cocliFilter) => {
    let name = cocliName;
    let code = cocliCode;
  
    if (cocli && (cocli.name || cocli.name === '')){
      name = cocli.name;
      setCocliName(name);
    }
    if (cocli && (cocli.code || cocli.code === '')){
      code = cocli.code;
      setCocliCode(code);
    }
  
    if(props.data && props.data.type === 'passenger'){
      Cocli.getUserOrgCoclis(
        props.data.org,
        props.data.field_id,
        {
          name, 
          code,
        },
        navigate
      )
      .then((resp: any) => {
        let tmpRows = resp.data.data;
          let tmpCoclis = props.data.cocliList || [];

          tmpRows = tmpRows.map((r: any) => {
            if(tmpCoclis.includes(r.id)){
              r.selected = !r.selected;
            }
            return r;
          });
          if(props.data.allCoclis){
            setAllCoclis(props.data.allCoclis);
          }else{
              setAllCoclis(false);
          }

          setRows(tmpRows);
          setCocliList(tmpCoclis);

          setPage(resp.data.pageNumber);
          setRowsPerPage(resp.data.pageSize);
          setCounts(resp.data.totalElements);
          setAllRows(tmpRows);
          setLoading(false);
      })
    }else {
      Cocli.getCoclis(
        {
          name, 
          code,
        },
        navigate
      )
      .then((resp: any) => {
        let tmpRows = resp.data.data;
        let tmpCoclis = props.data.cocliList || [];

        tmpRows = tmpRows.map((r: any) => {
          if(tmpCoclis.includes(r.id)){
            r.selected = !r.selected;
          }
          return r;
        });
        if(props.data.allCoclis){
          setAllCoclis(props.data.allCoclis);
        }else{
            setAllCoclis(false);
        }

        setRows(tmpRows);
        setCocliList(tmpCoclis);

        setPage(resp.data.pageNumber);
        setRowsPerPage(resp.data.pageSize);
        setCounts(resp.data.totalElements);
        setAllRows(tmpRows);
        setLoading(false);
      })
    }
  }

  const validateType = () => {
    if(cocliList.length > 0 || allCoclis){
      return ""
    }
    return "disabled"
  }

  const handleSelections = (row: any) => {
    
    let tmp = cocliList;
    let tmpCodes = cocliListCodes;
    let tmpRows = rows;

    if(!allCoclis){
      if(props.data && !props.data.multiselect){
        tmpRows = tmpRows.map((r: any) => {
          if(row.id !== r.id){
            r.selected = false;
          }
          return r;
        })
      }
      tmpRows = tmpRows.map((r: any) => {
        if(row.id === r.id){
          r.selected = !r.selected;
        }
        return r;
      })
      setRows(tmpRows);

      if(props.data && !props.data.multiselect){
        setCocliList([row.id]);
        setCocliListCodes([row]);
      }else {
        if(cocliList.includes(row.id)){
          tmp = tmp.filter((r: any) => r !== row.id);
          tmpCodes = tmpCodes.filter((r: any) => r.id !== row.id);
          setCocliList(tmp);
          setCocliListCodes(tmpCodes);
        }else{
          tmp.push(row.id);
          tmpCodes.push(row);
          setCocliList(tmp);
          setCocliListCodes(tmpCodes);
        }
      }
    }
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if(props.data && props.data.type === 'passenger'){
      Cocli.getUserOrgCoclis(
        props.data.org,
        props.data.field_id,
        {
          name: cocliName, 
          code: cocliCode,
          page: newPage,
          pageSize: rowsPerPage
        },
        navigate
      )
      .then((req: any) => {
        const {data} = req;
        let tmpRows = data.data;

        tmpRows = tmpRows.map((r: any) => {
          if(cocliList.includes(r.id)){
            r.selected = !r.selected;
          }
          return r;
        })
        setRows(tmpRows);
        setCounts(data.totalElements);
        setRowsPerPage(data.pageSize);
        setLoading(false);
      })
    }else{
      Cocli.getCoclis(
        {
          name: cocliName, 
          code: cocliCode,
          page: newPage,
          pageSize: rowsPerPage
        },
        navigate
      )
      .then((req: any) => {
        const {data} = req;
        let tmpRows = data.data;

        tmpRows = tmpRows.map((r: any) => {
          if(cocliList.includes(r.id)){
            r.selected = !r.selected;
          }
          return r;
        })
        setRows(tmpRows);
        setCounts(data.totalElements);
        setRowsPerPage(data.pageSize);
        setLoading(false);
      })
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCleanInputs = () => {
    setCocliName("");
    setCocliCode("");
    setPage(0);
    setNewCocli(false);
  }

  useEffect(() => {
    if(loading && props.show){
      if(props.data && props.data.type === 'passenger'){
        Cocli.getUserOrgCoclis(
          props.data.org,
          props.data.field_id,
          {
            name: cocliName, 
            code: cocliCode,
            page: page,
            pageSize: rowsPerPage
          }, navigate)
        .then((resp: any) => {
          let tmpRows = resp.data.data;
          let tmpCoclis = props.data.cocliList || [];

          tmpRows = tmpRows.map((r: any) => {
            if(tmpCoclis.includes(r.id)){
              r.selected = !r.selected;
            }
            return r;
          });
          if(props.data.allCoclis){
            setAllCoclis(props.data.allCoclis);
          }else{
            // if(tmpCoclis.length >= tmpRows.length){
            //   setAllCoclis(true);
            // }else{
              setAllCoclis(false);
            // }
          }

          setRows(tmpRows);
          setCocliList(tmpCoclis);
          setCocliListCodes(props.data.cocliListObjs || []);

          setPage(resp.data.pageNumber);
          setRowsPerPage(resp.data.pageSize);
          setCounts(resp.data.totalElements);
          setAllRows(tmpRows);
          setLoading(false);
        });

      }else {
        Cocli.getCoclis(
          {
            name: cocliName, 
            code: cocliCode,
            page: page,
            pageSize: rowsPerPage
          }, navigate)
        .then((resp: any) => {
          let tmpRows = resp.data.data;
          let tmpCoclis = props.data.cocliList || [];
          tmpRows = tmpRows.map((r: any) => {
            if(tmpCoclis.includes(r.id)){
              r.selected = !r.selected;
            }
            return r;
          });
          if(props.data.allCoclis){
            setAllCoclis(props.data.allCoclis);
          }else{
            // if(tmpCoclis.length >= tmpRows.length){
              // setAllCoclis(true);
            // }else{
              setAllCoclis(false);
            // }
          }

          setRows(tmpRows);
          setCocliList(tmpCoclis);
          setCocliListCodes(props.data.cocliListObjs || []);

          setPage(resp.data.pageNumber);
          setRowsPerPage(resp.data.pageSize);
          setCounts(resp.data.totalElements);
          setAllRows(tmpRows);
          setLoading(false);
        });
      }
    }else if(!props.show){
      setLoading(true);
    }
  }, [loading, navigate, rows, cocliList, props])

  function EnhancedTableHead(prop: EnhancedTableProps) {
    const { order, orderBy } =
      prop;
   
    return (
      <TableHead>
          <TableRow>
          {headCells.map((headCell) => (
              <StyledTableCell
                key={headCell.id}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel hideSortIcon style={{ cursor: 'unset' }}>
                  {headCell.label ?
                    headCell.label :
                    (headCell.checkbox ?
                      <Checkbox
                        id="selectCocli"
                        checked={allCoclis}
                        disabled={props.data && !props.data.multiselect}
                        onClick={() => {
                          let tmpRows = rows;
                          
                          tmpRows = tmpRows.map((r: any) => {
                              r.selected = false;
                            return r;
                          })

                          setRows(tmpRows);
                          if(allCoclis){
                            setCocliListCodes([]);
                          }else {
                            setCocliListCodes(tmpRows);
                          }
                          setCocliList([]);
                          setAllCoclis((prev: any) => !prev);
                      }}                              
                      sx={{
                          color: '#979797',
                          '&.Mui-checked': {
                            color: '#017ef3',
                          },
                      }}
                      />
                      : null  
                    )
                  }
                </TableSortLabel>
              </StyledTableCell>
          ))}
          <StyledTableCell align="left">&nbsp;</StyledTableCell>
          </TableRow>
      </TableHead>
    );
  }

  return (
    <div>
      {props.show &&
        
        <div>
          <div className={Styles.c_modal_table_background}></div>
            <div className={`${Styles.c_modal_table_container} ${props.width} ${!newCocli? props.height : sizeNewCocli}`}>
              <header className={Styles.c_modal_table_header}>
                <button onClick={() => {
                  handleCleanInputs();
                  if(props.cancelCallback){
                    props.cancelCallback();
                  }else {
                    props.close(false, props.modalType);
                  }
                }}
                >
                  <img src={iconClose} alt="close" />
                </button>
              </header>
              <div className={Styles.c_modal_table_main_group}> 
                <div className={Styles.c_modal_table_content_group_format}>
                  <div className={Styles.c_modal_table_title_container_format}>
                    <p className={Styles.c_modal_table_title}>{ !newCocli ? props.title : t("MANAGE_COCLIS.CREATE.FIRST_FORM_TITLE")}</p>
                    <p className={Styles.c_modal_table_subtitle}>
                      {t("MANAGE_COCLIS.CREATE.FIRST_FORM_SUBTITLE")}
                    </p>
                    {!newCocli ?
                      <div className={Styles.c_modal_table_search_group}>
                        <CTextInput 
                          id="cocli-name"
                          type="text"
                          placeholder={t("MANAGE_COCLIS.LIST.COL_NAME")}  
                          value={cocliName}
                          setValue={(value: any) => {
                            filter({name: value || ''});
                          }}
                          />
                        <CTextInput 
                          id="cocli-code"
                          type="text"
                          placeholder={t("MANAGE_COCLIS.LIST.COL_CODE")}
                          value={cocliCode}
                          setValue={(value: any) => {
                            filter({code: value || ''})
                          }}
                        />
                        <div className={Styles.v_coclis_list_search_arragner_search_button}>
                          {cocliName || cocliCode ? 
                            <CButton placeholder={t("MANAGE_COCLIS.LIST.BTN_SEARCH")} action={filter}/>
                            :
                            <CButton placeholder={t("MANAGE_COCLIS.LIST.BTN_SEARCH")} type="disabled" /> 
                          } 
                        </div>
                        {props.data.type !== 'passenger' ? 
                          <CButton 
                            placeholder={t("MANAGE_COCLIS.LIST.BTN_NEW_COCLI")} 
                            type="secondary"
                            icon={addIcon} 
                            action={() => {setNewCocli(true)}}
                          />
                          : null
                        }
                      </div>
                      : 
                      <div className={Styles.v_coclis_create_form}>
                        <div className={Styles.v_coclis_create_form_group}>
                          <div className={Styles.v_coclis_create_input_group}>
                            <label
                              className={Styles.v_coclis_create_input_label}
                              htmlFor="cocli-name"
                            >
                              {t("MANAGE_COCLIS.CREATE.FIRST_FORM_COCLI_NAME_LABEL")}*
                            </label>
                            <CTextInput
                              id="cocli-name"
                              type="text"
                              placeholder={t("MANAGE_COCLIS.CREATE.FIRST_FORM_COCLI_NAME_LABEL")}
                              value={cocliNameNew}
                              setValue={updateName}
                            />
                          </div>
                          <div className={Styles.v_coclis_create_input_group}>
                            <label
                              className={Styles.v_coclis_create_input_label}
                              htmlFor="cocli-code"
                            >
                              {t("MANAGE_COCLIS.CREATE.FIRST_FORM_COCLI_CODE_LABEL")}*
                            </label>
                            <CTextInput
                              id="cocli-code"
                              type="text"
                              placeholder={t("MANAGE_COCLIS.CREATE.FIRST_FORM_COCLI_CODE_LABEL")}
                              value={cocliCodeNew}
                              setValue={updateCode}
                            />
                          </div>
                        </div>
                      </div>
                    }

                    {!newCocli ? 
                    <div className={Styles.c_modal_table_list_table}>
                      <TableContainer>
                        <Box sx={{ overflow: "auto" }}>
                          <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                          <TableContainer sx={{ width: "100%", maxHeight: 440 }}>
                            <Table stickyHeader aria-label="customized table" >
                              <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                              />
                              <TableBody>
                                  {rows.map((row: any) => (
                                  <StyledTableRow key={row.id}>
                                    <StyledTableCell align="left">
                                      <Checkbox
                                        id="selectCocli"
                                        checked={allCoclis ? true : row.selected}
                                        onClick={() => {
                                          handleSelections(row)
                                        }}                              
                                        sx={{
                                            color: '#979797',
                                            '&.Mui-checked': {
                                              color: '#017ef3',
                                            },
                                        }}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                                    <StyledTableCell align="left">{row.code}</StyledTableCell>
                                  </StyledTableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          </Box>
                        </Box>
                      </TableContainer>   
                      <TablePagination
                        rowsPerPageOptions={[20]}
                        component="div"
                        count={counts}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      /> 
                    </div>
                    : null}
                  </div>
                </div>
              </div>
              <div className={Styles.c_modal_table_button_group}>
                {props.secondaryBtn &&
                  <div className={Styles.c_modal_table_button_secondary}>
                    <CButton 
                      placeholder={props.secondaryBtn} 
                      type="secondary" 
                      action={() => {
                        if(props.cancelCallback){
                          props.cancelCallback();
                        }else{
                          props.close(false, props.modalType)
                        }
                        handleCleanInputs();
                      }}
                    />
                  </div>
                }
                {props.primaryBtn && !newCocli ?
                  <div className={Styles.c_modal_table_button_secondary}>
                    <CButton
                      placeholder={props.primaryBtn}
                      action={() => {
                        if(props.callback){
                            props.callback(allCoclis, cocliList, cocliListCodes);
                        }else{
                          props.close(false, props.modalType)
                        }
                        handleCleanInputs();
                      }}
                      type={validateType()}
                    />
                  </div>
                  : 
                  <div className={Styles.c_modal_table_button_secondary}>
                    {cocliCodeNew &&
                      cocliNameNew &&
                      cocliNameNew !== "" &&
                      !formatCocliNameError &&
                      !formatCocliError ? (
                      <CButton placeholder={t("MANAGE_COCLIS.CREATE.FORM_BUTTON")} action={createCocli}/>
                    ) : (
                      <CButton placeholder={t("MANAGE_COCLIS.CREATE.FORM_BUTTON")} type="disabled" />
                    )}
                  </div>
                }
              </div>
            </div>
        </div>
      }
    </div>
  );
};
