export interface TravelData {
    id?: number;
    name?: string;
    lastname?: string;
    email?: string;
    organization_name?: string;
    organization_cifnif?: string;
    organization?: Organization;
    schedule?: Schedule;
    data?: FieldData[];
  }
  
  export interface Schedule {
    frequency: number;
    time: number;
  }

  export interface FieldData {
    list?: boolean | undefined;
    field_id: number;
    mandatory?: boolean;
    hide?: boolean;
    content?: string;
    disable?: boolean;
  }

  export interface Organization {
    id: number;
    name?: string;
    cifNif?: string;
    selection?: any;
  }
  
  export class TravelAgentModel implements TravelData {
    id?: number;
    name?: string;
    lastname?: string;
    email?: string;
    organization_name?: string;
    organization_cifnif?: string;
    organization?: Organization;
    schedule?: Schedule;
    data?: FieldData[] = [];
  }