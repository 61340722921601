import Styles from './v-error-500.module.css';
import Plane from './../../../assets/illustration-plane.svg';
import { t } from 'i18next';
import { CButton } from '../../../components/c-button/c-button';
import { useNavigate } from 'react-router-dom';

export default function V500() {
    const navigate = useNavigate();

    return (
        <div className={Styles.v_error_500_container}>
            <img src={Plane} alt="500"/>
            <div className={Styles.v_error_500_title_container}>
                <label className={Styles.v_error_500_title}>500</label>
                <label>{t('ERROR.500')}</label>
                <CButton 
                    placeholder={t('ERROR.BTN.RELOAD')} 
                    type="secondary" 
                    width={160}
                    action={() => {
                        localStorage.clear();
                        return(navigate('/'))
                    }}
                />
            </div>
        </div>
    )
}