import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Styles from "./v-dashboard.module.css";
import editIcon from "../../assets/icon-edit.svg";
import arrowIcon from "../../assets/icon-arrow.svg";
import sortIcon from "../../assets/icon-order.svg";
import closeIcon from "../../assets/icon-close.svg";
import infoIcon from "../../assets/icon-info.svg";
import addIcon from "../../assets/icon-add.svg"

import CTextInput from '../../components/c-text-input/c-text-input';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, TablePagination, TableSortLabel } from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from "react-router-dom";
import Schedule from "../../services/schedule/s-schedule";
import { CButton } from "../../components/c-button/c-button";

const STATUS_DELETED = 6;
type Order = 'asc' | 'desc';
interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: any;
  rowCount: number;
}
interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  disabledSort: boolean;
}
interface scheduleFilter {
  travelAgent?: any,
  status?: any;
  pageSize?: number, 
  page?: number
}

export default function VDashboard(props: any) {  
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()

  const [actualLanguage, setActualLanguage] = useState('');
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [counts, setCounts] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [openFilterTravelAgent, setOpenFilterTravelAgent] = useState(false);
  const [openFilterExportStatus, setOpenFilterExportStatus] = useState(false);
  const [rows, setRows] = useState<any>([]);
  const [allRows, setAllRows] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [lastUpload, setLastUpload] = useState('');
  const [lastUploadStatus, setLastUploadStatus] = useState('');
  const [statusFilter, setStatusFilter] = useState<any>([]);  
  const [travelAgent, setTravelAgent] = useState<any>(false);
  const [travelAgentFinder, setTravelAgentFinder] = useState<any>();

  const statuses_array = [
    {
      id: 0,
      name: t('DASHBOARD.PENDING'),
    },
    {
      id: 1,
      name: t('DASHBOARD.EXCECUTED'),
    },
    {
      id: 2,
      name: t('DASHBOARD.SUCCESS'),
    },
    {
      id: 3,
      name: t('DASHBOARD.FAIL'),
    },
    {
      id: 4,
      name: t('DASHBOARD.RETRY'),
    },
    {
      id: 5,
      name: t('DASHBOARD.STOPPED'),
    },
    {
      id: 6,
      name: t('DASHBOARD.DELETED'),
    }
  ];
  const [statuses, setStatuses] = useState<any>([]);
  

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#f6f6f8',
      color: '#6c6c6c',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
    borderBottom: "none !important",
    fontFamily: 'Poppins, sans-serif',
    letterSpacing: 0.2,
    color: '#1b1b1e'
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    backgroundColor: "white !important",
    fontFamily: 'Poppins, sans-serif'
  }));

  const handleChange = (value: any, type: string) => {
    getSchedules(orderBy, order, 0, {status: value.id}, travelAgentFinder);
  };

  const headCells: readonly HeadCell[] = [
    {
      id: 'frequency',
      numeric: true,
      disablePadding: false,
      label:  `${t('DASHBOARD.FREQUENCY.TITLE')}`,
      disabledSort: true,
    },
    {
      id: 'startedDate',
      numeric: true,
      disablePadding: false,
      label: `${t('DASHBOARD.DATE.TITLE')}`,
      disabledSort: false,
    },
    {
      id: 'hour',
      numeric: true,
      disablePadding: false,
      label: `${t('DASHBOARD.HOUR.TITLE')}`,
      disabledSort: true,
    },
    {
      id: 'travel_agent',
      numeric: true,
      disablePadding: false,
      label: `${t('DASHBOARD.TRAVEL_AGENT.TITLE')}`,
      disabledSort: false,
    },
    {
      id: 'export_status',
      numeric: true,
      disablePadding: false,
      label: `${t('DASHBOARD.EXPORT_STATUS.TITLE')}`,
      disabledSort: false,
    },
  ];

  const defineIcon = (value: any) => {
    if(value === t('DASHBOARD.DATE.TITLE')) {
      return sortIcon;
    }else {
      return arrowIcon;
    }
  }

  const getLastUploadStatus = (status: any) => {
    switch(status) {
      case 0: {
        return (
          setLastUploadStatus(t('DASHBOARD.VALIDATING'))
        );
      }
      case 1: {
        return (
          setLastUploadStatus(t('DASHBOARD.VALIDATED'))
        );      
      }
      case 2: {
        return (
          setLastUploadStatus(t('DASHBOARD.REJECTED'))
        );      
      }
      case 3: {
        return (
          setLastUploadStatus(t('DASHBOARD.PROCESSING'))
        );      
      }
      case 4: {
        return (
          setLastUploadStatus(t('DASHBOARD.SUCCESS'))
        );
      }
      case 5: {
        return (
          setLastUploadStatus(t('DASHBOARD.FAIL'))
        );
      }
      case 6: {
        return (
          setLastUploadStatus(t('DASHBOARD.RESTORED'))
        );
      }
    }
  }

  const filters = () => {
    const checkIfClickedOutside = (e: any) => {
      const element = e.target;
      const id = element.id.includes("statusF");
      const idIcon = element.id.includes("export_status");
      if(element.textContent !== t("DASHBOARD.EXPORT_STATUS.TITLE")
        && !id && !idIcon 
        && element.textContent !== t('DASHBOARD.PENDING') 
        && element.textContent !== t('DASHBOARD.EXCECUTED') 
        && element.textContent !== t('DASHBOARD.SUCCESS')
        && element.textContent !== t('DASHBOARD.FAIL')
        && element.textContent !==  t('DASHBOARD.RETRY') 
        && element.textContent !==  t('DASHBOARD.STOPPED')){
        setOpenFilterExportStatus(false);
      }
    }
    if(openFilterExportStatus){
      document.removeEventListener('click', checkIfClickedOutside);  
    }else {
        document.addEventListener('click', checkIfClickedOutside);
    }
    const checkIfClickedOutside2 = (e: any) => {
      const element = e.target;
      const id = element.id.includes("taF");
      const idIcon = element.id.includes("travel_agent");
      if(element.textContent !== t("DASHBOARD.TRAVEL_AGENT.TITLE") && !id && !idIcon){
        setOpenFilterTravelAgent(false);
      }
    }
    if(openFilterTravelAgent){
      document.removeEventListener('click', checkIfClickedOutside2);  
    }else {
        document.addEventListener('click', checkIfClickedOutside2);
    }
  }

  useEffect(() => {
    if(loading){
      setActualLanguage(i18n.language);
      let status = statuses_array.map((s: any) => s.id);
      let tmpStatus = statuses_array.map((s: any) => {
        s.selected = true;
        return s;
      });
      setLastUpload(props.values.lastUpload);
      if(props.values.lastUpload){
        getLastUploadStatus(props.values.lastUploadStatus);
      }else{
        setLastUploadStatus(t('DASHBOARD.WITHOUT_DATA'))
      }
      Schedule.getSchedule(navigate, orderBy, order, page, status).then((resp: any) => {
        let schedules = resp.data.data;
        schedules = schedules.map((value: any) => {
          value.travel_agent = {
            id: value.travel_agent.id,
            name: `${value.travel_agent.user.name} ${value.travel_agent.user.lastName} (${value.travel_agent.name})`
          }
          value.frequency.name = t(value.frequency.name);
          return value
        });            
        setRows(schedules);
        setAllRows(resp.data.data);
        setPage(resp.data.pageNumber);
        setRowsPerPage(resp.data.pageSize);
        setCounts(resp.data.totalElements);
        setStatuses(tmpStatus);
        setStatusFilter(status);
      }).catch((err: any) => {
        console.log(err.error);
      })
      setLoading(false);
      filters();
    }
    if(actualLanguage !== i18n.language && !loading){
      setActualLanguage(i18n.language);
      getSchedules(orderBy, order, page);
    }
  }, [loading, statuses, props.values.lastUpload, props.values.lastUploadStatus, getLastUploadStatus, navigate, i18n.language])

  const createSortHandler =
  (property: string) => (event: React.MouseEvent<unknown>) => {
    if(property === "startedDate"){
      handleRequestSort(event, property)
    }else if(property === "travel_agent"){
      setOpenFilterTravelAgent(!openFilterTravelAgent);
      setOpenFilterExportStatus(false);
    }else if(property === "export_status") {
      setOpenFilterExportStatus(!openFilterExportStatus);
      setOpenFilterTravelAgent(false);
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    getSchedules(property, isAsc ? 'desc' : 'asc',  page);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    getSchedules(orderBy, order, newPage, false, travelAgentFinder);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getSchedules = (sort?: any, order?: any, newPage?: any, schedule?: any, travelAgent?: any) => {

    let ta = travelAgent;
    let status = statusFilter;

    if (schedule && (schedule.travelAgent || schedule.travelAgent === '')){
      ta = schedule.travelAgent;
      setTravelAgent(ta);
    }
    
    if (schedule && schedule.status >= 0){
      let tmpStatus = statuses;
      if(!status.includes(schedule.status)){
        status.push(schedule.status);
        tmpStatus = tmpStatus.map((s: any) => {
          let tmp = s;
          if(tmp.id === schedule.status){
            tmp.selected = true;
          }
          return tmp;
        })
      }else {
        status = status.filter((s: any) => s !== schedule.status);
        tmpStatus = tmpStatus.map((s: any) => {
          let tmp = s;
          if(tmp.id === schedule.status){
            tmp.selected = false;
          }
          return tmp;
        })
      }
      setStatuses(tmpStatus);
      setStatusFilter(status);
    }

    Schedule.getSchedule(navigate, sort, order, newPage, status, travelAgent).then((resp: any) => {
      let schedules = resp.data.data;
      schedules = schedules.map((value: any) => {
        value.travel_agent = {
          id: value.travel_agent.id,
          name: `${value.travel_agent.user.name} ${value.travel_agent.user.lastName} (${value.travel_agent.name})`
        }
        value.frequency.name = t(value.frequency.name);
        return value
      });            
      setRows(schedules);
      setAllRows(resp.data.data);
      setPage(resp.data.pageNumber);
      setRowsPerPage(resp.data.pageSize);
      setCounts(resp.data.totalElements);
    }).catch(err => {
      console.log(err)
    })
  }

  const updateSchedule = (val: any) => {

    Schedule.updateSchedule(val, false, false, navigate)
    .then((resp: any) => {
      props.openModal(false, "modalSchedule");
      getSchedules(orderBy, order, page);
      props.openModal(true, "modalResponseSuccess", null, false, null, null, false, null, resp.result.info);
    }).catch((err: any) => {
      props.openModal(false, "modalSchedule");
      props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
      getSchedules(orderBy, order, page);
    })
  }

  const handleStatus = (status: number, row: any) => {
    switch (status) {
      // If status = pending (verde)
      case 0: {
        return (
          <div className={Styles.dashboard_tablet_item_pending}>
            <p>{t("SCHEDULE_EXPORTS.STATUS.0")}</p>
          </div>
        )
      }
      // If status = executing || complete (gris)
      case 1: {
        return (
          <div className={Styles.dashboard_tablet_item_executed_complete}>
            <p>{t("SCHEDULE_EXPORTS.STATUS.1")}</p>
          </div>
        )
      }
      // If status = executing || complete (gris)
      case 2: {
        return (
          <div className={Styles.dashboard_tablet_item_executed_complete}>
            <p>{t("SCHEDULE_EXPORTS.STATUS.2")}</p>
          </div>
        )
      }
      // If status = incomplete (naranja)
      case 3: {
        return (
          <div className={Styles.dashboard_tablet_item_incomplete_retry}>
              <p className={Styles.dashboard_tablet_item_span}> 
                <span onClick={() => {props.openModal(true, "modalRetryExport", row, false, 
                () => {
                  Schedule.retrySchedule(row.historyId, navigate)
                  .then((resp: any) => {
                    props.openModal(false, "modalRetryExport");
                    getSchedules(orderBy, order, page);
                  })
                  .catch(err => {
                    props.openModal(false, "modalRetryExport");
                    props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
                  })
                }, null, true)}}>
                  <img src={infoIcon} alt="" className={Styles.dashboard_tablet_item_e}/>
                </span>
                {t("SCHEDULE_EXPORTS.STATUS.3")}
              </p>
          </div>
        )
      }
      // If status = retry (naranja)
      case 4: {
        return (
          <div className={Styles.dashboard_tablet_item_incomplete_retry}>
            <p>{t("SCHEDULE_EXPORTS.STATUS.4")}</p>
          </div>
        )
      }
      // If status = stopped (rojo)
      case 5: {
        return (
          <div className={Styles.dashboard_tablet_item_stopped}>
              <p> 
                {t("SCHEDULE_EXPORTS.STATUS.5")}
              </p>
          </div>
        )
      }
      // If status = deleted (rojo)
      case 6: {
        return (
          <div className={Styles.dashboard_tablet_item_stopped}>
              <p> 
                {t("SCHEDULE_EXPORTS.STATUS.6")}
              </p>
          </div>
        )
      }
    }
  }

    return (
      <div className={Styles.dashboard}>
        <div className={Styles.dashboard_header}>
          <div className={Styles.dashboard_title_container}>
            <p className={Styles.dashboard_header_title}>{t("DASHBOARD.TITLE")}</p>
            <CButton 
              placeholder={t("DASHBOARD.ADD_SCHEDULE")} 
              type="secondary"
              height={36} 
              icon={addIcon} 
              action={() => {
                window.scrollTo(0,0);
                props.openModal(true, "modalNewSchedule",  
                {
                  frequency: {}, 
                  hour: {},       
                  travel_agent: {},
                }, false,
                (value: any) => {
                  Schedule.createSchedule(value.frequency.id, value.hour.id, value.travel_agent.id, navigate).then((resp: any) => {
                    getSchedules(orderBy, order, page);
                    props.openModal(false, "modalNewSchedule");
                    props.openModal(true, "modalResponseSuccess", null, false, () => {
                    props.openModal(false, "modalResponseSuccess");
                    }, null, false, null, resp.result.info);
                  }).catch(err => {
                    props.openModal(false, "modalNewSchedule");
                    props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
                    console.log(err);
                  })
                });
                }}
              />
          </div>
          <div className={Styles.dashboard_header_status_group}>
            <p className={Styles.dashboard_header_date}>
              {lastUpload ? `${t("DASHBOARD.LAST_UPLOAD")} ${lastUpload}${t("DASHBOARD.LAST_UPLOAD_FORMAT")}`: ''}
            </p>
            <div className={Styles.dashboard_header_status}>
              <div className={Styles.dashboard_header_status_dot}></div>
                <p className={Styles.dashboard_header_status_text}>{lastUploadStatus ? lastUploadStatus : ''}</p>
              </div>
            </div>
          </div>
          {travelAgentFinder ?
            <div className={Styles.dashboard_header_filter}>
              <div className={Styles.dashboard_header_filter_content} 
              onClick={() => {
                setTravelAgentFinder("");
                getSchedules(orderBy, order, page, {}, null);
              }}>
                {travelAgentFinder}
                <img src={closeIcon} alt="close" height={"10px"} width={"10px"} className={Styles.dashboard_header_filter_clean}/>
              </div>
            </div>  
            : 
            null
          }
          {!loading &&
            <div className={Styles.dashboard_tablet_desktop}>
              <TableContainer>
                {openFilterTravelAgent &&
                  <div className={Styles.dashboard_filter_container_t} id="taFilter">
                    <header className={Styles.dashboard_filter_close} id="taF1">
                      <label id="taF2" >{t('DASHBOARD.FILTER')}</label>
                      <button id="taF3" onClick={() => setOpenFilterTravelAgent(false)}>
                        <img src={closeIcon} alt="close" />
                      </button>
                    </header>
                      <div id="taF4" className={Styles.item}>
                        <CTextInput
                          id="taF5"
                          type="text"
                          placeholder={t("DASHBOARD.FINDBYNAME")}  
                          value={travelAgentFinder}
                          setValue={(value: any) => {
                            setTravelAgentFinder(value);
                            getSchedules(orderBy, order, 0, {}, value);
                          }}
                        />
                    </div>
                  </div>
                }
                {openFilterExportStatus &&
                  <div className={Styles.dashboard_filter_container_e} id="statusFilter">
                    <header id="statusF1" className={Styles.dashboard_filter_close}>
                      <label id="statusF2">{t('DASHBOARD.FILTER')}</label>
                      <button id="statusF3" onClick={() => setOpenFilterExportStatus(false)}>
                        <img src={closeIcon} alt="close" />
                      </button>
                    </header>
                      <div id="statusF4" className={Styles.item}>
                      {statuses.map((row: any) => (
                          <FormControlLabel
                            id="statusF5"
                            key={row.id}
                            label={row.name}
                            control={
                              <Checkbox
                                id="statusF6"
                                name={row.name}
                                checked={row.selected}
                                onClick={() => {
                                  handleChange(row, "platRoles")
                                }}                              
                                sx={{
                                    color: '#979797',
                                    '&.Mui-checked': {
                                      color: '#017ef3',
                                    },
                                }}
                              />
                            }
                          />                 
                        ))}  
                    </div>
                  </div>
                }
                <Box sx={{ overflow: "auto" }}>
                    <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            {headCells.map((headCell) => (
                              <StyledTableCell
                                key={headCell.id}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                                onClick={createSortHandler(headCell.id)}
                              >
                                {!headCell.disabledSort ?
                                  <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    IconComponent={
                                      () => 
                                        <span 
                                          id={headCell.id}
                                          onClick={() => {
                                            createSortHandler(headCell.id)
                                          }}
                                        >
                                          <img  id={headCell.id} src={defineIcon(headCell.label)} alt="" style={{marginLeft: '12px', width: '16px', height: '16px'}}/>
                                        </span>
                                      }
                                  >
                                      {headCell.label}
                                      {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                      ) : null}
                                  </TableSortLabel> :
                                  <TableSortLabel
                                    hideSortIcon 
                                    style={{ cursor: 'unset' }} 
                                  >
                                    {headCell.label}
                                  </TableSortLabel> 
                                }
                              </StyledTableCell>
                            ))}
                            <StyledTableCell align="left">&nbsp;</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row: any) => (
                          <StyledTableRow key={row.historyId}>
                            <StyledTableCell align="left">{row.frequency.name}</StyledTableCell>
                            <StyledTableCell align="left">{row.startedDate}</StyledTableCell>
                            <StyledTableCell align="left">{row.hour.name}</StyledTableCell>
                            <StyledTableCell align="left">{row.travel_agent.name}</StyledTableCell>                
                            <StyledTableCell align="left"> 
                            {handleStatus(row.status, row)}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.deletedDate === null &&
                                <button style={{width: 20, height: 20}} onClick={() => {
                                    window.scrollTo(0,0);
                                    props.openModal(
                                      true, 
                                      "modalSchedule", 
                                      row, 
                                      false, 
                                      (val: any) => {
                                        updateSchedule(val);
                                      },
                                      null, 
                                      true, 
                                      () => {
                                        props.openModal(false, "modalSchedule");
                                        props.openModal(true, "scheduleDelete", null, false, 
                                        () => {
                                          Schedule.updateSchedule(row, true, false, navigate).then((resp: any) => {
                                            props.openModal(false, "scheduleDelete");
                                            getSchedules(orderBy, order, page);
                                          }).catch((err: any) => {
                                            props.openModal(false, "scheduleDelete");
                                            props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
                                            getSchedules(orderBy, order, page);
                                          })
                                        },
                                        () => {
                                          props.openModal(false, "scheduleDelete");
                                          getSchedules(orderBy, order, page);
                                        },
                                        false, null, 
                                      );
                                      }, 
                                      null, 
                                      () => {
                                        getSchedules(orderBy, order, page);
                                      })
                                  }}
                                >
                                  <img src={editIcon} alt="" />
                                </button> 
                              }
                            </StyledTableCell>
                          </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Box>
              </TableContainer>   
              <TablePagination
                rowsPerPageOptions={[20]}
                component="div"
                count={counts}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> 
            </div>
          }
      </div>
  );
}