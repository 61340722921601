import axios from 'axios';
import {BASE_URL, TIMEOUT} from '../utils/s-constants';
interface passengerFilter {
    persDni?: string,
    email?: string, 
    name?: string, 
    firstLastname?: string, 
    secondLastname?: string, 
    rol?: string,
    status?: any,
    cocliHabitual?: string,
    pageSize?: number, 
    page?: number
}

class Passengers {
    static getPassengersByTravelAgent = (id: number, passenger: passengerFilter, navigate: any) => {

        const data: any = {};
        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}`};

        if (passenger.persDni !== ''){
            data.persDni = [passenger.persDni];
        }
        if (passenger.email !== ''){
            data.email = [passenger.email];
        }
        if (passenger.name !== ''){
            data.name = [passenger.name];
        }
        if (passenger.firstLastname !== ''){
            data.firstLastname = [passenger.firstLastname];
        }
        if (passenger.secondLastname !== ''){
            data.secondLastname = [passenger.secondLastname];
        }
        if (passenger.cocliHabitual !== ''){
            data.cocliHabitual = [passenger.cocliHabitual];
        }
        if (passenger.rol !== ''){
            data.rol = [passenger.rol];
        }
        if (passenger.status !== ''){
            data.status = passenger.status;
        }

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/employee/organization/${id}`,
            method: 'POST',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: TIMEOUT,
            params: {pageSize: passenger.pageSize, pageNumber: passenger.page},
            data,
            })
            .then(async req => {
                const {data} = req;
                resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    }
    static createPassenger = (id: number, passenger: any, fields: any, navigate: any) => {

        let tmp = JSON.parse(JSON.stringify(passenger));
        fields.forEach((f: any) => {
            if(f.content && f.content.length > 0){
                if(!f.multiselect){
                    tmp[f.field.name] = tmp[f.field.name].name || '';
                }
            }
            if(f.field.cocli){
                tmp[f.field.name] = {
                    coclis: tmp[f.field.name].cocliList || [],
                    all: tmp[f.field.name].allCoclis || false,
                };
            }
        })

        tmp.stdIdHr = tmp.numero_empleado;
        tmp.lock = false;
        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}`};

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/employee/organization/${id}/new`,
            method: 'POST',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: TIMEOUT,
            params: {},
            data: tmp,
            })
            .then(async req => {
                const {data} = req;
                resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    }
    static detailPassenger = (id: number, userOrgId: number, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}`};

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/employee/${id}/organization/${userOrgId}`,
            method: 'GET',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: TIMEOUT,
            })
            .then(async req => {
                const {data} = req;
                resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    }
    static updatePassenger = (id: number, passenger: any, fields: any,  userOrgId: number, navigate: any) => {

        fields.forEach((f: any) => {
            if(f.content && f.content.length > 0){
                if(!f.multiselect){
                    passenger[f.field.name] = passenger[f.field.name].name || '';
                }
            }
            if(f.field.cocli){
                passenger[f.field.name] = {
                    coclis: passenger[f.field.name].cocliList || [],
                    all: passenger[f.field.name].allCoclis || false,
                } ;
            }
        })

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}`};

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/employee/${id}/organization/${userOrgId}`, 
            method: 'PUT',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: TIMEOUT,
            params: {},
            data: passenger,
            })
            .then(async req => {
                const {data} = req;
                resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    }
    static deletePassenger = (id: number,  userOrgId: number, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}`};

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/employee/${id}/organization/${userOrgId}`,
            method: 'DELETE',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: TIMEOUT,
            })
            .then(async req => {
                const {data} = req;
                resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    }
}
export default Passengers;