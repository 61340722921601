import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

export interface DropdownState {
  value: any;
}

const initialState: DropdownState = {
  value: true,
};

export const DropdownSlice = createSlice({
  name: "dp",
  initialState,
  reducers: {
    selectDropdownItem: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
  },
});

export const { selectDropdownItem } = DropdownSlice.actions;

export const selectDrowpdown = (state: RootState) => state.dp.value;

export default DropdownSlice.reducer;
