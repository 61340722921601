import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import Styles from "./c-drag-drop.module.css";

const fileTypes: any = ["JPG", "JPEG", "PNG"];

function DragDrop(props: any) {
  const [file, setFile] = useState(null);
  const [typeError, setTypeError] = useState(false);

  const handleChange = (file: any) => {
    setTypeError(false);
    setFile(file.name);
    props.file(file)
  };

  const handleTypeError = () => {
    setTypeError(true);
  }

  const handleLabel = () => {
    if(typeError){
      return props.typeError;
    }else if(file){
      return props.typeUpload
    }else {
      return props.label;
    }
  }

  return (
    <FileUploader 
      classes={Styles.guZdik} 
      label={props.label} 
      handleChange={handleChange} 
      name={file} 
      types={fileTypes} 
      maxSize={10} 
      onSizeError={handleTypeError}
      onTypeError={handleTypeError}
      children={
        <div className={Styles.c_drag_drop_container}>
          <div className={`${Styles.c_drag_drop_left_container}`}>
            <input accept={fileTypes} type="file"></input>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.33317 6.66667H22.6665V16H25.3332V6.66667C25.3332 5.196 24.1372 4 22.6665 4H5.33317C3.8625 4 2.6665 5.196 2.6665 6.66667V22.6667C2.6665 24.1373 3.8625 25.3333 5.33317 25.3333H15.9998V22.6667H5.33317V6.66667Z" fill="#0658C2"></path><path d="M10.6665 14.6667L6.6665 20H21.3332L15.9998 12L11.9998 17.3333L10.6665 14.6667Z" fill="#0658C2"></path><path d="M25.3332 18.6667H22.6665V22.6667H18.6665V25.3333H22.6665V29.3333H25.3332V25.3333H29.3332V22.6667H25.3332V18.6667Z" fill="#0658C2"></path></svg>
            <span className={typeError ? Styles.c_drag_drop_error_msg : Styles.c_drag_drop_msg}>{handleLabel()}</span>
          </div>
          <div className={Styles.c_drag_drop_right_container}>
            <span className={typeError ? Styles.c_drag_drop_error_msg : Styles.c_drag_drop_msg}>{fileTypes.join(',')}</span>
          </div>
        </div>
      }
    />
  );
}

export default DragDrop;