import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Styles from "./v-schedule-exports.module.css";
import editIcon from "../../assets/icon-edit.svg";
import arrowIcon from "../../assets/icon-arrow.svg";
import sortIcon from "../../assets/icon-order.svg";
import closeIcon from "../../assets/icon-close.svg";
import infoIcon from "../../assets/icon-info.svg";

import CTextInput from '../../components/c-text-input/c-text-input';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, TablePagination, TableSortLabel } from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CDropDown from "../../components/c-dropdown/c-dropdown";
import { CButton } from "../../components/c-button/c-button";
import Schedule from "../../services/schedule/s-schedule";
import Frequencies from "../../services/schedule/s-frequencies";
import TravelAgent from "../../services/travel-agent/s-travel-agent";
import { useNavigate } from "react-router-dom";

type Order = 'asc' | 'desc';
interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: any;
  rowCount: number;
}
interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  disabledSort: boolean;
}

export default function VScheduleExports(props: any) {  
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()

  const [actualLanguage, setActualLanguage] = useState('');
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openFilterTravelAgent, setOpenFilterTravelAgent] = useState(false);
  const [openFilterExportStatus, setOpenFilterExportStatus] = useState(false);
  const [rows, setRows] = useState<any>([]);
  const [counts, setCounts] = useState(0);
  const [allRows, setAllRows] = useState<any>([]);
  const [frequencies, setFrequencies] = useState<any>([]);
  const [times, setTimes] = useState<any>([]);
  const [freq, setFreq] = useState<any>({});
  const [hour, setHour] = useState<any>({});
  const [travelAgentsWOrg, setTravelAgentsWOrg] = useState<any>({});
  const [travelA, setTravelA] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [btnType, setBtnType] = useState("disabled");
  const [travelAgent, setTravelAgent] = useState<any>(false);
  const [statusFilter, setStatusFilter] = useState<any>([]);  
  const [travelAgentFinder, setTravelAgentFinder] = useState<any>();
  const statuses_array = [
    {
      id: 0,
      name: t('DASHBOARD.PENDING'),
    },
    {
      id: 1,
      name: t('DASHBOARD.EXCECUTED'),
    },
    {
      id: 2,
      name: t('DASHBOARD.SUCCESS'),
    },
    {
      id: 3,
      name: t('DASHBOARD.FAIL'),
    },
    {
      id: 4,
      name: t('DASHBOARD.RETRY'),
    },
    {
      id: 5,
      name: t('DASHBOARD.STOPPED'),
    }
  ];
  const [statuses, setStatuses] = useState<any>([]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#f6f6f8',
      color: '#6c6c6c',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
    borderBottom: "none !important",
    fontFamily: 'Poppins, sans-serif',
    letterSpacing: 0.2,
    color: '#1b1b1e'
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    backgroundColor: "white !important",
    fontFamily: 'Poppins, sans-serif'
  }));

  const handleChange = (value: any, type: string) => {
    getSchedules(orderBy, order, page, {status: value.id});
  };

  const headCells: readonly HeadCell[] = [
    {
      id: "file",
      numeric: false,
      disablePadding: false,
      label: `${t('DASHBOARD.FILE.TITLE')}`,
      disabledSort: true,
    },
    {
      id: 'frequency',
      numeric: true,
      disablePadding: false,
      label:  `${t('DASHBOARD.FREQUENCY.TITLE')}`,
      disabledSort: true,
    },
    {
      id: 'startedDate',
      numeric: true,
      disablePadding: false,
      label: `${t('DASHBOARD.DATE.TITLE')}`,
      disabledSort: false,
    },
    {
      id: 'hour',
      numeric: true,
      disablePadding: false,
      label: `${t('DASHBOARD.HOUR.TITLE')}`,
      disabledSort: true,
    },
    {
      id: 'travel_agent',
      numeric: true,
      disablePadding: false,
      label: `${t('DASHBOARD.TRAVEL_AGENT.TITLE')}`,
      disabledSort: false,
    },
    {
      id: 'export_status',
      numeric: true,
      disablePadding: false,
      label: `${t('DASHBOARD.EXPORT_STATUS.TITLE')}`,
      disabledSort: false,
    },
  ];

  const defineIcon = (value: any) => {
    if(value === t('DASHBOARD.DATE.TITLE')) {
      return sortIcon;
    }else {
      return arrowIcon;
    }
  }

  const setButtonType = (type: any) => {
    switch(type) {
      case "loading":
        return (
          <CButton type="loading" />
          );
      case "done":
        return (
          <CButton placeholder={t('SCHEDULE_EXPORTS.BTN.DONE')} action={() => {}} />
        );
      case "disabled":
        return (
          <CButton placeholder={t('SCHEDULE_EXPORTS.BTN.SAVE')} type="disabled" />
        );
      default:
        return (
          <CButton placeholder={t('SCHEDULE_EXPORTS.BTN.SAVE')} 
            action={() => {
              setBtnType("loading"); 
              callCreateSchedule();
            }} 
          />
        );
    }
  }

  const callCreateSchedule = async () => {
    await Schedule.createSchedule(freq.id, hour.id, travelA.id, navigate).then((resp: any) => {
      setBtnType("done");
      getSchedules(orderBy, order, page);
      setFreq({});
      setHour({});
      setTravelA({});
      setRefresh(true);
      setTimeout(() => {
        setRefresh(false);
      }, 200);
      setTimeout(() => {
        setBtnType("disabled");
      }, 4000); 
    }).catch(err => {
      setBtnType("default");
      props.openModal(false, "modalSchedule");
      props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
      console.log(err);
    })
  }

  const getSchedules = (sort?: any, order?: any, newPage?: any, schedule?: any, travelAgent?: any) => {

    let ta = travelAgent;
    let status = statusFilter;

    if (schedule && (schedule.travelAgent || schedule.travelAgent === '')){
      ta = schedule.travelAgent;
      setTravelAgent(ta);
    }
    
    if (schedule && schedule.status >= 0){
      let tmpStatus = statuses;
      if(!status.includes(schedule.status)){
        status.push(schedule.status);
        tmpStatus = tmpStatus.map((s: any) => {
          let tmp = s;
          if(tmp.id === schedule.status){
            tmp.selected = true;
          }
          return tmp;
        })
      }else {
        status = status.filter((s: any) => s !== schedule.status);
        tmpStatus = tmpStatus.map((s: any) => {
          let tmp = s;
          if(tmp.id === schedule.status){
            tmp.selected = false;
          }
          return tmp;
        })
      }
      setStatuses(tmpStatus);
      setStatusFilter(status);
    }

    Schedule.getSchedule(navigate, sort, order, newPage, status, travelAgent).then((resp: any) => {
      let schedules = resp.data.data;
      schedules = schedules.map((value: any) => {
        value.travel_agent = {
          id: value.travel_agent.id,
          name: `${value.travel_agent.user.name} ${value.travel_agent.user.lastName} (${value.travel_agent.name})`
        }
        value.frequency.name = t(value.frequency.name);
        return value
      });            
      setRows(schedules);
      setAllRows(resp.data.data);
      setPage(resp.data.pageNumber);
      setRowsPerPage(resp.data.pageSize);
      setCounts(resp.data.totalElements);
    }).catch(err => {
      console.log(err)
    })
  }

  const updateSchedule = (val: any) => {
    Schedule.updateSchedule(val, false, false, navigate)
    .then((resp: any) => {
      props.openModal(false, "modalSchedule");
      setTimeout(() => {
        getSchedules(orderBy, order, page);
      }, 1000);
      props.openModal(true, "scheduleSuccess");
    }).catch((err: any) => {
      props.openModal(false, "modalSchedule");
      props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
      setTimeout(() => {
        getSchedules(orderBy, order, page);
      }, 1000);
    })
  }

  const filters = () => {
    const checkIfClickedOutside = (e: any) => {
      const element = e.target;
      const id = element.id.includes("statusF");
      const idIcon = element.id.includes("export_status");
      if(element.textContent !== t("DASHBOARD.EXPORT_STATUS.TITLE") 
        && !id && !idIcon
        && element.textContent !== t('DASHBOARD.PENDING') 
        && element.textContent !== t('DASHBOARD.EXCECUTED') 
        && element.textContent !== t('DASHBOARD.SUCCESS')
        && element.textContent !== t('DASHBOARD.FAIL')
        && element.textContent !==  t('DASHBOARD.RETRY') 
        && element.textContent !==  t('DASHBOARD.STOPPED')){
        setOpenFilterExportStatus(false);
      }
    }
    if(openFilterExportStatus){
      document.removeEventListener('click', checkIfClickedOutside);  
    }else {
        document.addEventListener('click', checkIfClickedOutside);
    }
    const checkIfClickedOutside2 = (e: any) => {
      const element = e.target;
      const id = element.id.includes("taF");
      const idIcon = element.id.includes("travel_agent");
      if(element.textContent !== t("DASHBOARD.TRAVEL_AGENT.TITLE") && !id && !idIcon){
        setOpenFilterTravelAgent(false);
      }
    }
    if(openFilterTravelAgent){
      document.removeEventListener('click', checkIfClickedOutside2);  
    }else {
        document.addEventListener('click', checkIfClickedOutside2);
    }
  }

  const getPosition = (e: any, ref: any) => {
    const scrolling = () => {
      window.removeEventListener('click', scrolling);
      window.scrollTo({ top: 10000, behavior: 'smooth' })
    };
    window.addEventListener('click', scrolling);
  }

  useEffect(() => {
    window.scrollTo(0,0);
    if(loading){
      setActualLanguage(i18n.language);
      let status = statuses_array.map((s: any) => s.id);
      let tmpStatus = statuses_array.map((s: any) => {
        s.selected = true;
        return s;
      });      
      Schedule.getSchedule(navigate, null, null, null, status).then((resp: any) => {
        let schedules = resp.data.data;
        schedules = schedules.map((value: any) => {
          value.travel_agent = {
            id: value.travel_agent.id,
            name: `${value.travel_agent.user.name} ${value.travel_agent.user.lastName} (${value.travel_agent.name})`
          }
          value.frequency.name = t(value.frequency.name);
          return value
        });            

        setRows(schedules);
        setAllRows(resp.data.data);
        setPage(resp.data.pageNumber);
        setRowsPerPage(resp.data.pageSize);
        setCounts(resp.data.totalElements);
        setStatuses(tmpStatus);
        setStatusFilter(status);
        Frequencies.getFrecuencies(navigate).then((resp: any) => {
          let frecs = resp.data;
    
          frecs = frecs.map((value: any) => {
              return {
                id: value.id,
                name: t(value.name)
              }
          })
          setFrequencies(frecs);
          Frequencies.getTimes(navigate).then((resp: any) => {
            setTimes(resp.data);
            TravelAgent.getTravelAgentsWOrg(navigate).then((resp: any) => {
              let agents = resp.data;
              agents = agents.map((value: any) => {
                return {
                  id: value.id,
                  name: `${value.user.name} ${value.user.lastName} (${value.name})`
                }
              });            
              setTravelAgentsWOrg(agents);
              setLoading(false);
              setRefresh(false);
            }).catch((err: any) => {
              props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
            });
      
          }).catch(err => {
            console.log(err);
          })
        }).catch(err => {
          console.log(err)
        })
      }).catch(err => {
        console.log(err)
      })
    }
    filters();
    if(actualLanguage !== i18n.language && !loading){
      setActualLanguage(i18n.language);
      getSchedules(orderBy, order, page);
    }
  }, [i18n.language])


  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } =
      props;
    const createSortHandler =
      (property: string) => (event: React.MouseEvent<unknown>) => {
        if(property === "startedDate"){
          onRequestSort(event, property);
        }else if(property === "travel_agent"){
          setOpenFilterTravelAgent(!openFilterTravelAgent);
        }else {
          setOpenFilterExportStatus(!openFilterExportStatus);
        }
      };
   
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              onClick={createSortHandler(headCell.id)}
            >
              {!headCell.disabledSort ?
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  IconComponent={
                    () => 
                    <span 
                      id={headCell.id}
                      onClick={() => {
                        createSortHandler(headCell.id)
                      }}
                    >
                      <img id={headCell.id} src={defineIcon(headCell.label)} alt="" style={{marginLeft: '12px', width: '16px', height: '16px'}}/>
                    </span>
                  }
                >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                </TableSortLabel> :
                <TableSortLabel hideSortIcon style={{ cursor: 'unset' }}>
                  {headCell.label}
                </TableSortLabel> 
              }
            </StyledTableCell>
          ))}
          <StyledTableCell align="left">&nbsp;</StyledTableCell>
        </TableRow>
      </TableHead>
    );
  }

  const handleStatus = (status: number, row: any) => {
    switch (status) {
      case 0: {
        return (
          <div className={Styles.v_schedule_exports_tablet_item_f}>
            <p>{t("SCHEDULE_EXPORTS.STATUS.0")}</p>
          </div>
        )
      }
      case 1: {
        return (
          <div className={Styles.v_schedule_exports_tablet_item_f}>
            <p>{t("SCHEDULE_EXPORTS.STATUS.1")}</p>
          </div>
        )
      }
      case 2: {
        return (
          <div className={Styles.v_schedule_exports_tablet_item_f}>
            <p>{t("SCHEDULE_EXPORTS.STATUS.2")}</p>
          </div>
        )
      }
      case 3: {
        return (
          <div className={Styles.v_schedule_exports_tablet_item_e}>
              <p className={Styles.v_schedule_exports_tablet_item_spam}> 
                <span onClick={() => {props.openModal(true, "modalRetryExport", row, false, 
                () => {
                  Schedule.retrySchedule(row.historyId, navigate)
                  .then((resp: any) => {
                    props.openModal(false, "modalRetryExport");
                    getSchedules(orderBy, order, page);
                  })
                  .catch(err => {
                    console.log(err);
                    props.openModal(false, "modalRetryExport");
                    props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
                  })
                }, null, true)}}>
                  <img src={infoIcon} alt="" className={Styles.v_schedule_exports_item_e}/>
                </span>
                {t("SCHEDULE_EXPORTS.STATUS.3")}
              </p>
          </div>
        )
      }
      case 4: {
        return (
          <div className={Styles.v_schedule_exports_tablet_item_f}>
            <p>{t("SCHEDULE_EXPORTS.STATUS.4")}</p>
          </div>
        )
      }
      case 5: {
        return (
          <div className={Styles.v_schedule_exports_tablet_item_e}>
              <p> 
                {t("SCHEDULE_EXPORTS.STATUS.5")}
              </p>
          </div>
        )
      }
    }
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    getSchedules(property, isAsc ? 'desc' : 'asc',  page);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    getSchedules(orderBy, order, newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

    return (
      <div>
      {!loading &&
        <div className={Styles.v_schedule_exports}>
          <div className={Styles.v_schedule_exports_header}>
            <h1 className={Styles.v_schedule_exports_header_title}>{t('SCHEDULE_EXPORTS.TITLE.ACTIVE_EXPORTS')}</h1>
          </div>
          {travelAgentFinder ?
          <div className={Styles.v_schedu_exports_header_filter}>
            <div className={Styles.v_schedu_exports_header_filter_content} 
            onClick={() => {
              setTravelAgentFinder("");
              getSchedules(orderBy, order, page, {}, null);
            }}>
              {travelAgentFinder}
              <img src={closeIcon} alt="close" height={"10px"} width={"10px"} className={Styles.v_schedule_exports_header_filter_clean}/>
            </div>
          </div>  
            : 
            null
          }
          <div className={Styles.v_schedule_exports_tablet_desktop}>
            <TableContainer>
              {openFilterTravelAgent &&
                <div className={Styles.v_schedule_exports_filter_container_t} id="taFilter">
                  <header className={Styles.v_schedule_exports_filter_close} id="taF1">
                    <label id="taF2" >{t('DASHBOARD.FILTER')}</label>
                    <button id="taF3" onClick={() => setOpenFilterTravelAgent(false)}>
                      <img src={closeIcon} alt="close" />
                    </button>
                  </header>
                    <div id="taF4" className={Styles.item}>
                      <CTextInput
                        id="taF5"
                        type="text"
                        placeholder={t("DASHBOARD.FINDBYNAME")}  
                        value={travelAgentFinder}
                        setValue={(value: any) => {
                          setTravelAgentFinder(value);
                          getSchedules(orderBy, order, page, {}, value);
                        }}
                      />
                  </div>
                </div>
                }
              {openFilterExportStatus &&
                <div className={Styles.v_schedule_exports_filter_container_e} id="statusFilter">
                  <header id="statusF1" className={Styles.v_schedule_exports_filter_close}>
                    <label id="statusF2">{t('DASHBOARD.FILTER')}</label>
                    <button id="statusF3" onClick={() => setOpenFilterExportStatus(false)}>
                      <img src={closeIcon} alt="close" />
                    </button>
                  </header>
                  <div id="statusF4" className={Styles.item}>
                  {statuses.map((row: any) => (
                      <FormControlLabel
                        id="statusF5"
                        key={row.id}
                        label={row.name}
                        control={
                          <Checkbox
                          id="statusF6"
                          name={row.name}
                          checked={row.selected}
                          onClick={() => {
                            handleChange(row, "platRoles")
                          }}                              
                          sx={{
                              color: '#979797',
                              '&.Mui-checked': {
                                color: '#017ef3',
                              },
                            }}
                          />
                        }
                      />                 
                    ))}  
                </div>
              </div>
              }
              <Box sx={{ overflow: "auto" }}>
              <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                <TableContainer sx={{ width: "100%", maxHeight: 440 }}>
                  <Table aria-label="customized table">
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {rows.map((row: any) => (
                        <StyledTableRow key={row.historyId}>
                          <StyledTableCell align="left">{row.id}</StyledTableCell>
                          <StyledTableCell align="left">{row.frequency.name}</StyledTableCell>
                          <StyledTableCell align="left">{row.startedDate}</StyledTableCell>
                          <StyledTableCell align="left">{row.hour.name}</StyledTableCell>
                          <StyledTableCell align="left">{row.travel_agent.name}</StyledTableCell>                
                          <StyledTableCell align="left"> 
                            {handleStatus(row.status, row)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <button style={{width: 20, height: 20}} onClick={() => {
                                window.scrollTo(0,0);
                                props.openModal(true, "modalSchedule", row, false, (val: any) => {updateSchedule(val)},
                                null, true, 
                                () => {
                                  props.openModal(false, "modalSchedule");
                                  props.openModal(true, "scheduleDelete", null, false, 
                                  () => {
                                    Schedule.updateSchedule(row, true, false, navigate).then((resp: any) => {
                                      props.openModal(false, "scheduleDelete");
                                      getSchedules(orderBy, order, page);
                                    }).catch((err: any) => {
                                      props.openModal(false, "scheduleDelete");
                                      props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
                                      getSchedules(orderBy, order, page);
                                    })
                                  },
                                  () => {
                                    props.openModal(false, "scheduleDelete");
                                    getSchedules(orderBy, order, page);
                                  },
                                  false, null);
                                })
                              }}
                            >
                                <img src={editIcon} alt="" />
                            </button> 
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            </TableContainer>   
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={counts}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> 
          </div>
            <div className={Styles.v_schedule_exports_subheader}>
              <h1 className={Styles.v_schedule_exports_header_title}>{t('SCHEDULE_EXPORTS.TITLE.NEW')}</h1>
              <h1 className={Styles.v_schedule_exports_subheader_title}>{t('SCHEDULE_EXPORTS.SUB.NEW')}</h1>
              {!refresh &&
                  <div className={Styles.v_schedule_exports_group}>
                    <div className={Styles.v_schedule_exports_group_inputs}>
                      <label>{t('SCHEDULE_EXPORTS.INPUT.FREQUENCY')}</label>
                      <CDropDown 
                        placeholder={t('SCHEDULE_EXPORTS.PLACEHOLDER.FREQUENCY')}
                        options={frequencies} 
                        selected={freq}
                        setSelected={(value: any) => {
                          if(hour.id && travelA.id){
                            setBtnType("enabled");
                          }
                          setFreq(value);
                        }}  
                        onClick={getPosition}
                      />
                    </div>
                    <div className={Styles.v_schedule_exports_group_inputs}>
                      <label>{t('SCHEDULE_EXPORTS.INPUT.HOUR')}</label>
                      <CDropDown 
                        placeholder={t('SCHEDULE_EXPORTS.PLACEHOLDER.HOUR')}
                        options={times} 
                        selected={hour}
                        setSelected={(value: any) => {
                          if(freq.id && travelA.id){
                            setBtnType("enabled");
                          }
                          setHour(value);
                        }}  
                        onClick={getPosition}
                      />
                    </div>
                    <div className={Styles.v_schedule_exports_group_inputs}>
                    <label>{t('SCHEDULE_EXPORTS.INPUT.TA')}</label>
                      <CDropDown 
                        placeholder={t('SCHEDULE_EXPORTS.PLACEHOLDER.TA')}
                        secondaryPlaceholder={t("MANAGE_PASSENGERS.PLACEHOLDER_SEARCH")}
                        titleButton={t("MANAGE_PASSENGERS.FINDER_BUTTON")}
                        finder
                        className="w-[100%] sm:w-[326px]"
                        options={travelAgentsWOrg} 
                        selected={travelA} 
                        setSelected={(value: any) => {
                          if(hour.id && freq.id){
                            setBtnType("enabled");
                          }
                          setTravelA(value)
                        }}
                        action={() => {}}
                        onClick={getPosition}
                      />
                    </div>
                    <div className={Styles.v_schedule_exports_group_inputs}>
                    </div>
                  </div>
              }
                <div className={Styles.v_schedule_exports_group_button}>
                  <div className={Styles.v_schedule_exports_button}>
                    {setButtonType(btnType)}      
                  </div>
                </div>
            </div>  
        </div>
      }
      </div>
  );
}