import { useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";

import Styles from "./c-language-drop.module.css";

import EsFlag from "../../assets/es-flag.svg";
import EnFlag from "../../assets/en-flag.svg";
import iconArrow from "../../assets/icon-arrow.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export default function CLanguageDrop() {
  const { i18n } = useTranslation();
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef<any>();

  const langs = [
    {
      id: 0,
      name: 'ES',
      flag: 'EsFlag',
    },
    {
      id: 1,
      name: 'EN',
      flag: 'EnFlag',
    },
  ]

  const setLanguage = (id: number) => {
    if(id === 0){
      i18n.changeLanguage("es");
    }else if(id === 1){
      i18n.changeLanguage("en");
    }
    setShowOptions(!showOptions);
  };
  
  const setFlag = (flag: string) => {
    switch(flag) {
      case "EsFlag": {
        return EsFlag;
      }
      case "EnFlag": {
        return EnFlag;
      }
    }
    return EsFlag
  }

  const handleOptions = () => {    
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowOptions(false);
      }
    }
        
    if(showOptions){
      document.removeEventListener('click', checkIfClickedOutside);
    }else{
      document.addEventListener('click', checkIfClickedOutside);
    }
  },[showOptions])

  return (
    <div>
      <div
        className={Styles.c_language_drop_selected}
        ref={ref}
        onClick={() => handleOptions()
        }
      >
        {i18n.language === "es" ? (
          <ReactSVG src={EsFlag}  />
        ) : (
          <ReactSVG src={EnFlag} />
        )}
        {i18n.language === "es" ? <p>ES</p> : <p>ENG</p>}
        <ReactSVG src={iconArrow} />
      </div>
      {showOptions === true ? (
        <div className={Styles.c_language_drop_options}>
          {langs.map((l: any) => (
            <div
                key={l.id}
                className={Styles.c_language_drop_options_item}
                onClick={() => {
                  setLanguage(l.id);
               }}
            >
              <ReactSVG src={setFlag(l.flag)} />
              <p>{l.name}</p>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
