import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Styles from "./v-manage-coclis-list.module.css";
import editIcon from "../../../assets/icon-edit.svg";
import arrowIcon from "../../../assets/icon-arrow.svg";
import sortIcon from "../../../assets/icon-order.svg";
import addIcon from "../../../assets/icon-add.svg"

import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import { Box, TablePagination, TableSortLabel } from "@mui/material";
import { visuallyHidden } from '@mui/utils';

import CTextInput from "../../../components/c-text-input/c-text-input";
import { CButton } from "../../../components/c-button/c-button";

import VManageCocliEdit from "../v-manage-coclis-edit/v-manage-coclis-edit";

import { useNavigate } from "react-router-dom";
import Cocli from "../../../services/cocli/s-cocli";
import VManageCoclisCreate from "../v-manage-coclis-create/v-manage-coclis-create";

type Order = 'asc' | 'desc';

interface cocliFilter {
  code?: string, 
  name?: string, 
  pageSize?: number, 
  page?: number
}
interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: any;
  rowCount: number;
}
interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  disabledSort: boolean;
}

export default function VManageCoclisList(props: any) {  
  const { t } = useTranslation();
  const navigate = useNavigate()

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [counts, setCounts] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [openFilterStatus, setOpenFilterStatus] = useState(false);
  const [rows, setRows] = useState<any>([]);
  const [allRows, setAllRows] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [createView, setCreateView] = useState(false);
  const [actualView, setActualView] = useState(true);
  const [editView, setEditView] = useState(false);
  const [cocliId, setCocliId] = useState();

  const [cocliName, setCocliName] = useState("");
  const [cocliCode, setCocliCode] = useState("");

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      height: "10px"
    },
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#f6f6f8',
      color: '#6c6c6c',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
    borderBottom: "none !important",
    fontFamily: 'Poppins, sans-serif',
    letterSpacing: 0.2,
    color: '#1b1b1e',
    height: "5px"
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({

    [`&.${tableRowClasses.root}`]: {
      height: "16px",
    },
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    backgroundColor: "white !important",
    fontFamily: 'Poppins, sans-serif'
  }));

  const filter = (cocli: cocliFilter) => {
    let name = cocliName;
    let code = cocliCode;
  
    if (cocli && (cocli.name || cocli.name === '')){
      name = cocli.name;
      setCocliName(name);
    }
    if (cocli && (cocli.code || cocli.code === '')){
      code = cocli.code;
      setCocliCode(code);
    }
  
    Cocli.getCoclis(
      {
        name, 
        code,
      },
      navigate
    )
    .then((req: any) => {
      const {data} = req;
      setRows(data.data);
      setCounts(data.totalElements);
      setRowsPerPage(data.pageSize);
      setLoading(false);
    })
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: `${t('MANAGE_COCLIS.LIST.COL_NAME')}`,
      disabledSort: true,
    },
    {
      id: 'code',
      numeric: true,
      disablePadding: false,
      label:`${t('MANAGE_COCLIS.LIST.COL_CODE')}`,
      disabledSort: true,
    },
  ];

  const defineIcon = (value: any) => {
    if(value === t('DASHBOARD.DATE.TITLE')) {
      return sortIcon;
    }else {
      return arrowIcon;
    }
  }

  const actualV = (value: any) => {
    if(createView){
      setCreateView(value);
    }else if(editView){
      setEditView(value);
    }
    filter({});
  }

  const handleNewPassenger = () => {
      props.back(true, () => {
        setCreateView(false);
        setActualView(true);
        props.back(false);
      });
      setActualView(false);
      setCreateView(true);
  }

  const handleEditCocli = async (value: any) => {
    setCocliId(value);
    setEditView(true);
    props.back(true, () => {
      setEditView(false);
      setActualView(true);
      props.back(false);
    });
  }

  useEffect(() => {
    if(loading){
      Cocli.getCoclis({}, navigate)
      .then((resp: any) => {
        let tmp = resp.data.data;
        setRows(tmp);
        setPage(resp.data.pageNumber);
        setRowsPerPage(resp.data.pageSize);
        setCounts(resp.data.totalElements);
        setAllRows(tmp);
        setLoading(false);
      });
    }
  }, [loading, navigate, rows])

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy } =
      props;
    const createSortHandler =
      (property: string) => (event: React.MouseEvent<unknown>) => {
          setOpenFilterStatus(!openFilterStatus);
      };
   
    return (
        <TableHead>
            <TableRow>
            {headCells.map((headCell) => (
                <StyledTableCell
                  key={headCell.id}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                {!headCell.disabledSort ?
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                      IconComponent={() => 
                        <span 
                          id={headCell.id}
                          onClick={() => {
                            createSortHandler(headCell.id)
                          }}
                        >
                          <img  id={headCell.id} src={defineIcon(headCell.label)} alt="" style={{marginLeft: '12px', width: '16px', height: '16px'}}/>
                        </span>
                      }>
                        {headCell.label}
                        {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                        ) : null}
                    </TableSortLabel> :
                    <TableSortLabel hideSortIcon style={{ cursor: 'unset' }}>
                      {headCell.label}
                    </TableSortLabel> 
                }
                </StyledTableCell>
            ))}
            <StyledTableCell align="left">&nbsp;</StyledTableCell>
            </TableRow>
        </TableHead>
    );
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    Cocli.getCoclis(
      {
        name: cocliName, 
        code: cocliCode,
        page: newPage,
        pageSize: rowsPerPage
      },
      navigate
    )
    .then((req: any) => {
      const {data} = req;
      setRows(data.data);
      setCounts(data.totalElements);
      setRowsPerPage(data.pageSize);
      setLoading(false);
    })
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

    return (
      <div>
        {editView && !loading ?
          <VManageCocliEdit
            back={props.back}
            openModal={props.openModal} 
            cocliId={cocliId}
            from={actualV}
          /> 
          :
          createView ? 
            <VManageCoclisCreate
              back={props.back}
              openModal={props.openModal} 
              cocliId={cocliId}
              from={actualV}
            /> 
          :
          (!loading &&
            <div className={Styles.v_coclis_list}>
              <div className={Styles.v_coclis_list_search_group}>
                <div className={Styles.v_coclis_list_search_arragner}>
                  <label>{t("MANAGE_COCLIS.SEARCH_4_COCLIS")}</label>
                  <div className={Styles.v_coclis_list_search_arragner_grid}>
                    <CTextInput 
                      id="cocli-name"
                      type="text"
                      placeholder={t("MANAGE_COCLIS.LIST.COL_NAME")}  
                      value={cocliName}
                      setValue={(value: any) => {
                        filter({name: value || ''});
                      }}
                      />
                    <CTextInput 
                      id="cocli-code"
                      type="text"
                      placeholder={t("MANAGE_COCLIS.LIST.COL_CODE")}
                      value={cocliCode}
                      setValue={(value: any) => {
                        filter({code: value || ''})
                      }}
                    />
                    <div className={Styles.v_coclis_list_search_arragner_search_button}>
                      {cocliName || cocliCode ? 
                        <CButton placeholder={t("MANAGE_COCLIS.LIST.BTN_SEARCH")} action={filter}/>
                      :
                        <CButton placeholder={t("MANAGE_COCLIS.LIST.BTN_SEARCH")} type="disabled" /> 
                      } 
                    </div>
                  </div>
                </div>
              </div>
              <div className={Styles.v_coclis_list_header}>
                <div className={Styles.v_coclis_list_header_group}>
                  <p className={Styles.v_coclis_list_header_title}>{t("MANAGE_COCLIS.TITLE")}</p>
                  <CButton 
                    placeholder={t("MANAGE_COCLIS.LIST.BTN_NEW_COCLI")} 
                    type="secondary"
                    height={36} 
                    icon={addIcon} 
                    action={handleNewPassenger}
                  />
                </div>
                <div className={Styles.v_coclis_list_table}>
                  <TableContainer>
                    <Box sx={{ overflow: "auto" }}>
                      <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                      <TableContainer sx={{ width: "100%", maxHeight: 440 }}>
                        <Table stickyHeader aria-label="customized table" >
                          <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                          />
                          <TableBody>
                              {rows.map((row: any) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell align="left">{row.name}</StyledTableCell>
                                <StyledTableCell align="left">{row.code}</StyledTableCell>
                                <StyledTableCell align="left">
                                  <button style={{width: 20, height: 20}} 
                                    onClick={() => {handleEditCocli(row.id)}}
                                  >
                                      <img src={editIcon} alt="" />
                                  </button> 
                                </StyledTableCell>
                              </StyledTableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      </Box>
                    </Box>
                  </TableContainer>   
                  <TablePagination
                    rowsPerPageOptions={[20]}
                    component="div"
                    count={counts}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  /> 
                </div>
              </div>
            </div>
          )
        } 
      </div>

  );
}