import axios from 'axios';
import {BASE_URL, TIMEOUT} from '../utils/s-constants';

class TravelAgent {
    static getFields = (navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: '/api/v1/field',
            method: 'GET',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: TIMEOUT,
            })
            .then(async req => {
            const {data} = req;
            resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
            });
        });
    }
    static getTravelAgentsWOrg = (navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
                url: '/api/v1/user/list',
                method: 'GET',
                baseURL: BASE_URL,
                headers: newHeaders,
                timeout: TIMEOUT,
            })
            .then(async req => {
            const {data} = req;
            resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    }
    static create = (travel_agent: any, fields: any, taData: any, navigate: any) => {

        travel_agent.data = taData.map((value: any) => {
            if(value.content){
                value.content = value.content.split(',');
            }
            if(fields.find((f: any) => f.id === value.field_id).mandatory){
                value.mandatory = true;
                value.hide = false;
            }
            return value;
        });
        
        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
            axios
              .create()
              .request({
                url: '/api/v1/user/new',
                method: 'POST',
                baseURL: BASE_URL,
                headers: newHeaders,
                timeout: TIMEOUT,
                params: {},
                data: travel_agent,
              })
              .then(async req => {
                const {data} = req;
                taData.map((value: any) => {
                    if(value.content){
                        value.content = value.content.join(',');
                    }
                    return value;
                });
                resolve(data);
              })
              .catch(error => {
                const {data} = error?.response;
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                taData.map((value: any) => {
                    if(value.content){
                        value.content = value.content.join(',');
                    }
                    return value;
                });
                reject({error});
              });
          });
    };
    static getTravelAgents = (travel_agent: any, navigate: any) => {

        const data: any = {};
        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };
        
        if(travel_agent.name !== ''){
            data.name = [travel_agent.name];
        }
        if(travel_agent.firstLastname !== ''){
            data.lastname = [travel_agent.firstLastname];
        }
        if(travel_agent.email !== ''){
            data.email = [travel_agent.email];
        }

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
                url: '/api/v1/user/',
                method: 'POST',
                baseURL: BASE_URL,
                headers: newHeaders,
                timeout: TIMEOUT,
                params: {pageSize: travel_agent.pageSize, pageNumber: travel_agent.page},
                data,
            })
            .then(async req => {
            const {data} = req;
            resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    }
    static getAvatars = (userId: number, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };
  
        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/auth/profile/picture/${userId}`,
            method: 'GET',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: TIMEOUT,
            responseType: 'arraybuffer'
            })
            .then(async req => {
              const base64 = btoa(
                new Uint8Array(req.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ''
              ));
              resolve(base64);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    };
    static getTravelAgentDetail = (id: number, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/user/${id}`,
            method: 'GET',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: TIMEOUT,
            })
            .then(async req => {
                const {data} = req;
                resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
            });
        });
    }
    static updateTravelAgent = (id: number, travel_agent: any, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/user/${id}`,
            method: 'PUT',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: TIMEOUT,
            data: travel_agent,
            })
            .then(async req => {
                const {data} = req;
                resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject(error);
            });
        });
    }
    static deleteTravelAgent = (id: number, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/user/${id}`,
            method: 'DELETE',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: TIMEOUT,
            })
            .then(async req => {
                const {data} = req;
                resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject(error);
            });
        });
    }
}
export default TravelAgent;