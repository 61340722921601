import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Styles from "./v-manage-coclis-create.module.css";
import CTextInput from "../../../components/c-text-input/c-text-input";
import { CButton } from "../../../components/c-button/c-button";

import { useNavigate } from "react-router-dom";
import { CocliModel } from "../../../model/m-cocli";
import Cocli from "../../../services/cocli/s-cocli";

export default function VManageCoclisCreate(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const alphaFormat = /^([a-zA-Z ]+)$/
  const [cocliCode, setCocliCode] = useState("");
  const [cocliName, setCocliName] = useState("");

  const [formatCocliNameError, setFormatCocliNameError] = useState(false);
  const [formatCocliError, setCocliError] = useState(false);
  
  const [cocli, setCocli] = useState(new CocliModel());
  const [loading, setLoading] = useState(true);

  const saveData = () => {
    cocli.code = cocliCode;
    cocli.name = cocliName;
  }

  const createCocli = () => {

    saveData();
    Cocli.createCocli(cocli, navigate)
    .then((resp: any) => {
      props.openModal(true, "modalResponseSuccess", null, false, () => {
        props.back();
        props.from(false);
        props.openModal(false, "modalResponseSuccess");
      }, null, false, null, resp.result.info);
    })
    .catch((error: any) => {
      props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, error.response.data.result.info);
    });
  };

  const updateName = (data: any) => {
    setCocliName(data.trim());
  }
  
  const updateCode = (data: any) => {
    setCocliCode(data);
    if (alphaFormat.test(data)){
      setCocliError(true);
    }else{
      setCocliError(false);
    }
  }
  
  useEffect(() => {
    if(loading){
      setLoading(false);
    }
  },[loading, props]);

  return (
    <div className={Styles.v_coclis_create}>
      <div className={Styles.v_coclis_create_title_group}>
        <h1 className={Styles.v_coclis_create_title}>
          {t("MANAGE_COCLIS.CREATE.FIRST_FORM_TITLE")}
        </h1>
        <p className={Styles.v_coclis_create_subtitle}>
          {t("MANAGE_COCLIS.CREATE.FIRST_FORM_SUBTITLE")}
        </p>
        <div className={Styles.v_coclis_create_input_group}>
        </div>
      </div>
      <div className={Styles.v_coclis_create_form}>
        <div className={Styles.v_coclis_create_form_group}>
          <div className={Styles.v_coclis_create_input_group}>
            <label
              className={Styles.v_coclis_create_input_label}
              htmlFor="cocli-name"
            >
              {t("MANAGE_COCLIS.CREATE.FIRST_FORM_COCLI_NAME_LABEL")}*
            </label>
            <CTextInput
              id="cocli-name"
              type="text"
              placeholder={t("MANAGE_COCLIS.CREATE.FIRST_FORM_COCLI_NAME_LABEL")}
              value={cocliName}
              setValue={updateName}
            />
          </div>
          <div className={Styles.v_coclis_create_input_group}>
            <label
              className={Styles.v_coclis_create_input_label}
              htmlFor="cocli-code"
            >
              {t("MANAGE_COCLIS.CREATE.FIRST_FORM_COCLI_CODE_LABEL")}*
            </label>
            <CTextInput
              id="cocli-code"
              type="text"
              placeholder={t("MANAGE_COCLIS.CREATE.FIRST_FORM_COCLI_CODE_LABEL")}
              value={cocliCode}
              setValue={updateCode}
            />
          </div>
        </div>
      </div>
      <div className={Styles.v_coclis_create_data_form_button}>
        {cocliCode &&
        cocliName &&
        cocliName !== "" &&
        !formatCocliNameError &&
        !formatCocliError ? (
          <CButton placeholder={t("MANAGE_COCLIS.CREATE.FORM_BUTTON")} action={createCocli}/>
        ) : (
          <CButton placeholder={t("MANAGE_COCLIS.CREATE.FORM_BUTTON")} type="disabled" />
        )}
      </div>
    </div>
  );
}
