import Styles from "./c-text-input.module.css"
import EyeOn from "../../assets/icon-eye.svg";
import EyeOff from "../../assets/icon-eye-off.svg";
import { useEffect, useState } from "react";

function TextInput(props: any) {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [val, setVal] = useState("");

  
  const managePasswordVisibility = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const type = (value: any) => {
    if(value === "password"){
      if(showPassword){
        return "text"
      }else {
        return "password"
      }
    }else {
      return props.type
    }
  }

  const styling = () => {
    if(props.failed){
      if(props.className){
        return `${Styles.c_text_input_value_error_s} ${props.className}`;
      }else{
        return Styles.c_text_input_value_error;
      }
    }else if(val === ''){
      if(props.className){
        return `${Styles.c_text_input_value_s} ${props.className}`;
      }else {
        return Styles.c_text_input_value;
      }
    }else {
      if(props.className){
        return `${Styles.c_text_input_value_have_content_s} ${props.className}`;
      }else{
        return Styles.c_text_input_value_have_content;
      }
    }
  }

  useEffect(() => {
    // if(loading){
      setVal(props.value);
      setLoading(false);
    // }
  }, [props])

  return (
    <div>
      <div className={Styles.c_text_input_container}>
        <input
          id={props.id}
          ref={props.refa}
          className={styling()}
          type={type(props.type)}
          placeholder={props.placeholder}
          disabled={props.disabled}
          value={val}
          onChange={(e: any) => {
            setVal(e.target.value);
            props.setValue(e.target.value);
          }}
        />

        <span className={Styles.c_text_input_icon_span} onClick={managePasswordVisibility}>
          <div className={Styles.c_text_input_icon}>
            { props.type==="password"? 
              (showPassword ?
                <img
                  src={EyeOn}
                  style={{ height: 19, width: 20,  marginRight: 64}}
                  alt="On"
                /> :
                <img
                  src={EyeOff}
                  style={{ height: 19, width: 20 , marginRight: 64}}
                  alt="On"
                />          
              ) : null
            }
          </div>
        </span>
      </div>
      {props.failed && props.failedText &&
        <label className={Styles.c_text_input_failed_text}>{props.failedText}</label>
      }
    </div>
  );
}

export default TextInput;
