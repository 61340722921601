import axios from 'axios';
import {BASE_URL, TIMEOUT} from '../utils/s-constants';

class Frequencies {
    static getFrecuencies = (navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
            axios
              .create()
              .request({
                url: '/api/v1/frequency',
                method: 'GET',
                baseURL: BASE_URL,
                headers: newHeaders,
                timeout: TIMEOUT,
              })
              .then(async req => {
                const {data} = req;
                resolve(data);
              })
              .catch(error => {
                console.log(error);
                if(error.response){
                  if(error.response.status === 401){
                      localStorage.clear();
                      return navigate('/')
                  }
                  else if (error.response.status === 500){
                      return navigate('/500')
                  }
              }
              reject({error});
              });
          });
    };
    static getTimes = (navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
            axios
              .create()
              .request({
                url: '/api/v1/frequency/time',
                method: 'GET',
                baseURL: BASE_URL,
                headers: newHeaders,
                timeout: TIMEOUT,
              })
              .then(async req => {
                const {data} = req;
                resolve(data);
              })
              .catch(error => {
                console.log(error);
                if(error.response){
                  if(error.response.status === 401){
                      localStorage.clear();
                      return navigate('/')
                  }
                  else if (error.response.status === 500){
                      return navigate('/500')
                  }
              }
              reject({error});
              });
          });
    };
}
export default Frequencies;