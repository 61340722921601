import axios from 'axios';
import {BASE_URL, TIMEOUT} from '../utils/s-constants';

class Cocli {
    static getCoclis = (cocli: any, navigate: any) => {

        const data: any = {};
        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        if(cocli.name !== ''){
            data.name = [cocli.name];
        }
        if(cocli.code !== ''){
            data.code = [cocli.code];
        }

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: '/api/v1/cocli',
            method: 'POST',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: TIMEOUT,
            params: {pageSize: cocli.pageSize, pageNumber: cocli.page},
            data,
            })
            .then(async req => {
            const {data} = req;
            resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject(error);
            });
        });
    }
    static getUserOrgCoclis = (id: any, field_id: any, cocli: any, navigate: any) => {

        const data: any = {};
        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        if(cocli.name !== ''){
            data.name = [cocli.name];
        }
        if(cocli.code !== ''){
            data.code = [cocli.code];
        }

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/cocli/user_organization/${id}/field/${field_id}`,
            method: 'POST',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: TIMEOUT,
            params: {pageSize: cocli.pageSize, pageNumber: cocli.page},
            data,
            })
            .then(async req => {
            const {data} = req;
            resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject(error);
            });
        });
    }
    static getCocliDetail = (id: number, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/cocli/${id}`,
            method: 'GET',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: TIMEOUT,
            })
            .then(async req => {
            const {data} = req;
            resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject(error);
            });
        });
    }
    static createCocli = (cocli: any, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: '/api/v1/cocli/new',
            method: 'POST',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: TIMEOUT,
            data: cocli,
            })
            .then(async req => {
            const {data} = req;
            resolve(data);
            })
            .catch(error => {
                // console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject(error);
            });
        });
    }
    static updateCocli = (id: any, cocli: any, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/cocli/${id}`,
            method: 'PUT',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: TIMEOUT,
            data: cocli,
            })
            .then(async req => {
            const {data} = req;
            resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject(error);
            });
        });
    }
    static deleteCocli = (id: any, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/cocli/${id}`,
            method: 'DELETE',
            baseURL: BASE_URL,
            headers: newHeaders,
            timeout: TIMEOUT,
            })
            .then(async req => {
            const {data} = req;
            resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject(error);
            });
        });
    }
}
export default Cocli;
