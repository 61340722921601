import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Styles from "./c-dropdown.module.css";

import iconArrow from "../../assets/icon-arrow-2.svg";
import iconArrowUp from "../../assets/icon-arrow-up.svg";
import iconSearch from "../../assets/icon-search.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectDrowpdown, selectDropdownItem } from "../../store/slices/dropdownSlice";
import { Style } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";

export default function CDropDown(props: any) {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const dp = useAppSelector(selectDrowpdown);

  const [showOptions, setShowOptions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState<any>(false);
  const [selectedOptions, setSelectedOptions] = useState<any>(false);
  const [finderValue, setFinderValue] = useState("");
  const [rows, setRows] = useState<any>("");
  const ref = useRef<any>();

  const handleOptions = async (e: any) => {
    if(props.onClick){
      props.onClick(e);
    }
    await setShowOptions(!showOptions);
  };

  const handleMultiSelect = (option: any) => {
    let tmpRows: any = rows;
    if(tmpRows){
      tmpRows = tmpRows.map((r: any) => {
        if(r.id === option.id){
          r.selected = !r.selected;
        }
        return r;
      }); 
    }
    let tmp = tmpRows.filter((r: any) => r.selected).map((r: any) => r.name);
    setSelectedOptions(tmp.join('@@'));
    props.setSelected(tmp.join('@@'));
    setRows(tmpRows);
  }

  const setOption = (value: any) => {
    setSelectedOption(value);
    if(!props.finder){
      setShowOptions(!showOptions);
    }
    props.setSelected(value);
  };

  useEffect(() => {
    if((props.selected || props.selected === '') && loading){
      if(!props.multiselect){
        setSelectedOption(props.selected);
        setRows(props.options);
      }else{
        setSelectedOptions(props.selected);
        let tmp = props.selected.split('@@');
        let tmpRows = props.options;
        tmpRows = tmpRows.map((r: any) => {
          if(tmp.includes(r.name)){
            r.selected = true;
          }
          return r;
        }); 
        setRows(tmpRows);
      }
      setLoading(false);
    }
    const checkIfClickedOutside = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowOptions(false);
      }
    }
        
    if(showOptions){
      document.removeEventListener('click', checkIfClickedOutside);
    }else{
      document.addEventListener('click', checkIfClickedOutside);
    }

  }, [props.selected, props.options, loading, showOptions])


  return (
    <div className={props.className}
      ref={ref}
    >
      <div
        className={
          props.failed ? 
          `${Styles.c_dropdown_selected_f} ${props.className}`
          : 
          (props.className ? 
            (selectedOption && selectedOption.name ?
              `${Styles.c_dropdown_select_h}  ${props.className}` 
              :
              `${Styles.c_dropdown_selected_s} ${props.className}` 
            )
            : 
            (selectedOption && selectedOption.name?
              `${Styles.c_dropdown_select}  ${props.className}` 
              :
              Styles.c_dropdown_selected
            )
          )}
        onClick={(e: any) => handleOptions(e)}
      >
        <p>
          {selectedOption && selectedOption.name ? selectedOption.name : (selectedOptions ? selectedOptions : props.placeholder)}
        </p>
        {showOptions && dp?
          <img src={iconArrowUp} alt={""} className={Styles.c_dropdown_icon}/> :
          <img src={iconArrow} alt={""} className={Styles.c_dropdown_icon}/>
          }
      </div>
        {showOptions && dp ? (
        <div className={Styles.c_dropwdown_options_group}>
            <div className={
              !props.finder ? 
              (!props.multiselect ? Styles.c_dropdown_options : Styles.c_dropdown_options_multiselect) 
              : 
              Styles.c_dropdown_options_finder}>
              {props.finder &&
                <div
                  key={"finder"}
                  >
                  <div className={Styles.c_dropdown_input_main}>
                    <div className={Styles.c_dropdown_input_group}>
                      <img src={iconSearch} alt="" className={Styles.c_dropdown_input_img}/>
                      <input
                          className={Styles.c_dropdown_input_finder}
                          type="text"
                          placeholder={props.secondaryPlaceholder}
                          value={finderValue}
                          onChange={
                            (e: any) => {
                              let find = e.target.value.trim().toLowerCase();
                              setFinderValue(find)
                              let newRows = props.options.filter((value: any) => 
                              value.name.trim().toLowerCase()
                              .normalize('NFD').replace(/\p{Diacritic}/gu, '') //Esta linea quita los acentos para que í = i
                              .includes(find.normalize('NFD').replace(/\p{Diacritic}/gu, '')))
                              setRows(newRows)
                            }
                          }
                          />
                    </div>
                  </div>
                </div>              
              }
              <div className={rows && rows.length >= 10 ? Styles.c_dropdown_options_group_scroll : (
                !props.multiselect ? Styles.c_dropdown_options_group : Styles.c_dropdown_options_multiselect_group)
              }>
                {rows ? (
                  rows.map((option: any) => (
                    (!props.multiselect ?
                      <div
                        key={option.id}
                        className={
                          selectedOption && selectedOption.id === option.id ?
                            Styles.c_dropdown_option_selected 
                          : 
                            Styles.c_dropdown_option_notselected
                        }
                        onClick={() => {
                          setOption(option);
                          if(props.finder){
                            setShowOptions(false);
                            props.action(option);
                          }
                        }}
                      >
                        <p className={Styles.c_drowpdown_text}>
                          {option.name}
                        </p>
                      </div>
                      : 
                      <div className={Styles.c_dropdown_option_multiselect} key={option.id}>
                        <FormControlLabel
                            id="multiselect"
                            className={Styles.c_drowpdown_text_multiselect}
                            sx={{
                              fontFamily: 'Poppins, sans-serif',
                            }}
                            label={<Typography className={Styles.c_drowpdown_text_multiselect}>{option.name}</Typography>}
                            control={
                              <Checkbox
                                id="multiselect"
                                name={option.name}
                                checked={option.selected}
                                onClick={() => {handleMultiSelect(option)}}                              
                                sx={{
                                    color: '#979797',
                                    '&.Mui-checked': {
                                      color: '#017ef3',
                                    },
                                    padding: 0,
                                }}
                            />
                            }
                          />    
                   
                      </div>
                    )
                  ))
                ):   
                <div
                  onClick={() => {
                    setShowOptions(!showOptions);
                  }}
                  >
                  <p>...</p>
                </div>
              }
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
