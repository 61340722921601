import { Routes, Route } from "react-router-dom";

import VLogin from "./views/v-login/v-login";
import VGlobal from "./views/v-global/v-global";
import V404 from "./views/v-errors/v-error-404/v-error-404";
import V500 from "./views/v-errors/v-error-500/v-error-500";

const Routers = () => {

  return (
    <Routes>
      <Route path={'/'} element={<VLogin />} />
      <Route path={'/dashboard'} element={<VGlobal menuId={1}/>} />
      <Route path={'/create-ta'} element={<VGlobal menuId={2}/>} />
      <Route path={'/manage-passengers'} element={<VGlobal menuId={3}/>} />
      <Route path={'/schedules'} element={<VGlobal menuId={4}/>} />
      <Route path={'/travel-agents'} element={<VGlobal menuId={5}/>} />
      <Route path={'/coclis'} element={<VGlobal menuId={6}/>} />

      <Route path={'/dashboard-ta'} element={<VGlobal menuId={1}/>} />
      <Route path={'/new-passenger'} element={<VGlobal menuId={2}/>} />
      <Route path={'/report'} element={<VGlobal menuId={4}/>} />

      <Route path={'/404'} element={<V404 />} />
      <Route path={'/500'} element={<V500 />} />
    </Routes>
  );
};

export default Routers;
