import { useEffect, useState } from "react";

import Styles from "./c-modal.module.css";
import iconClose from "../../assets/icon-close.svg";
import iconCheck from "../../assets/icon-check.svg";
import iconAlert from "../../assets/icon-alert.svg";
import iconInfo from "../../assets/icon-info.svg";
import iconUpload from "../../assets/icon-upload.svg";

import { CButton } from "../c-button/c-button";
import CTextInput from "../c-text-input/c-text-input";
import DragDrop from "../c-drag-drop/c-drag-drop";
import { t } from "i18next";


export default function CModal(props: any) {
  const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [file, setImage] = useState<any>("");
  const [eFormat, setEmailFormat] = useState<boolean>(false);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

  const defineIconResult = () => {
    if(props.type === "success"){
      return <img src={iconCheck} alt="" className={Styles.c_modal_icon_success}/>
    }else if (props.type === "failed" || props.type === "retry"){
      return <img src={iconAlert} alt="" className={Styles.c_modal_icon_failed}/> 
    }
  }

  const handleFile = (file: any) => {
    setImage(file);
  }

  const updateEmail = (data: any) => {
    setEmail(data);
    if (!emailFormat.test(data)){
      setEmailFormat(true);
    }else{
      setEmailFormat(false);
    }
  }

  const validateType = () => {
    if(props.type === "forgotPassword"){
      if(email !== "" && !eFormat){
        return ""
      }
      return "disabled"
    }else if (props.type === "resetPassword"){
      if(newPassword !== "" && newPasswordRepeat !== "" && newPassword === newPasswordRepeat){
        return ""
      }
      return "disabled"
    }else if (props.type === "editModalAvatar"){
      if(file){
        return ""
      }
      return "disabled"
    }
  }

  const handleCleanInputs = () => {
    setEmail("");
    setNewPassword("");
    setNewPasswordRepeat("");
    setEmailFormat(false);
  }

  return (
    <div>
     {props.show &&
     <div>
      <div className={Styles.c_modal_background}></div>
      <div className={`${Styles.c_modal_container} ${props.width} ${props.height}`}>
        <header className={Styles.c_modal_header}>
          <button onClick={() => {
            setEmail("");
            setNewPassword("");
            setEmailFormat(false);
            handleCleanInputs();
            if(props.cancelCallback){
              props.cancelCallback();
            }else {
              props.close(false, props.modalType)
            }
          }}
          >
            <img src={iconClose} alt="close" />
          </button>
        </header>
        <div className={Styles.c_modal_main_group}> 
        {props.type !== "forgotPassword" && props.type !== "resetPassword" && props.type !== "editModalAvatar" ? 
          <div className={Array.isArray(props.information) ? Styles.c_modal_content_group :
             (props.type ? Styles.c_modal_content_group_wicon : Styles.c_modal_content_group_principal)}>
              {defineIconResult()}
            <div className={Styles.c_modal_title_container}>
              <p className={Styles.c_modal_title}>{props.title}</p>
              <p className={Styles.c_modal_subtitle}>{props.subtitle}</p>
            </div>
              {props.information &&
                (Array.isArray(props.information) ? (
                  <div className={Styles.c_modal_information_group}>
                    {props.information.map((label: any) => (
                      <div 
                        key={label.id}
                        className={Styles.c_modal_information_mult}
                      >
                        <p>{label.text}</p>
                      </div> 
                    ))}
                  </div>
                  ): (
                  <div className={Styles.c_modal_information}>
                    <p>{props.information}</p>
                  </div>
                  )
                )
              }
              {props.moreInformation &&
                <div className={Styles.c_modal_information_extra}>
                  {props.moreInformation.map((value: any) => (
                    (props.dataInfotmation.map((data: any) => (
                      (value.id === data.id &&
                        (value.text !== "" && 
                          <div key={value.id} className={Styles.c_modal_information_extra_text}>
                            <label className={Styles.c_modal_information_bold_text}>{data.data}</label>
                            <label>{value.text}</label>
                          </div>
                        )
                      )
                    )))
                  ))}
                </div>
              }
              {props.warning && props.deleted > 0 && 
                <div className={Styles.c_modal_information_warning}>
                  {props.warning.map((value: any, index: any) => (
                    <div key={value.id} className={Styles.c_modal_information_mod_text}>
                      {index === 0 ?
                        <div className={Styles.c_modal_information_extra_icon_group}>
                          <img src={iconInfo} alt="" className={Styles.c_modal_information_extra_icon}/>
                          <label className={Styles.c_modal_information_text_secondary}>{value.text}</label>
                        </div>
                        :
                        (
                          index === 2 ?
                          <div className={Styles.c_modal_information_mod_text}>
                            <label className={Styles.c_modal_information_text_secondary}>{value.total}</label>
                            <label className={Styles.c_modal_information_text_primary}>{value.text}</label>
                          </div>
                          :
                          <label className={Styles.c_modal_information_text_primary}>{value.text}</label>
                        )
                      }
                    </div>
                  ))}
                </div>
              }
              {props.recovery && 
                <div className={Styles.c_modal_container_box}>
                  {props.recoveries.map((rec: any) => (
                    <div key={rec.id} className={Styles.c_modal_box}>
                      <div className={Styles.c_moda_main_box}>

                        <div className={Styles.c_modal_content_box}>
                          <div className={Styles.c_modal_file_container}>
                            <label className={Styles.c_modal_file_name}>
                              {rec.filename}
                            </label>
                          </div>
                          <label className={Styles.c_modal_file_date}>
                            {/* {moment(rec.createdDate).format('DD / MM / YYYY')} */}
                            {rec.createdDate}
                          </label>
                        </div>
                          {rec.reverse &&
                            <span className={Styles.c_modal_recovery_container}
                              onClick={() => {
                                if(props.callback){
                                  props.callback();
                                }
                              }}
                            >
                              <img src={iconUpload} alt="Recovery"/>
                              <label className={Styles.c_modal_file_recovery}>
                                {t("MODAL.RECOVER.FILE")}
                              </label>
                            </span>
                          }
                      </div>
                        <div className={Styles.c_modal_data_container}>
                          <div className={Styles.c_modal_data_text_container}>
                            <label className={Styles.c_modal_data_text}>{rec.created}</label>
                            <label className={Styles.c_modal_data_subtext}>{t("MODAL.RECOVER.1")}</label>
                          </div>
                          <div className={Styles.c_modal_data_text_container}>
                            <label className={Styles.c_modal_data_text}>{rec.deleted}</label>
                            <label className={Styles.c_modal_data_subtext}>{t("MODAL.RECOVER.2")}</label>
                          </div>
                          <div className={Styles.c_modal_data_text_container}>
                            <label className={Styles.c_modal_data_text}>{rec.modified}</label>
                            <label className={Styles.c_modal_data_subtext}>{t("MODAL.RECOVER.3")}</label>
                          </div>
                        </div>
                    </div>
                  ))}
                </div>
              }
          </div>
          : 
           null
        }
        {props.type === "forgotPassword" ?
          <div className={Styles.c_modal_content_group_format}>
            <div className={Styles.c_modal_title_container_format}>
              <p className={Styles.c_modal_title}>{props.title}</p>
              <p className={Styles.c_modal_subtitle}>{props.subtitle}</p>
              <div className={Styles.c_modal_forgot_email_group}>
                <CTextInput
                  id="c_modal_forgot1"
                  type="text"
                  placeholder={t("LOGIN.USER_PLACEHOLDER")}
                  value={email}
                  setValue={updateEmail}
                  failed={eFormat}
                  failedText={t("CREATE_TRAVEL_AGENT.FORM_FORMAT_ERROR")}      
                />
              </div>
            </div>
          </div>
          : 
          null
        }
        {props.type === "resetPassword" ? 
          <div className={Styles.c_modal_content_group_format}>
            <div className={Styles.c_modal_title_container_format}>
              <p className={Styles.c_modal_title}>{props.title}</p>
              <p className={Styles.c_modal_subtitle}>{props.subtitle}</p>
              <div className={Styles.c_modal_reset_email_group}>
                <CTextInput
                  id="c_modal_reset3"
                  type="password"
                  placeholder={t("LOGIN.NEW_PASSWORD")}
                  value={newPassword}
                  setClick={setNewPassword}
                  setValue={(password: any) => {
                    setNewPassword(password);
                  }}   
                />
                <CTextInput
                  id="c_modal_reset4"
                  type="password"
                  placeholder={t("LOGIN.NEW_PASSWORD_REPEAT")}
                  value={newPasswordRepeat}
                  setClick={setNewPasswordRepeat}
                  setValue={(password: any) => {
                    setNewPasswordRepeat(password);
                  }}   
                />
                {newPassword !== "" && newPasswordRepeat !== "" && newPassword !== newPasswordRepeat ? 
                    <span className={Styles.c_modal_error_msg}>{t('MODAL.PASSWORD_FORMAT')}</span>
                  : null  
                }
              </div>
            </div>
          </div>
          : null
        }
        {props.type === "editModalAvatar" ?
          <div className={Styles.c_modal_content_group_format}>
            <div className={Styles.c_modal_title_container_format}>
              <p className={Styles.c_modal_title}>{props.title}</p>
              <div className={Styles.c_modal_drag_drop_group}>
                <DragDrop 
                  label={t('MODAL.AVATAR.SUBTITLE')}
                  file={handleFile} 
                  typeUpload={t('MODAL.AVATAR_UPLOADED_TYPE')} 
                  typeError={t('MODAL.AVATAR_ERROR_TYPE')} />
              </div>
            </div>
          </div>
          : 
          null
        }
          </div>
          <div className={Styles.c_modal_button_group}>
            {props.secondaryBtn &&
              <div className={Styles.c_modal_button_secondary}>
                <CButton 
                  placeholder={props.secondaryBtn} 
                  type="secondary" 
                  action={() => {
                    if(props.cancelCallback){
                      props.cancelCallback();
                    }else{
                      props.close(false, props.modalType)
                    }
                  }}
                />
              </div>
            }
            {props.primaryBtn && props.type !== "editModalAvatar" && 
              <div className={Styles.c_modal_button_primary}>
                <CButton
                  placeholder={props.primaryBtn}
                  action={() => {
                    if(props.callback){
                      if(email){
                        props.callback(email);
                        handleCleanInputs();
                      }else if (newPassword){
                        props.callback(newPassword);
                        handleCleanInputs();
                      }else{
                        props.callback();
                      }
                    }else{
                      props.close(false, props.modalType)
                    }
                    }}
                  type={validateType()}
                />
              </div>
            }
            {props.type === "editModalAvatar" &&
              // (loadingBtn ?  
              //   <div className={Styles.c_modal_button_primary}>
              //     <CButton type="loading" />
              //   </div>
              // : 
                <div className={Styles.c_modal_button_primary}>
                  <CButton
                    placeholder={props.primaryBtn}
                    action={() => {
                      if (file){
                        props.callback(file);
                        setImage(null);
                        setLoadingBtn(true);
                      } 
                    }}
                    type={validateType()}
                  />
                </div>
              // )
            }
          </div>
        </div>
      </div>
     }
    </div>
  );
};
