import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Styles from "./v-manage-passengers-create.module.css";
import CDropDown from "../../../components/c-dropdown/c-dropdown";
import CTextInput from "../../../components/c-text-input/c-text-input";
import { CButton } from "../../../components/c-button/c-button";

import addIcon from "../../../assets/icon-add.svg";

import { PassengerModel } from "../../../model/m-passenger";
import Passengers from "../../../services/passengers/s-passengers";
import { useNavigate } from "react-router-dom";
import Fields from "../../../services/travel-agent/s-fields";
import User from "../../../services/utils/s-user";
import { selectMenu, selectMenuItem } from "../../../store/slices/menuSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";


export default function VManagePassengersCreate(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const instanceUser = new User();
  const dispatch = useAppDispatch();
  const menu = useAppSelector(selectMenu);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>({});
  const [fields, setFields] = useState<any>();
  const [mandatoryFields, setMandatoryFields] = useState<any>();
  const [org, setTravelAgentOrg] = useState<any>();
  const [passenger, setPassenger] = useState<any>(new PassengerModel());
  
  const [cocliListNames, setCocliListNames] = useState<any>([]);

  const callCreatePassenger = () => {
    let send = true;
    let tmpFields = fields;
    mandatoryFields.map((t: any) => {
      if(passenger[t.field.name] === "" || 
      (t.field.cocli && (!passenger[t.field.name].cocliList || (passenger[t.field.name].cocliList.length === 0 && !passenger[t.field.name].allCoclis))) || 
      (!t.field.cocli && !t.multiselect && t.content.length && !passenger[t.field.name].id)){
        send = false;
        tmpFields = tmpFields.map((f: any) => {
          if(f.id === t.id){
            f.error = true
          }
          return f;
        });
      }else {
        tmpFields = tmpFields.map((f: any) => {
          if(f.id === t.id){
            f.error = false;
          }
          return f;
        });
      }
    })
    tmpFields = tmpFields.map((t: any) => {
      if(t.field.regex && passenger[t.field.name]){
        
        const validation = t.field.regex ?
        new RegExp(t.field.regex) :
        false;

        if(validation){
          if (!validation.test(passenger[t.field.name])){
            send = false;
            t.formatError = true
          }else {
            t.formatError = false;
          }
        }
      }
      return t;
    })
    setFields(tmpFields);

    if(send){
      window.scrollTo(0, 0);
      Passengers.createPassenger(org, passenger, fields, navigate)
      .then((resp: any) => {
        props.openModal(true, "modalResponseSuccess", null, false, 
        () => {
          if(props.from){
            props.from(false)
          }else{
            if(menu !== 1){
              dispatch(selectMenuItem(1));
              navigate('/dashboard-ta');
              props.openModal(false, "passengerSuccessCreated");
            }else {
              props.back(false);
              props.from(false);
              props.openModal(false, "passengerSuccessCreated");
            }
          }
          props.openModal(false, "modalResponseFailed");
        },
        () => {
          props.from(false)
          props.openModal(false, "modalResponseFailed");
        }, 
        false, null, resp.result.info);
      }).catch((err: any) => {
        props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
      });
    }else {
      window.scrollTo(0, 0);
    }
  }

     // eslint-disable-next-line react-hooks/exhaustive-deps
     const validateFields = () => {
      let tmpFields = fields;
      if (mandatoryFields){
        mandatoryFields.map((t: any) => {
          if(passenger[t.field.name] === "" || 
          (t.field.cocli && (!passenger[t.field.name].cocliList || (passenger[t.field.name].cocliList.length === 0 && !passenger[t.field.name].allCoclis))) || 
          (!t.multiselect && t.content.length && !passenger[t.field.name].id)){
            tmpFields = tmpFields.map((f: any) => {
              if(f.id === t.id){
                f.error = true;
              }
              return  f;
            });
          }else {
            tmpFields = tmpFields.map((f: any) => {
              if(f.id === t.id){
                f.error = false;
              }
              return  f;
            });
          }
        })
      }
  
      if(tmpFields){
        tmpFields = tmpFields.map((t: any) => {
          if(t.field.regex && passenger[t.field.name]){
            
            const validation = t.field.regex ?
            new RegExp(t.field.regex) :
            false;
    
            if(validation){
              if (!validation.test(passenger[t.field.name])){
                t.formatError = true;
              }else {
                t.formatError = false;
              }
            }
          }
          return t;
        })
      }
      setFields(tmpFields);
    }

  useEffect(() => {
    if(loading){
      const user = instanceUser.getUser();
      setUser(instanceUser.getUser());
      Fields.getFields(props.org, navigate)
      .then((resp: any) => {
        if(!mandatoryFields){
          setMandatoryFields(resp.data.selection.filter((f: any) => f.mandatory));
        }
        if(!fields){
          setFields(resp.data.selection);
        }
        setTravelAgentOrg(props.org);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
    }
    validateFields();
  }, [props.data, props.org])

  return (
    <div>
    {!loading &&
      <div className={Styles.v_manage_passengers_create}>
        <div className={Styles.v_manage_passengers_create_title_group}>
            <h1 className={Styles.v_manage_passengers_create_title}>
              {t("MANAGE_PASSENGERS.CREATE.TITLE")}
            </h1>
            <p className={Styles.v_manage_passengers_create_subtitle}>
              {t("MANAGE_PASSENGERS.CREATE.SUBTITLE")}
            </p>
        </div>

        <div className={Styles.v_manage_passengers_create_group}>
          <div className={Styles.v_manage_passengers_create_group_column_titles}>
            <label className={Styles.v_manage_passengers_create_field_title}>
              {t("MANAGE_PASSENGERS.CREATE.FIELD")}
            </label>
            <label className={Styles.v_manage_passengers_create_field_title}> 
              {t("MANAGE_PASSENGERS.CREATE.CONTENT")}
            </label>
          </div>
        {fields.map((value: any) => (
          <div 
            key={value.id}
            className={Styles.v_manage_passengers_create_group_column}
          >
            <label className={value.error || value.formatError ? Styles.v_manage_passengers_create_content_title_red :
              Styles.v_manage_passengers_create_content_title}
            >
              {value.mandatory ? `${value.field.name}*`  : value.field.name}
            </label>
            <div className={Styles.v_manage_passengers_create_content}>
              {value.field.cocli ?
                <div className={Styles.v_manage_passengers_create_content_values}>
                  <CButton 
                    placeholder={t("MANAGE_COCLIS.LIST.BTN_ASOC_COCLI")} 
                    type="secondary"
                    height={36} 
                    icon={addIcon} 
                    action={() => {
                      props.openModal(true, "selectCocli",
                      {
                        cocliList: passenger[value.field.name].cocliList, 
                        allCoclis: passenger[value.field.name].allCoclis, 
                        type: 'passenger', 
                        org: props.org, 
                        field_id: value.id,
                        multiselect: value.multiselect
                      }, 
                      false, 
                      (allCoclis: any, cocliList: any, cocliListCodes: any) => {
                        passenger[value.field.name].cocliList = cocliList;
                        passenger[value.field.name].allCoclis = allCoclis;

                        let tmp = cocliListCodes;
                        tmp = tmp.map((c: any) => c.name);
                        let tmpNames = tmp.join('@@');
                        setCocliListNames(tmpNames);
                        passenger[value.field.name].cocliListNames = tmpNames;
                        
                        if(tmpNames.length > 30){
                          tmpNames = tmpNames.substring(0,60).concat('...');
                        }
                        passenger[value.field.name].cocliListCodes = tmpNames;
                        validateFields();
                        props.openModal(false, "selectCocli");
                      }, 
                      () => {
                        props.openModal(false, "selectCocli");
                      }, false, null, null);
                    }}
                  />
                  {/* <input
                    className="appearance-none border rounded-[10px] w-full h-[36px] py-2 px-3 text-[#1b1b1e] leading-tight focus:outline-none focus:shadow-outline text-[14px] font-normal"
                    type="text"
                    title={passenger[value.field.name].cocliListNames}
                    placeholder={value.field.name}
                    value={passenger[value.field.name].cocliListCodes}
                    disabled
                    readOnly
                  /> */}
                  <CTextInput 
                    id={value.id}
                    type="text"
                    className={"h-[36px]"}
                    placeholder={value.field.name}
                    value={passenger[value.field.name].cocliListCodes}
                    disabled
                    failed={value.error || value.formatError}
                  />
                </div>
              :
              (value.content.length ?
                (value.multiselect ?
                  <CDropDown
                    className={"h-[30px]"}
                    placeholder={t("MANAGE_PASSENGERS.CREATE.SELECT")}
                    options={value.content} 
                    multiselect
                    selected={passenger[value.field.name] || ''} 
                    setSelected={(e: any) => {
                      passenger[value.field.name] = e;
                      validateFields();
                    }}
                    failed={value.error || value.formatError}
                  />
                    :
                  <CDropDown
                    className={"h-[30px]"}
                    placeholder={t("MANAGE_PASSENGERS.CREATE.SELECT")}
                    options={value.content} 
                    selected={passenger[value.field.name] || {}} 
                    setSelected={(e: any) => {
                      passenger[value.field.name] = e;
                      validateFields();
                    }}
                    failed={value.error || value.formatError}
                  />
                ):
                <CTextInput 
                  id={value.id}
                  type="text"
                  className={"h-[30px]"}
                  placeholder={t('CREATE_TRAVEL_AGENT.PLACEHOLDER')}
                  value={passenger[value.field.name]}
                  setValue={(e: any) => {
                    passenger[value.field.name] = e;
                    validateFields();
                  }}
                  failed={value.error || value.formatError}
                />
              )}
              {value.formatError &&
                <label className={Styles.v_manage_passengers_create_format_error}>
                  {`${t("MANAGE_PASSENGERS.FORMAT_ERROR")} ${value.field.example ?t("MANAGE_PASSENGERS.EXAMPLE") + value.field.example : ''}`}
                </label> 
              }
            </div> 
          </div>
          ))}
          <div className={Styles.v_manage_passengers_create_button_group}>
            <div className={Styles.v_manage_passengers_create_button}>
                <CButton 
                  placeholder={t("MANAGE_PASSENGERS.CREATE.BTN_CREATE")} 
                  action={() => callCreatePassenger()}
                /> 
            </div>
          </div>
        </div>
      </div>
    }
    </div>
  );
}

