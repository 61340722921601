import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Styles from "./v-manage-passengers-edit.module.css";
import CDropDown from "../../../components/c-dropdown/c-dropdown";
import CTextInput from "../../../components/c-text-input/c-text-input";
import { CButton } from "../../../components/c-button/c-button";

import deleteIcon from "../../../assets/icon-delete.svg";
import checkIcon from "../../../assets/icon-check.svg";
import alertIcon from "../../../assets/icon-alert.svg";
import addIcon from "../../../assets/icon-add.svg";

import Passengers from "../../../services/passengers/s-passengers";
import { PassengerModel } from "../../../model/m-passenger";
import { useNavigate } from "react-router-dom";

export default function VManagePassengersEdit(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true);
  const [editedSuccess, setEditedSuccess] = useState(false);
  const [editedFailed, setEditedFailed] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const [fields, setFields] = useState<any>();
  const [mandatoryFields, setMandatoryFields] = useState<any>();
  const [passenger, setPassenger] = useState<any>(new PassengerModel());
  const [passengerId, setPassengerId] = useState(0);
  const [userOrgId, setUserOrgId] = useState(0);
  const [timer, setTimer] = useState(5000);

  const [cocliListNames, setCocliListNames] = useState<any>([]);


  const callEditPassenger = () => {
    let send = true;
    let tmpFields = fields;
    mandatoryFields.map((t: any) => {
      if(passenger[t.field.name] === "" || 
        (t.field.cocli && (passenger[t.field.name].cocliList.length === 0 && !passenger[t.field.name].allCoclis)) || 
        (!t.field.cocli && !t.multiselect && t.content.length && !passenger[t.field.name].id)){
        send = false;
        tmpFields = fields.map((f: any) => {
          if(f.id === t.id){
            f.error = true
          }
          return f;
        });
      }else {
        tmpFields = fields.map((f: any) => {
          if(f.id === t.id){
            f.error = false;
          }
          return f;
        });
      }
    })
    tmpFields = tmpFields.map((t: any) => {
      if(t.field.regex && passenger[t.field.name]){
        
        const validation = t.field.regex ?
        new RegExp(t.field.regex) :
        false;

        if(validation){
          if (!validation.test(passenger[t.field.name])){
            send = false;
            t.formatError = true
          }else {
            t.formatError = false;
          }
        }
      }
      return t;
    })
    setFields(tmpFields);

    if(send){
      setEditedFailed(false);
      window.scrollTo(0, 0);
      Passengers.updatePassenger(passengerId, passenger, fields, userOrgId, navigate).then((resp: any) => {
        setEditedSuccess(true);
        let timerSuccess = setTimeout(() => {
          props.from(false);
          clearTimeout(timerSuccess);
        }, timer)
      }).catch(err => {
        console.log(err);
        setEditedFailed(true);
      });
    }else {
      window.scrollTo(0, 0);
      setEditedFailed(true);
    }
  }

  const callDeletePassenger = () => {
    Passengers.deletePassenger(passengerId, userOrgId, navigate).then((resp: any) => {
      props.openModal(false, "passengerDelete");
      setDeleteSuccess(true);
      let timerSuccess = setTimeout(() => {
        props.from(false);
        clearTimeout(timerSuccess);
      }, timer)
    }).catch((err: any) => {
      props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
    });
  }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validateFields = () => {
      let tmpFields = fields;
      if (mandatoryFields){
        mandatoryFields.map((t: any) => {
          if(passenger[t.field.name] === "" || 
          (t.field.cocli && (passenger[t.field.name].cocliList.length === 0 && !passenger[t.field.name].allCoclis)) || 
          (!t.multiselect && t.content.length && !passenger[t.field.name].id)){
            tmpFields = tmpFields.map((f: any) => {
              if(f.id === t.id){
                f.error = true
              }
              return  f;
            });
          }else {
            tmpFields = tmpFields.map((f: any) => {
              if(f.id === t.id){
                f.error = false;
              }
              return  f;
            });
          }
        })
      }
      if(tmpFields){
        tmpFields = tmpFields.map((t: any) => {
          if(t.field.regex && passenger[t.field.name] && passenger[t.field.name].trim() !== ''){
            
            const validation = t.field.regex ?
            new RegExp(t.field.regex) :
            false;
    
            if(validation){
              if (!validation.test(passenger[t.field.name])){
                t.formatError = true
              }else {
                t.formatError = false;
              }
            }
          }
          return t;
        })
      }
      setFields(tmpFields);
    }

  useEffect(() => {
    setTimeout(() => {
      setEditedSuccess(false);
      setEditedFailed(false);
      setDeleteSuccess(false);
    }, 15000); 

    if(loading){
      props.data.map((f: any) => {
        if(!f.field.cocli){
          passenger[f.field.name] = props.edit[f.field.name];
        }else{

          let tmp = props.edit[f.field.name].coclis_objs;
          tmp = tmp.map((c: any) => c.name);
          let tmpNames = tmp.join('@@');
          setCocliListNames(tmpNames);
          
          passenger[f.field.name] = {
            cocliList: props.edit[f.field.name].coclis,
            allCoclis: props.edit[f.field.name].all,
            cocliListObjs: props.edit[f.field.name].coclis_objs,
            cocliListNames: tmpNames,
            cocliListCodes: tmpNames.length > 30 ? tmpNames.substring(0,60).concat('...') : tmpNames,
          };
        }
      })
      setPassengerId(props.passengerId);
      setUserOrgId(props.org)
      setFields(props.data);
      setMandatoryFields(props.data.filter((f: any) => f.mandatory));
      setLoading(false);
    }else{
      validateFields();
    }

  }, [loading, passenger, props.data, props.edit, props.org, props.passengerId])

  return (
    <div>
      {deleteSuccess && 
        <div id="side-in" className={Styles.v_manage_passengers_edited_success}>
          <div className={Styles.v_manage_passengers_edited_group}>
            <img src={checkIcon} alt="Saved"/>
            <label className={Styles.v_manage_passengers_edited}>
            {t("MANAGE_PASSENGERS.EDIT.DELETED")}
            </label>
          </div>
        </div>
      }
      {editedSuccess && 
        <div id="side-in" className={Styles.v_manage_passengers_edited_success}>
          <div className={Styles.v_manage_passengers_edited_group}>
            <img src={checkIcon} alt="Saved"/>
            <label className={Styles.v_manage_passengers_edited}>
            {t("MANAGE_PASSENGERS.EDIT.STATE_SAVED")}
            </label>
          </div>
        </div>
      }
      {editedFailed && 
        <div id="side-in" className={Styles.v_manage_passengers_edited_failed}>
          <div className={Styles.v_manage_passengers_edited_group}>
            <img src={alertIcon} alt="Saved"/>
            <label className={Styles.v_manage_passengers_edited}>
            {t("MANAGE_PASSENGERS.EDIT.STATE_ERROR")}
            </label>
          </div>
        </div>
      }
    {!loading &&
      <div className={Styles.v_manage_passengers_edit}>
        <div className={Styles.v_manage_passengers_edit_title_group}>
          <div className={Styles.v_manage_passengers_edit_title_subgroup}>
            <h1 className={Styles.v_manage_passengers_edit_title}>
              {t("MANAGE_PASSENGERS.EDIT.TITLE")}
            </h1>
            <CButton
              placeholder={t("MANAGE_PASSENGERS.EDIT.BTN_DELETE")} 
              icon={deleteIcon} 
              type="text" 
              action={()=> {
                props.openModal(true, "passengerDelete", null, false, 
                () => {callDeletePassenger()}, 
                () => {props.openModal(false, "passengerDelete")}, true)}
              }
            />
          </div>
            <p className={Styles.v_manage_passengers_edit_subtitle}>
              {t("MANAGE_PASSENGERS.EDIT.SUBTITLE")}
            </p>
        </div>
        <div className={Styles.v_manage_passengers_edit_group}>
          <div className={Styles.v_manage_passengers_edit_group_column_titles}>
            <label className={Styles.v_manage_passengers_edit_field_title}>
              {t("MANAGE_PASSENGERS.EDIT.FIELD")}
            </label>
            <label className={Styles.v_manage_passengers_edit_field_title}> 
              {t("MANAGE_PASSENGERS.EDIT.CONTENT")}
            </label>
          </div>

          {fields.map((value: any) => (
            <div 
              key={value.id}
              className={Styles.v_manage_passengers_edit_group_column}
            >
              <label className={value.error || value.formatError ? Styles.v_manage_passengers_edit_content_title_red : Styles.v_manage_passengers_edit_content_title}>
                {value.mandatory ? `${value.field.name}*`  : value.field.name}
              </label>
                <div className={Styles.v_manage_passengers_edit_content}>
                  {value.field.cocli ?
                    <div className={Styles.v_manage_passengers_edit_content_values}>
                      <CButton 
                        placeholder={t("MANAGE_COCLIS.LIST.BTN_ASOC_COCLI")} 
                        type="secondary"
                        height={36} 
                        width={155}
                        icon={addIcon} 
                        action={() => {
                          props.openModal(true, "selectCocli",
                          {
                            cocliList: passenger[value.field.name].cocliList, 
                            allCoclis: passenger[value.field.name].allCoclis, 
                            cocliListObjs: passenger[value.field.name].cocliListObjs,
                            type: 'passenger', 
                            org: props.org, 
                            field_id: value.id,
                            multiselect: value.multiselect
                          }, 
                          false, 
                          (allCoclis: any, cocliList: any, cocliListCodes: any) => {
                            passenger[value.field.name].cocliList = cocliList;
                            passenger[value.field.name].allCoclis = allCoclis;
                            
                            let tmp = cocliListCodes;
                            tmp = tmp.map((c: any) => c.name);
                            let tmpNames = tmp.join('@@');
                            passenger[value.field.name].cocliListNames = tmpNames;
                            // setCocliListNames(tmpNames);
                            if(tmpNames.length > 30){
                              tmpNames = tmpNames.substring(0,60).concat('...');
                            }
                            passenger[value.field.name].cocliListCodes = tmpNames;
                            validateFields();
                            props.openModal(false, "selectCocli");
                          }, 
                          () => {
                            props.openModal(false, "selectCocli");
                          }, false, null, null);
                        }}
                      />
                      <CTextInput 
                        id={value.id}
                        type="text"
                        className={"h-[36px]"}
                        placeholder={value.field.name}
                        value={passenger[value.field.name].cocliListCodes}
                        disabled
                        failed={value.error || value.formatError}
                      />
                    </div>
                  :
                  (value.content.length ? 
                    (value.multiselect ?
                      <CDropDown
                        className={"h-[30px]"}
                        placeholder={t("MANAGE_PASSENGERS.CREATE.SELECT")}
                        options={value.content} 
                        multiselect
                        selected={passenger[value.field.name]} 
                        setSelected={(e: any) => {
                          passenger[value.field.name] = e;
                          validateFields();
                        }}
                        failed={value.error || value.formatError}
                      />
                        :
                      <CDropDown
                        className={"h-[30px]"}
                        placeholder={t("MANAGE_PASSENGERS.EDIT.SELECT")}
                        options={value.content} 
                        selected={passenger[value.field.name]} 
                        setSelected={(e: any) => {
                          passenger[value.field.name] = e;
                          validateFields();
                        }}
                        failed={value.error || value.formatError}
                      />
                    ):
                    <CTextInput 
                      id={value.id}
                      type="text"
                      className={"h-[30px]"}
                      placeholder={t('CREATE_TRAVEL_AGENT.PLACEHOLDER')}
                      value={passenger[value.field.name]}
                      setValue={(e: any) => {
                        passenger[value.field.name] = e;
                        validateFields();
                      }}
                      failed={value.error || value.formatError}
                    />
                  )}
                  {value.formatError &&
                    <label className={Styles.v_manage_passengers_edit_format_error}>
                      {`${t("MANAGE_PASSENGERS.FORMAT_ERROR")} ${value.field.example ?t("MANAGE_PASSENGERS.EXAMPLE") + value.field.example : ''}`}
                    </label> 
                  }
              </div> 
            </div>
          ))}
          <div className={Styles.v_manage_passengers_edit_button_group}>
            <div className={Styles.v_manage_passengers_edit_button}>
            
                <CButton placeholder={t("MANAGE_PASSENGERS.EDIT.BTN_EDIT")} 
                  action={() => {callEditPassenger()}}
                /> 
            </div>
          </div>
        </div>
      </div>
    }
    </div>
  );
}
