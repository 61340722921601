import { useEffect, useState } from "react";
import { t } from "i18next";

import Styles from "./v-global.module.css";
import CSideMenu from "../../components/c-side-menu/c-side-menu";
import CHeader from "../../components/c-header/c-header";
import CModalSchedule from "../../components/c-modal-schedule/c-modal-schedule";
import CModal from "../../components/c-modal/c-modal";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectMenuItem, selectMenu } from "../../store/slices/menuSlice";
import { selectDrowpdown, selectDropdownItem } from "../../store/slices/dropdownSlice";

import VDashboard from "../v-dashboard/v-dashboard";
import VCreateTravelAgent from "../v-create-travel-agent/v-create-travel-agent";
import VManagePassengers from "../v-manage-passengers/v-manage-passengers";
import VScheduleExports from "../v-schedule-exports/v-schedule-exports";
import VDataReport from "../v-data-report/v-data-report";
import VManagePassengersCreate from "../v-manage-passengers/v-manage-passengers-create/v-manage-passengers-create";
import VDashboardTA from "../v-dashboard-ta/v-dashboard-ta";

import User from "../../services/utils/s-user";
import { useNavigate } from "react-router-dom";
import V404 from "../v-errors/v-error-404/v-error-404";
import Auth from "../../services/auth/s-auth";
import Profile from "../../services/profile/s-profile";
import VManageTravelAgentList from "../v-manage-travel-agent/v-manage-travel-agent-list/v-manage-travel-agent-list";
import VManageCoclisList from '../v-manage-coclis/v-manage-coclis-list/v-manage-coclis-list';
import CModalTable from "../../components/c-modal-table/c-modal-table";

export default function VGlobal(props: any) {
  let menu = useAppSelector(selectMenu);
  const instanceUser = new User();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [modalVisibility, setModalVisibility] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalScheduleData, setModalScheduleData] = useState(true);
  const [modalScheduleType, setModalScheduleType] = useState(true);
  const [modalScheduleDataEditable, setModalScheduleDataEditable] = useState(true);
  const [modalUploadExcel, setModalUploadExcel] = useState(false);
  const [modalRetryExport, setModalRetryExport] = useState(false);
  const [modalResumeUpdates, setModalResumeUpdates] = useState(false);
  const [modalResumeUpdatesConfirm, setModalResumeUpdatesConfirm] = useState(false);
  const [sessionModal, setSessionModal] = useState(false);
  const [scheduleSuccess, setScheduleSuccess] = useState(false);
  const [scheduleFailed, setScheduleFailed] = useState(false);
  const [scheduleDelete, setScheduleDelete] = useState(false);
  const [selectCocli, setSelectCocli] = useState(false);
  const [modal, setModal] = useState("");
  const [modalResponseSuccess, setModalResponseSuccess] = useState(false);
  const [modalResponseFailed, setModalResponseFailed] = useState(false);
  const [editModalAvatar, setEditModalAvatar] = useState(false);
  const [msg, setMsg] = useState<any>("");

  const [successModal, setSuccessModal] = useState(false);
  const [failedModal, setFailedModal] = useState(false);
  const [passengerDelete, setPassengerDelete] = useState(false);
  const [cocliDelete, setCocliDelete] = useState(false);
  const [taDelete, setTADelete] = useState(false);
  const [showBack, setShowBack] = useState(false);
  const [backCallback, setBackCallback] = useState();
  const [cancelable, setCancelable] = useState(false);
  const [modalCallback, setModalCallback] = useState();
  const [modalCancelCallback, setModalCancelCallback] = useState();
  const [modalDeletrCallback, setModalDeleteCallback] = useState();
  const [modalRefreshCallback, setModalRefreshCallback] = useState();
  const [data, setData] = useState({});
  const [subtitle, setSubtitle] = useState({});
  const [warning, setWarning] = useState({});
  const [deleted, setDeleted] = useState<any>();
  const [avatar, setAvatar] = useState<any>();
  const [recoveries, setRecoveries] = useState({});
  // 14 minutos original
  const [timer, setTimer] = useState(840000);
  // 1 minutos original
  const [tmpTimer, setTmpTimer] = useState(60000);
  // 10 minutos original
  const [rToken, setRToken] = useState(600000);

  const handleModal = (visibility: boolean, type: any, data: any, editable: boolean, 
    callback: any, cancelCallback: any, cancelable: boolean, callbackDelete: any, msgRec: any, callbackRefresh: any) => {
    switch(type) {
      case "modalResponseSuccess": {
        setModalResponseSuccess(visibility);
        setMsg(msgRec);
        window.scrollTo(0, 0);
        setModal(type);
        setModalCallback(() => callback);
        break;
      }
      case "modalResponseFailed": {
        setModalResponseFailed(visibility);
        setMsg(msgRec);
        window.scrollTo(0, 0);
        setModal(type);
        setModalCallback(() => callback);
        break;
      }
      case "modalSchedule": {
        if(cancelable){
          setCancelable(cancelable);
        }
        setModalVisibility(visibility);
        setModalScheduleData(data);
        setModalScheduleType(type);
        setModalScheduleDataEditable(editable);
        setModal(type);
        setModalCallback(() => callback);
        setModalDeleteCallback(() => callbackDelete);
        setModalRefreshCallback(() => callbackRefresh)
        break;
      }
      case "modalNewSchedule":
      case "modalCreateTravelAgent": {
        setModalVisibility(visibility);
        setModalScheduleData(data);
        setModalScheduleType(type);
        setModalScheduleDataEditable(editable);
        setModalCallback(() => callback);
        window.scrollTo(0, 0);
        setModal(type);
        break;
      }
      case "success":
      case "passengerSuccessCreated": {
        setSuccessModal(visibility);
        window.scrollTo(0, 0);
        setModal(type);
        setModalCallback(() => callback);
        break;
      }
      case "failed":
      case "passengerFailedCreated": {
        setFailedModal(visibility)
        window.scrollTo(0, 0);
        setModal(type);
        break;
      }
      case "passengerDelete": {
        setPassengerDelete(visibility);
        window.scrollTo(0, 0);
        setModal(type);
        setModalCallback(() => callback);
        setModalCancelCallback(() => cancelCallback);
        break;
      }
      case "cocliDelete": {
        setCocliDelete(visibility);
        window.scrollTo(0, 0);
        setModal(type);
        setModalCallback(() => callback);
        setModalCancelCallback(() => cancelCallback);
        break;
      }
      case "taDelete": {
        setTADelete(visibility);
        window.scrollTo(0, 0);
        setModal(type);
        setModalCallback(() => callback);
        setModalCancelCallback(() => cancelCallback);
        break;
      }
      case "modalUploadExcel": {
        setModalUploadExcel(visibility);
        if(visibility){
          setData(data.data);
          setSubtitle(data.subtitle);
          setWarning(data.warning);
          setDeleted(data.deleted);
        }
        window.scrollTo(0, 0);
        setModal(type);
        setModalCallback(() => callback);
        setModalCancelCallback(() => cancelCallback);
        break;
      }
      case "modalRetryExport": {
        setModalRetryExport(visibility);
        window.scrollTo(0, 0);
        setModal(type);
        setModalCallback(() => callback);
        setModalCancelCallback(() => cancelCallback);
        break;
      }
      case "modalResumeUpdates": {
        setModalResumeUpdates(visibility);
        if(visibility){
         setRecoveries(data);
        }
        window.scrollTo(0, 0);
        setModal(type);
        setModalCallback(() => callback);
        break;
      }
      case "modalResumeUpdatesConfirm": {
        setModalResumeUpdatesConfirm(visibility);
        window.scrollTo(0, 0);
        setModal(type);
        setModalCallback(() => callback);
        break;
      }
      case "sessionModal": {
        setSessionModal(visibility);
        window.scrollTo(0, 0);
        setModal(type);
        setModalCallback(() => callback);
        setModalCancelCallback(() => cancelCallback);
        break;
      }
      case "scheduleSuccess": {
        setScheduleSuccess(visibility);
        window.scrollTo(0, 0);
        setModal(type);
        setModalCallback(() => callback);
        break;
      }
      case "setScheduleFailed": {
        setScheduleFailed(visibility);
        window.scrollTo(0, 0);
        setModal(type);
        setModalCallback(() => callback);
        break;
      }
      case "scheduleDelete": {
        setScheduleDelete(visibility);
        window.scrollTo(0, 0);
        setModal(type);
        setModalCallback(() => callback);
        setModalCancelCallback(() => cancelCallback);
        break;
      }
      case "editModalAvatar": {
        setEditModalAvatar(visibility);
        window.scrollTo(0, 0);
        setModal(type);
        setModalCallback(() => uploadImgCallback);
        setModalCancelCallback(() => cancelCallback);
        break;
      }
      case "selectCocli": {
        setSelectCocli(visibility);
        window.scrollTo(0, 0);
        setData(data);
        setModal(type);
        setModalCallback(() => callback);
        setModalCancelCallback(() => cancelCallback);
        break;
      }
    }
  }

  const uploadImgCallback: any = (f: any) => {
    if(f){
      Profile.uploadProfileImg(f, navigate)
      .then((resp: any) => {
        callAvatar();
        handleModal(false, "editModalAvatar", null, false, null, null, false, null, "", null);
        handleModal(true, "modalResponseSuccess", null, false, null, null, false, null, resp.result.info, null);
      }).catch((err: any) => {
        handleModal(true, "modalResponseFailed", null, false, null, null, false, null, "RESPONSE.ERROR", null);
      })
    }
  }

  const modifications = [
    {
      id: 1,
      text: "",
    },
    {
      id: 2,
      text: t("MANAGE_PASSENGERS.EXCEL.EXTRA_INFO_2"),
    },
    {
      id: 3,
      text: t("MANAGE_PASSENGERS.EXCEL.EXTRA_INFO_3"),
    },
    {
      id: 4,
      text: t("MANAGE_PASSENGERS.EXCEL.EXTRA_INFO_4"),
    },
  ];

  const modalToShow = (modal: string) => {
    switch(modal) {
      case "modalResponseSuccess": {
        return (
          <CModal 
            width="sm:w-[385px] w-[620px]" 
            height="h-[177px]" 
            show={modalResponseSuccess} 
            title={t(msg)}
            primaryBtn={t("MANAGE_PASSENGERS.CREATE.ACCEPT_BTN")}
            close={handleModal}
            modalType={"passengerSuccessCreated"}
            callback={modalCallback}
            type={"success"}
          />
        );
      }
      case "modalResponseFailed": {
        return (
          <CModal 
            width="sm:w-[385px] w-[620px]" 
            height="h-[177px]" 
            show={modalResponseFailed} 
            title={t(msg)}
            primaryBtn={t("MANAGE_PASSENGERS.CREATE.ACCEPT_BTN")}
            close={handleModal}
            modalType={"passengerSuccessCreated"}
            callback={modalCallback}
            type={"failed"}
          />
        );
      }
      case "modalNewSchedule":
      case "modalSchedule":
      case "modalCreateTravelAgent": {
        return (
          <CModalSchedule 
            show={modalVisibility} 
            close={handleModal} 
            data={modalScheduleData} 
            openModal={handleModal}
            modalType={modalScheduleType} 
            editable={modalScheduleDataEditable}
            cancelable={cancelable}
            callback={modalCallback}
            callbackDelete={modalDeletrCallback}
            callbackRefresh={modalRefreshCallback}
          />
        );
      }
      case "success":
      case "passengerSuccessCreated": {
        return (
          <CModal 
            width="sm:w-[385px] w-[620px]" 
            height="h-[177px]" 
            show={successModal} 
            title={t("MANAGE_PASSENGERS.CREATE.SUCCESS_CREATED")}
            primaryBtn={t("MANAGE_PASSENGERS.CREATE.ACCEPT_BTN")}
            close={handleModal}
            modalType={"passengerSuccessCreated"}
            callback={modalCallback}
            type={"success"}
          />
        );
      }
      case "failed":
      case "passengerFailedCreated": {
        return (
          <CModal 
            width="sm:w-[385px] w-[620px]" 
            height="h-[177px]" 
            show={failedModal} 
            title={t("MANAGE_PASSENGERS.CREATE.FAILED_CREATED")}
            primaryBtn={t("MANAGE_PASSENGERS.CREATE.ACCEPT_BTN")}
            close={handleModal}
            modalType={"passengerFailedCreated"}
            type={"failed"}
          />
        );
      }
      case "passengerDelete": {
       return (
          <CModal 
            width="sm:w-[385px] w-[620px]" 
            height="sm:h-[290px] h-[238px]" 
            show={passengerDelete} 
            title={t("MANAGE_PASSENGERS.EDIT.TITLE_DELETE")}
            information={t("MANAGE_PASSENGERS.EDIT.INFO_DELETE")}
            primaryBtn={t("MANAGE_PASSENGERS.EDIT.DELETE")}
            secondaryBtn={t("MANAGE_PASSENGERS.EDIT.!DELETE")}
            close={handleModal}
            modalType={"passengerDelete"}
            callback={modalCallback}
            cancelCallback={modalCancelCallback}
          />
       );
      }
      case "cocliDelete": {
       return (
          <CModal 
            width="sm:w-[385px] w-[620px]" 
            height="sm:h-[290px] h-[238px]" 
            show={cocliDelete} 
            title={t("MANAGE_COCLIS.EDIT.TITLE_DELETE")}
            information={t("MANAGE_COCLIS.EDIT.INFO_DELETE")}
            primaryBtn={t("MANAGE_COCLIS.EDIT.DELETE")}
            secondaryBtn={t("MANAGE_COCLIS.EDIT.!DELETE")}
            close={handleModal}
            modalType={"passengerDelete"}
            callback={modalCallback}
            cancelCallback={modalCancelCallback}
          />
       );
      }
      case "taDelete": {
       return (
          <CModal 
            width="sm:w-[385px] w-[620px]" 
            height="sm:h-[290px] h-[238px]" 
            show={taDelete}
            title={t("MANAGE_TRAVEL_AGENTS.EDIT.TITLE_DELETE")}
            information={t("MANAGE_TRAVEL_AGENTS.EDIT.INFO_DELETE")}
            primaryBtn={t("MANAGE_TRAVEL_AGENTS.EDIT.DELETE")}
            secondaryBtn={t("MANAGE_TRAVEL_AGENTS.EDIT.!DELETE")}
            close={handleModal}
            modalType={"passengerDelete"}
            callback={modalCallback}
            cancelCallback={modalCancelCallback}
          />
       );
      }
      case "modalUploadExcel": {
       return (
          <CModal 
            width="md:w-[623px] w-[620px]" 
            height={deleted > 0 ? 
              `sm:w-[385px] md:w-[490px] sm:h-[500px] md:h-[419px] h-[419px]` : `sm:w-[385px] md:w-[490px] sm:h-[500px] md:h-[350px] h-[350px]`} 
            show={modalUploadExcel} 
            title={t("MANAGE_PASSENGERS.EXCEL.TITLE")}
            information={subtitle}
            dataInfotmation={data}
            moreInformation={modifications}
            warning={warning}
            deleted={deleted}
            primaryBtn={t("MANAGE_PASSENGERS.EXCEL.BTN_UPLOAD")}
            secondaryBtn={t("MANAGE_PASSENGERS.EXCEL.BTN_CANCEL")}
            close={handleModal}
            modalType={"passengerDelete"}
            callback={modalCallback}
            cancelCallback={modalCancelCallback}
          />
       );
      }
      case "modalRetryExport": {
       return (
          <CModal 
            width="sm:w-[385px] w-[620px]" 
            height="h-[216px]" 
            show={modalRetryExport} 
            title={t("MANAGE_PASSENGERS.CREATE.FAILED_CREATED")}
            subtitle={t("MODAL.RETRY.SUBTITLE")}
            primaryBtn={t("MODAL.RETRY.BUTTON")}
            close={handleModal}
            modalType={"passengerFailedCreated"}
            callback={modalCallback}
            type={"retry"}
          />
       );
      }
      case "modalResumeUpdates": {
       return (
          <CModal 
            width="sm:w-[385px] w-[620px]" 
            show={modalResumeUpdates} 
            title={t("MODAL.RECOVER.TITLE")}
            close={handleModal}
            modalType={"modalResumeUpdates"}
            recoveries={recoveries}
            callback={modalCallback}
            recovery
          />
       );
      }
      case "modalResumeUpdatesConfirm": {
       return (
          <CModal 
            width="sm:w-[385px] w-[620px]" 
            height="h-[272px]" 
            show={modalResumeUpdatesConfirm} 
            title={t("MODAL.RECOVER_CONFIRM.TITLE")}
            subtitle={t("MODAL.RECOVER_CONFIRM.SUBTITLE")}
            primaryBtn={t("MODAL.DELETE.ACCEPT_BTN")}
            secondaryBtn={t("MODAL.DELETE.CANCEL_BTN")}
            close={handleModal}
            modalType={"modalResumeUpdatesConfirm"}
            callback={modalCallback}
          />
       );
      }
      case "sessionModal": {
       return (
          <CModal 
            width="sm:w-[385px] w-[620px]" 
            height="sm:h-[216px] h-[200px]" 
            show={sessionModal} 
            title={t("MODAL.SESSION.TITLE")}
            primaryBtn={t("MODAL.SESSION.BTN_ACTIVE")}
            secondaryBtn={t("MODAL.SESSION.BTN_CANCEL")}
            close={handleModal}
            modalType={"sessionModal"}
            callback={modalCallback}
            cancelCallback={modalCancelCallback}
          />
       );
      }
      case "scheduleSuccess": {
        return (
          <CModal 
            show={scheduleSuccess} width="w-[620px]" height="h-[177px]" 
            title={t("MODAL.SUCCES.MODITY_SCHEDULE.TITLE")}
            primaryBtn={t("MODAL.ACCEPT_BTN.CREATE_SCHEDULE")}
            close={handleModal}
            modalType={"modalCreateTravelAgent"}
            type={"success"}
          />
        )
      }
      case "scheduleFailed": {
        return (
          <CModal 
            show={scheduleFailed} width="w-[620px]" height="h-[177px]" 
            title={t("MODAL.FAILED.CREATE_SCHEDULE.TITLE")}
            primaryBtn={t("MODAL.ACCEPT_BTN.CREATE_SCHEDULE")}
            close={handleModal}
            modalType={"modalCreateTravelAgent"}
            type={"failed"}
        />
        )
      }
      case "scheduleDelete": {
        return (
          <CModal 
            show={scheduleDelete} width="sm:w-[357px] w-[620px]" height="h-[238px]" 
            title={t("MODAL.DELETE.TITLE")}
            information={t("MODAL.DELETE.INFO")}
            primaryBtn={t("MODAL.DELETE.ACCEPT_BTN")}
            secondaryBtn={t("MODAL.DELETE.CANCEL_BTN")}
            close={handleModal}
            modalType={"modalSchedule"}
            callback={modalCallback}
            cancelCallback={modalCancelCallback} 
          />
        )
      }
      case "editModalAvatar": {
        return (
          <CModal 
            show={editModalAvatar} width="sm:w-[357px] w-[620px]" height="sm:h-[343px] h-[313px]" 
            title={t("MODAL.AVATAR.TITLE")}
            primaryBtn={t("MODAL.AVATAR.ACCEPT_BTN")}
            secondaryBtn={t("MODAL.AVATAR.CANCEL_BTN")}
            close={handleModal}
            type={modal}
            callback={modalCallback}
            cancelCallback={modalCancelCallback} 
          />
        )
      }
      case "selectCocli": {
        return (
          <CModalTable 
            show={selectCocli} width="sm:w-[357px] w-[720px]" height="sm:h-[343px] h-[755px]" 
            data={data}
            title={t("MODAL.SELECT_COCLI.TITLE")}
            primaryBtn={t("MODAL.SELECT_COCLI.ACCEPT_BTN")}
            secondaryBtn={t("MODAL.SELECT_COCLI.CANCEL_BTN")}
            close={handleModal}
            type={modal}
            openModal={handleModal}
            callback={modalCallback}
            cancelCallback={modalCancelCallback} 
          />
        )
      }
    }
  }

  const handleBack = (value: any, callback: any) => {
    setBackCallback( () => callback );
    setShowBack(value);
  }

  const routes = (menu: any) => {
    const user = instanceUser.getUser();
    switch(menu) { 
      case 1: { 
        if(user.rol === "ROL.SUPER_ADMIN.NAME"){
          return  <VDashboard openModal={handleModal} values={instanceUser.getUser()} />;
        }else {
          return  <VDashboardTA openModal={handleModal} back={handleBack} />;
        }
      } 
      case 2: { 
        if(user.rol === "ROL.SUPER_ADMIN.NAME"){
          return <VCreateTravelAgent openModal={handleModal} />;
        }else {
          return <VManagePassengersCreate openModal={handleModal} org={user.organizations[0].id}/>;
        }
      } 
      case 3: {
        if(user.rol === "ROL.SUPER_ADMIN.NAME"){
          return <VManagePassengers openModal={handleModal} back={handleBack} />;
        }else {
          return;
        }
      } 
      case 4: {
        if(user.rol === "ROL.SUPER_ADMIN.NAME"){
          return <VScheduleExports openModal={handleModal} back={handleBack} />;
        }else {
          return <VDataReport openModal={handleModal} />;
        }
      } 
      case 5: {
        if(user.rol === "ROL.SUPER_ADMIN.NAME"){
          return <VManageTravelAgentList openModal={handleModal} back={handleBack} />;
        }else {
          return;
        }
      } 
      case 6: {
        if(user.rol === "ROL.SUPER_ADMIN.NAME"){
          return <VManageCoclisList openModal={handleModal} back={handleBack} />;
        }else {
          return;
        }
      } 
      default: { 
        return <V404/>;
      } 
   } 
  }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLogout = async (param: string) => {
    dispatch(selectDropdownItem(true));
    await localStorage.clear();
    return navigate(param);
  }

  let tim: any = null;
  let tmpTim: any = null;

  const timerToken = () => {
    let interval = setInterval(() => {
      Auth.doRefresh()
      .then(() => {
      })
      .catch((err: any) => {
        handleLogout("/");
        clearInterval(interval);
      })
    }, rToken)
  }

  const timerSession = () => {
    clearInterval(tim);
    clearTimeout(tmpTim);

    tim = setInterval(() => {
      dispatch(selectDropdownItem(false));
      handleModal(true, "sessionModal", null, false, 
      () => {
        Auth.doRefresh().then(resp => {
          clearTimeout(tmpTim);
          dispatch(selectDropdownItem(true));
          handleModal(false, "sessionModal", null, false, null, null, false, null, null, null);
        }).catch(err => {
          dispatch(selectDropdownItem(true));
          handleModal(false, "sessionModal", null, false, null, null, false, null, null, null);
          handleLogout("/");
        });
      }, 
      () => {
        handleLogout("/");
      }, false, null, null, null);
      clearTimeout(tmpTim);
      tmpTim = setTimeout(() => {
        handleLogout("/");
      }, tmpTimer);
    }, timer);
  }

  const callAvatar = () => {
    Profile.getAvatar(navigate)
    .then((resp: any) => {
      setAvatar(resp);
    }).catch((err: any) => {
      console.log(err);
    });
  }

  useEffect(() => {
    callAvatar();
    if(!instanceUser.getUser()){
      const user = JSON.parse(localStorage.getItem("user")  || '');
      instanceUser.setUser(user);
    }else{
      setLoading(false);
      timerSession();
      document.addEventListener('click', () => {timerSession()});
      timerToken();
    }
    dispatch(selectMenuItem(props.menuId));
  }, []);

  return (
    <div>
      <div className={Styles.global_layout}>
        {modalToShow(modal)}   
        <CSideMenu values={instanceUser.getUser()} menuId={props.menuId} back={handleBack}/>
        <div className={Styles.global_layout_section}>
          <CHeader values={instanceUser.getUser()} back={showBack} callback={backCallback} openModal={handleModal} avatar={avatar}/>
          <div>
            {routes(props.menuId)}
          </div>
        </div>
      </div>
    </div>
  );
}