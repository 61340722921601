import Styles from './v-error-404.module.css';
import Plane from './../../../assets/illustration-plane.svg';
import { t } from 'i18next';
import { CButton } from '../../../components/c-button/c-button';
import { useNavigate } from 'react-router-dom';

export default function V404() {
    const navigate = useNavigate();

    return (
        <div className={Styles.v_error_404_container}>
            <img src={Plane} alt="404"/>
            <div className={Styles.v_error_404_title_container}>
                <label className={Styles.v_error_404_title}>404</label>
                <label>{t('ERROR.404')}</label>
                <CButton 
                    placeholder={t('ERROR.BTN.RELOAD')} 
                    type="secondary" 
                    width={160}
                    action={() => {
                        localStorage.clear();
                        return(navigate('/'))
                    }}
                />
            </div>
        </div>
    )
}