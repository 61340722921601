import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Styles from "./v-manage-passenger-list.module.css";
import editIcon from "../../../assets/icon-edit.svg";
import arrowIcon from "../../../assets/icon-arrow.svg";
import sortIcon from "../../../assets/icon-order.svg";
import closeIcon from "../../../assets/icon-close.svg";
import addIcon from "../../../assets/icon-add.svg"
import uploadIcon from "../../../assets/icon-upload.svg"
import downloadIcon from "../../../assets/icon-download.svg"
import eyeIcon from "../../../assets/icon-eye.svg"
import checkIcon from "../../../assets/icon-check.svg"
import loaderIcon from "../../../assets/icon-loader.svg"
import alertIcon from "../../../assets/icon-alert.svg"
import infoIcon from "../../../assets/icon-info.svg";

import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import { Box, TablePagination, TableSortLabel } from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import CTextInput from "../../../components/c-text-input/c-text-input";
import CDropDown from "../../../components/c-dropdown/c-dropdown";
import { CButton } from "../../../components/c-button/c-button";

import VManagePassengersCreate from "../v-manage-passengers-create/v-manage-passengers-create";
import VManagePassengersEdit from "../v-manage-passengers-edit/v-manage-passengers-edit";

import Passengers from "../../../services/passengers/s-passengers";
import Fields from "../../../services/travel-agent/s-fields";
import Exports from "../../../services/exports/s-exports";
import { useNavigate } from "react-router-dom";

type Order = 'asc' | 'desc';

interface passengerFilter {
  persDni?: string,
  email?: string, 
  name?: string, 
  firstLastname?: string, 
  rol?: string,
  travelAgent?: any,
  status?: any;
  pageSize?: number, 
  page?: number
}
interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: any;
  rowCount: number;
}
interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  disabledSort: boolean;
}

export default function VManagePassengersList(props: any) {  
  const { t } = useTranslation();
  const navigate = useNavigate()

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [counts, setCounts] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [openFilterTravelAgent, setOpenFilterTravelAgent] = useState(false);
  const [openFilterStatus, setOpenFilterStatus] = useState(false);
  const [rows, setRows] = useState<any>([]);
  const [allRows, setAllRows] = useState<any>([]);
  const [uniqueTravelAgents, setUniqueTravelAgents] = useState<any>([]);
  const [travelAgent, setTravelAgent] = useState<any>(false);
  const [loading, setLoading] = useState(true);
  const [newView, setNewView] = useState(false);
  const [actualView, setActualView] = useState(true);
  const [editView, setEditView] = useState(false);
  const [fields, setFields] = useState({});
  const [editData, setEditData] = useState<any>({});
  const [stauses, setStauses] = useState<any>({});
  const [passengerId, setPassengerId] = useState();
  const [uploading, setUploading] = useState(false);
  const [uploadedSucess, setUploadedSuccess] = useState(false);
  const [uploadedFailed, setUploadedFailed] = useState(false);

  const [arrangerName, setArrangerName] = useState("");
  const [arrangerSurname, setArrangerSurname] = useState("");
  const [arrangerEmail, setArrangerEmail] = useState("");
  const [arrangerEmployee, setArrangerEmployee] = useState("");
  const [arrangerRol, setArrangerRol] = useState("");
  const [arrangerModify, setArrangerModify] = useState("");
  const [statusFilter, setStatusFilter] = useState<any>([]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      height: "10px", 
    },
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#f6f6f8',
      color: '#6c6c6c',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
    borderBottom: "none !important",
    fontFamily: 'Poppins, sans-serif',
    letterSpacing: 0.2,
    color: '#1b1b1e',
    height: "5px"
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({

    [`&.${tableRowClasses.root}`]: {
      height: "16px",
    },
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    backgroundColor: "white !important",
    fontFamily: 'Poppins, sans-serif'
  }));

  const handleChange = (value: any, type: string) => {
    filter({status: value.id});
  };

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const selectedFiles: any = files as FileList;
    uploadExcel(selectedFiles[0]);
  };

  const filter = (passenger: passengerFilter) => {
    let email = arrangerEmail;
    let persDni = arrangerEmployee;
    let name = arrangerName;
    let firstLastname = arrangerSurname;
    let rol = arrangerRol;
    let ta = travelAgent;
    let status = statusFilter;
   
    if (passenger && (passenger.persDni || passenger.persDni === '')){
      persDni = passenger.persDni;
      setArrangerEmployee(persDni)
    }
    if (passenger && (passenger.email || passenger.email === '')){
      email = passenger.email;
      setArrangerEmail(email);
    }
    if (passenger && (passenger.name || passenger.name === '')){
      name = passenger.name;
      setArrangerName(name);
    }
    if (passenger && (passenger.firstLastname || passenger.firstLastname === '')){
      firstLastname = passenger.firstLastname;
      setArrangerSurname(firstLastname);
    }
    if (passenger && (passenger.rol || passenger.rol === '')){
      rol = passenger.rol;
      setArrangerRol(rol);
    }
    if (passenger && (passenger.travelAgent || passenger.travelAgent === '')){
      ta = passenger.travelAgent;
      setTravelAgent(ta);
    }
    if (passenger && passenger.status >= 0){
      let tmpStatus = stauses;
      if(!status.includes(passenger.status)){
        status.push(passenger.status);
        tmpStatus = tmpStatus.map((s: any) => {
          let tmp = s;
          if(tmp.id === passenger.status){
            tmp.selected = true;
          }
          return tmp;
        })
      }else {
        status = status.filter((s: any) => s !== passenger.status);
        tmpStatus = tmpStatus.map((s: any) => {
          let tmp = s;
          if(tmp.id === passenger.status){
            tmp.selected = false;
          }
          return tmp;
        })
      }
      setStauses(tmpStatus);
      setStatusFilter(status);
    }

    Passengers.getPassengersByTravelAgent(
      ta.id,
      {
        email, 
        persDni, 
        name, 
        firstLastname, 
        rol,
        status
      },
      navigate
    )
    .then((req: any) => {
      const {data} = req;
      setRows(data.data);
      setPage(data.pageNumber);
      setCounts(data.totalElements);
      setRowsPerPage(data.pageSize);
      setLoading(false);
    })
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: `${t('MANAGE_PASSENGERS.LIST.COL_NAME')}`,
      disabledSort: true,
    },
    {
      id: 'date',
      numeric: true,
      disablePadding: false,
      label:`${t('MANAGE_PASSENGERS.LIST.COL_SURNAME')}`,
      disabledSort: true,
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      label: `${t('MANAGE_PASSENGERS.LIST.COL_EMAIL')}`,
      disabledSort: true,
    },
    {
      id: 'employee',
      numeric: true,
      disablePadding: false,
      label: `${t('MANAGE_PASSENGERS.LIST.COL_EMPLOYEE')}`,
      disabledSort: true,
    },
    {
      id: 'rol',
      numeric: true,
      disablePadding: false,
      label: `${t('MANAGE_PASSENGERS.LIST.COL_ROL')}`,
      disabledSort: true,
    },
    {
      id: 'cocli',
      numeric: true,
      disablePadding: false,
      label: `${t('MANAGE_PASSENGERS.LIST.COL_COCLI')}`,
      disabledSort: true,
    },
    {
      id: 'cocli_habitual',
      numeric: true,
      disablePadding: false,
      label: `${t('MANAGE_PASSENGERS.LIST.COL_COCLI_HABITUAL')}`,
      disabledSort: true,
    },
    {
      id: 'profile_status',
      numeric: true,
      disablePadding: false,
      label: `${t('MANAGE_PASSENGERS.LIST.COL_PROFILE')}`,
      disabledSort: false,
    },
  ];

  const defineIcon = (value: any) => {
    if(value === t('DASHBOARD.DATE.TITLE')) {
      return sortIcon;
    }else {
      return arrowIcon;
    }
  }

  const actualV = (value: any) => {
    if(newView){
      setNewView(value);
    }else if(editView){
      setEditView(value);
    }
    filter({});
  }

  const handleNewPassenger = () => {
    Fields.getFields(travelAgent.id, navigate)
    .then((resp: any) => {
      setFields(resp.data.selection);
      props.back(true, () => {
        setNewView(false);
        setActualView(true);
        props.back(false);
      });
      setActualView(false);
      setNewView(true);
    })
    .catch(err => {
      console.log(err);
    });
  }

  const handleEditPassenger = async (value: any) => {
    setPassengerId(value);
    Fields.getFields(travelAgent.id, navigate)
    .then((resp1: any) => {
      Passengers.detailPassenger(value, travelAgent.id, navigate).then((resp2: any) => {
          let fields = resp1.data.selection;
          let editPassenger = resp2.data;
          fields.forEach((f: any) => {
            if(f.content && f.content.length > 0){
              if(!f.multiselect){
                let filter = f.content.find((c: any) => c.name === editPassenger[f.field.name])
                editData[f.field.name] = filter || {};
              }else{
                editData[f.field.name] = editPassenger[f.field.name] || '';
              }
            }else {
              editData[f.field.name] = editPassenger[f.field.name] || '';
            }
          })
          setFields(fields);
          props.back(true, () => {
            setEditView(false);
            setActualView(true);
            props.back(false);
          });

        setActualView(false);
        setEditView(true);
      }).catch(err => {
        console.log(err);
      });
    })
    .catch(err => {
      console.log(err);
    });
  }

  const generateExcelTemplate = () => {
    Exports.generateExcelTemplate(travelAgent.id, navigate).catch(err => {
      console.log(err)
    })
  }
  
  const generateExcelFullDB = () => {
    Exports.generateExcelFullDB(travelAgent.id, navigate).catch(err => {
      console.log(err)
    })
  }

  const generateResumeUpdates = () => {
    let confirmModal = false;
    Exports.generateRecoveryUpdates(travelAgent.id, navigate).then((resp: any) => {
      if(resp.data && resp.data.length > 0){
        props.openModal(true, "modalResumeUpdates", resp.data, null, () => {
          confirmModal = true;
          if(confirmModal){
            props.openModal(false, "modalResumeUpdates");
            props.openModal(true, "modalResumeUpdatesConfirm", null, null, () => {
              Exports.recoveryUpdates(travelAgent.id, navigate).then(resp => {
                props.openModal(false, "modalResumeUpdatesConfirm");
                filter({});
              })
              .catch((err: any) => {
                props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
                
              })
            })
          }
        });
      }else{
        props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, "RESPONSE.ERROR");
      }
    }).catch((err: any) => {
      props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
    })
  }

  const uploadExcel = (file: any) => {
    Exports.uploadExcel(travelAgent.id, file, navigate)
    .then((resp: any) => {
      let data;
      if(resp.data.failed !== 0){
        data = [
          {
            id: 1,
            data: resp.data.noChanged,
          },
          {
            id: 2,
            data: resp.data.modified,
          },
          {
            id: 3,
            data: resp.data.created,
          },
          {
            id: 4,
            data: resp.data.failed,
          },
        ];
      }else {
        data = [
          {
            id: 1,
            data: resp.data.noChanged,
          },
          {
            id: 2,
            data: resp.data.modified,
          },
          {
            id: 3,
            data: resp.data.created,
          }
        ]
      }
      let subtitle = [
        {
          id: 1,
          text: t("MANAGE_PASSENGERS.EXCEL.SUBTITLE_1"),
        },
        {
          id: 2,
          text: resp.data.fileTotal,
        },
        {
          id: 3,
          text: t("MANAGE_PASSENGERS.EXCEL.SUBTITLE_2"),
        }
      ];
      let warning = [
        {
          id: 1,
          text: t("MANAGE_PASSENGERS.EXCEL.WARNING_1"),
        },
        {
          id: 2,
          text: t("MANAGE_PASSENGERS.EXCEL.WARNING_2"),
        },
        {
          id: 3,
          text: t("MANAGE_PASSENGERS.EXCEL.WARNING_3"),
          total: resp.data.deleted,
        }
      ];
      let deleted = resp.data.deleted;
      setUploadedSuccess(false);
      setUploadedFailed(false);
      setUploading(true);
      props.openModal(true, "modalUploadExcel", {data, subtitle, warning, deleted}, null, 
        () => {
          setUploading(false);
          setUploadedSuccess(true);
          setTimeout(() => {
            setUploadedSuccess(false);
          }, 5000)
          Exports.validateExcel(travelAgent.id, resp.data.id, true, navigate)
          .then((resp: any) => {
            setTimeout(() => {
              filter({});
            }, 1000)
            props.openModal(false, "modalUploadExcel")
          })
          .catch((err: any) => {
            props.openModal(false, "modalUploadExcel")
              props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
          });
        }, 
        () => {
          setUploading(false);
          setUploadedSuccess(false);
          setUploadedFailed(false);
          Exports.validateExcel(travelAgent.id, resp.data.id, false, navigate).catch((err: any) => {
              props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
          });
          props.openModal(false, "modalUploadExcel")
        }
      );
    })
    .catch((err: any) => {
      props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
  });
  }

  const filters = () => {
    const checkIfClickedOutside = (e: any) => {
      const element = e.target;
      const id = element.id.includes("statusF");
      const idIcon = element.id.includes("profile_status");
      if(element.textContent !== t("MANAGE_PASSENGERS.LIST.COL_PROFILE") 
        && !id && !idIcon
        && element.textContent !== t('DASHBOARD.SUCCESS')
        && element.textContent !== t('DASHBOARD.FAIL')
        && element.textContent !== t('DASHBOARD.WITHERRORS') 
      ){
        setOpenFilterStatus(false);
      }
    }
    if(openFilterStatus){
      document.removeEventListener('click', checkIfClickedOutside);  
    }else {
      document.addEventListener('click', checkIfClickedOutside);
    }
  }

  const statuses_array = [
    {
      id: 1,
      name: t("DASHBOARD.SUCCESS"),
    },
    {
      id: 0,
      name: t("DASHBOARD.FAIL"),
    },
    {
      id: 2,
      name: t("DASHBOARD.WITHERRORS"),
    }
  ]
  
  useEffect(() => {
    setTimeout(() => {
      setUploadedSuccess(false);
      setUploadedFailed(false);
    }, 10000); 

    if(loading){
      let status = statuses_array.map((s: any) => s.id);
      let tmpStatus = statuses_array.map((s: any) => {
        s.selected = true;
        return s;
      });

      setStauses(tmpStatus);
      setStatusFilter(status);
      setRows(props.data.data);
      setPage(props.data.pageNumber);
      setRowsPerPage(props.data.pageSize);
      setCounts(props.data.totalElements);
      setAllRows(props.data.data);
      setTravelAgent(props.travelAgentSelected);
      setLoading(false);
    }
    filters();
  }, [loading, props.data, props.travelAgentSelected])

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy } =
      props;
    const createSortHandler =
      (property: string) => (event: React.MouseEvent<unknown>) => {
          setOpenFilterStatus(!openFilterStatus);
      };
   
    return (
        <TableHead>
            <TableRow>
            {headCells.map((headCell) => (
                <StyledTableCell
                  key={headCell.id}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                {!headCell.disabledSort ?
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                      IconComponent={() => 
                        <span 
                          id={headCell.id}
                          onClick={() => {
                            createSortHandler(headCell.id)
                          }}
                        >
                          <img id={headCell.id} src={defineIcon(headCell.label)} alt="" style={{marginLeft: '12px', width: '16px', height: '16px'}}/>
                        </span>
                      }>
                        {headCell.label}
                        {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                        ) : null}
                    </TableSortLabel> :
                    <TableSortLabel hideSortIcon style={{ cursor: 'unset' }}>
                      {headCell.label}
                    </TableSortLabel> 
                }
                </StyledTableCell>
            ))}
            <StyledTableCell align="left">&nbsp;</StyledTableCell>
            </TableRow>
        </TableHead>
    );
  }

  const transformStatus = (row: any) => {
    if(row.complete === 0){
      return (
        <div className={Styles.v_manasge_passenger_list_tablet_item_e}>
          <p> 
            <span>
              <img src={infoIcon} alt="" className={Styles.v_manasge_passenger_item_e}/>
            </span>
            {t("DASHBOARD.INCOMPLETE")} 
          </p>
        </div>
      )
    } else if(row.complete === 1){
      return (
        <div className={Styles.v_manasge_passenger_list_tablet_item_f}>
          <p>{t("DASHBOARD.COMPLETE")}</p>
        </div>
      ) 
    } else if(row.complete === 2){
      return (
        <div className={Styles.v_manasge_passenger_list_tablet_item_e}>
          <p>{t("DASHBOARD.WITHERRORS")}</p>
        </div>
      )
    }
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    Passengers.getPassengersByTravelAgent(
      travelAgent.id,
      {
        email: arrangerEmail, 
        persDni: arrangerEmployee, 
        name: arrangerName, 
        firstLastname: arrangerSurname, 
        rol: arrangerRol, 
        page: newPage,
        pageSize: rowsPerPage
      },
      navigate
    )
    .then((req: any) => {
      const {data} = req;
      setRows(data.data);
      setCounts(data.totalElements);
      setRowsPerPage(data.pageSize);
      setLoading(false);
    })
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

    return (
      <div>
        {uploading &&
          <div id="side-in" className={Styles.v_manage_passengers_list_loading}>
            <div className={Styles.v_manage_passengers_list_group_response}>
              <img src={loaderIcon} alt="loading" className={Styles.v_manage_passengers_list_icon_rotating}/>
              <label className={Styles.v_manage_passengers_edited}>
                {t("MANAGE_PASSENGERS.EXCEL.UPLOADING_RESPONSE")}
              </label>
            </div>
          </div>
        }
         {uploadedSucess &&
          <div id="side-in" className={Styles.v_manage_passengers_list_uploaded}>
            <div className={Styles.v_manage_passengers_list_group_response}>
              <img src={checkIcon} alt="uploaded"/>
              <label className={Styles.v_manage_passengers_edited}>
              {t("MANAGE_PASSENGERS.EXCEL.UPLOADED_RESPONSE")}
              </label>
            </div>
          </div>
          }
        {uploadedFailed && 
          <div id="side-in" className={Styles.v_manage_passengers_list_notuploaded}>
            <div className={Styles.v_manage_passengers_list_group_response}>
              <img src={alertIcon} alt="notuploaded"/>
              <label className={Styles.v_manage_passengers_edited}>
              {t("MANAGE_PASSENGERS.EXCEL.NOT_UPLOADED_RESPONSE")}
              </label>
           </div>
         </div>
        }
      
        {newView && !loading ?
          <VManagePassengersCreate 
            back={props.back} 
            openModal={props.openModal} 
            data={fields} 
            org={travelAgent.id} 
            from={actualV}
          /> :
          (editView ?
            <VManagePassengersEdit
              back={props.back} 
              openModal={props.openModal} 
              data={fields} 
              passengerId={passengerId}
              org={travelAgent.id}
              edit={editData}
              from={actualV}
            /> 
            :
            (!loading &&
            <div className={Styles.v_manage_passenger_list}>
              <div className={Styles.v_manage_passenger_list_search_group}>
                  <div className={Styles.v_manage_passenger_list_search_agent}>
                      <label>{t("MANAGE_PASSENGERS.LIST.SEARCH_4_TAgent")}</label>
                      <CDropDown 
                        placeholder={t("MANAGE_PASSENGERS.PLACEHOLDER_DROPDOWN")} 
                        secondaryPlaceholder={t("MANAGE_PASSENGERS.PLACEHOLDER_SEARCH")}
                        titleButton={t("MANAGE_PASSENGERS.FINDER_BUTTON")}
                        finder
                        className="w-[100%] sm:w-[326px]"
                        options={props.travelAgents} 
                        selected={travelAgent} 
                        setSelected={(value: any) => {setTravelAgent(value)}}
                        action={(value: any) => {filter({travelAgent: value})}}
                      />
                  </div>  
                  <div className={Styles.v_manage_passenger_list_search_arragner}>
                      <label>{t("MANAGE_PASSENGERS.LIST.SEARCH_4_TArragner")}</label>
                      <div className={Styles.v_manage_passenger_list_search_arragner_grid}>
                        <CTextInput 
                          id="arranger-name"
                          type="text"
                          placeholder={t("MANAGE_PASSENGERS.LIST.COL_NAME")}  
                          value={arrangerName}
                          setValue={(value: any) => {
                            filter({name: value || ''});
                          }}
                          />
                        <CTextInput 
                          id="arranger-surname"
                          type="text"
                          placeholder={t("MANAGE_PASSENGERS.LIST.COL_SURNAME")}
                          value={arrangerSurname}
                          setValue={(value: any) => {
                            filter({firstLastname: value || ''})
                          }}
                        />
                        <CTextInput 
                            id="arranger-email"
                            type="text"
                            placeholder={t("MANAGE_PASSENGERS.LIST.COL_EMAIL")}
                            value={arrangerEmail}
                            setValue={(value: any) => {
                              filter({email: value || ''})
                            }}
                          />
                      </div>
                      <div className={Styles.v_manage_passenger_list_search_arragner_grid}>
                        <CTextInput 
                            id="arranger-employee"
                            type="text"
                            placeholder={t("MANAGE_PASSENGERS.LIST.COL_EMPLOYEE")}
                            value={arrangerEmployee}
                            setValue={(value: any) => {
                              filter({persDni: value || ''})
                            }}                     
                          />
                        <CTextInput 
                            id="arranger-rol"
                            type="text"
                            placeholder={t("MANAGE_PASSENGERS.LIST.COL_ROL")}
                            value={arrangerRol}
                            setValue={(value: any) => {
                              filter({rol: value || ''})
                            }}
                        />
                        <CTextInput 
                          id="arranger-modify"
                          type="text"
                          placeholder={t("MANAGE_PASSENGERS.LIST.COL_LASTMODIFY")}
                          value={arrangerModify}
                          setValue={setArrangerModify}
                        />
                        <div className={Styles.v_manage_passenger_list_search_arragner_search_button}>
                          {arrangerName || arrangerSurname || 
                            arrangerEmail || arrangerEmployee || 
                            arrangerRol || arrangerModify ? 
                            <CButton placeholder={t("MANAGE_PASSENGERS.LIST.BTN_SEARCH")} action={filter}/>
                          :
                            <CButton placeholder={t("MANAGE_PASSENGERS.LIST.BTN_SEARCH")} type="disabled" /> 
                          } 
                        </div>
                      </div>
                  </div>
              </div>
              <div className={Styles.v_manasge_passenger_list_header}>
                <div className={Styles.v_manasge_passenger_list_header_group}>
                  <p className={Styles.v_manasge_passenger_list_header_title}>{t("MANAGE_PASSENGERS.SUBTITLE")}</p>
                  <CButton 
                    placeholder={t("MANAGE_PASSENGERS.LIST.BTN_NEW_PASSENGER")} 
                    type="secondary"
                    height={36} 
                    icon={addIcon} 
                    action={handleNewPassenger}
                  />
                </div>
                <div className={Styles.v_manasge_passenger_list_table}>
                  <TableContainer>
                    {openFilterTravelAgent &&
                      <div className={Styles.v_manasge_passenger_list_filter_container_t}>
                        <header className={Styles.v_manasge_passenger_list_filter_close}>
                          <label>Find</label>
                          <button onClick={() => setOpenFilterTravelAgent(false)}>
                            <img src={closeIcon} alt="close" />
                          </button>
                        </header>
                          <div className={Styles.item}>
                          {uniqueTravelAgents.map((row: any, i: number) => (
                            <FormControlLabel
                            key={row.id}
                            label={row.name}
                              control={
                                <Checkbox
                                  checked={row.selected}
                                  onClick={() => {handleChange(row, "travel_agent")}}
                                  sx={{
                                    color: '#979797',
                                    '&.Mui-checked': {
                                      color: '#017ef3',
                                    },
                                  }}
                                />
                              }
                            />                 
                          ))}  
                        </div>
                      </div>
                    }
                    {openFilterStatus &&
                      <div className={Styles.v_manasge_passenger_list_filter_container_e} id="statusFilter">
                        <header id="statusF1" className={Styles.v_manasge_passenger_list_filter_close}>
                          <label id="statusF2">{t('DASHBOARD.FILTER')}</label>
                          <button id="statusF3" onClick={() => setOpenFilterStatus(false)}>
                            <img src={closeIcon} alt="close" />
                          </button>
                        </header>
                          <div id="statusF4" className={Styles.item}>
                          {stauses.map((row: any) => (
                            <FormControlLabel
                              id="statusF5"
                              key={row.id}
                              label={row.name}
                              control={
                                <Checkbox
                                  id="statusF6"
                                  name={row.name}
                                  checked={row.selected}
                                  onClick={() => {
                                    handleChange(row, "platRoles");
                                  }}                              
                                  sx={{
                                      color: '#979797',
                                      '&.Mui-checked': {
                                        color: '#017ef3',
                                      },
                                  }}
                                />
                              }
                            />                 
                          ))}  
                        </div>
                      </div>
                    }
                    <Box sx={{ overflow: "auto" }}>
                      <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                      <TableContainer sx={{ width: "100%", maxHeight: 440 }}>
                        <Table stickyHeader aria-label="customized table" >
                          <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                          />
                          {!uploading &&
                            <TableBody>
                                {rows.map((row: any) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                                    <StyledTableCell align="left">{row.firstLastname}</StyledTableCell>
                                    <StyledTableCell align="left">{row.email}</StyledTableCell>
                                    <StyledTableCell align="left">{row.persDni}</StyledTableCell>
                                    <StyledTableCell align="left">{row.platRoles}</StyledTableCell>                
                                    <StyledTableCell align="left">{row.cocli}</StyledTableCell>                
                                    <StyledTableCell align="left" width={200}>
                                      {
                                        row.cocliHabitual ? (row.cocliHabitual.length > 20 ? row.cocliHabitual.substring(0,20).concat('...') : row.cocliHabitual) : ''
                                      }
                                    </StyledTableCell>                
                                    <StyledTableCell align="left"> 
                                      {transformStatus(row)}
                                    </StyledTableCell>  
                                    <StyledTableCell align="left">
                                      <button style={{width: 20, height: 20}} 
                                        onClick={() => {handleEditPassenger(row.id)}}
                                      >
                                          <img src={editIcon} alt="" />
                                      </button> 
                                    </StyledTableCell>
                                </StyledTableRow>
                                ))}
                            </TableBody>
                          }
                        </Table>
                        {uploading &&
                        <div>
                          <div className={Styles.v_manasge_passenger_list_file}>
                          </div>
                          <div className={Styles.v_manasge_passenger_list_file}>
                          </div>
                          <div className={Styles.v_manasge_passenger_list_file}>
                          </div>
                          <div className={Styles.v_manasge_passenger_list_file}>
                          </div>
                          <div className={Styles.v_manasge_passenger_list_file}>
                          </div>
                          <div className={Styles.v_manasge_passenger_list_file}>
                          </div>
                          <div className={Styles.v_manasge_passenger_list_file}>
                          </div>
                          <div className={Styles.v_manasge_passenger_list_file}>
                          </div>
                        </div>
                        
                        }
                      </TableContainer>
                      </Box>
                    </Box>
                  </TableContainer>   
                  <TablePagination
                    rowsPerPageOptions={[20]}
                    component="div"
                    count={counts}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  /> 
                </div>
                
                <div className={Styles.v_manage_passenger_list_download_group}>
                  <CButton 
                    placeholder={t("MANAGE_PASSENGERS.LIST.BTN_DOWNLOAD_DB")} 
                    type="secondary" 
                    height={36} 
                    icon={downloadIcon}
                    action={() => {generateExcelFullDB()}}
                  />
                  <CButton 
                    placeholder={t("MANAGE_PASSENGERS.LIST.BTN_UPLOAD_EXCEL")} 
                    type="file" 
                    height={36} 
                    icon={uploadIcon}
                    action={(event: any) => {
                      selectFile(event);
                    }}
                  />
                  <CButton 
                    placeholder={t("MANAGE_PASSENGERS.LIST.BTN_CHECK_LAST_UPLOAD")} 
                    type="secondary" 
                    height={36} 
                    icon={eyeIcon}
                    action={() => {generateResumeUpdates()}}
                  />
                </div>
            </div>
            </div>
            )
          )
        } 
      </div>

  );
}