export interface PassengerData {
    stdIdHr?: string;
    cocli?: any;
    cocli_habitual?: any;
    plat_roles?: string;
    plat_categoria?: string;
    plat_autorizadores?: string;
    notificadores?: string;
    prof_nombre?: string;
    prof_apellido1?: string;
    prof_apellido2?: string;
    prof_emailTrabajo?: string;
    prof_emailNotificaciones?: string;
    prof_telefonoTrabajo?: string;
    prof_movilTrabajo?: string;
    pers_dni?: string;
    numero_empleado?: string;
    idioma?: string;
    Greeting?: string;
    Gender?: string;
    DOB?: string;
    REFFA?: string;
    REFFB?: string;
    REFFC?: string;
    REFFD?: string;
    REFFE?: string;
    REFFF?: string;
    REFFG?: string;
    lock?: boolean;
}

export class PassengerModel implements PassengerData{
    stdIdHr?: string = '';
    cocli?: any = {};
    cocli_habitual?: any = {};
    plat_roles?: string = '';
    plat_categoria?: string = '';
    plat_autorizadores?: string = '';
    notificadores?: string = '';
    prof_nombre?: string = '';
    prof_apellido1?: string = '';
    prof_apellido2?: string = '';
    prof_emailTrabajo?: string = '';
    prof_emailNotificaciones?: string = '';
    prof_telefonoTrabajo?: string = '';
    prof_movilTrabajo?: string = '';
    pers_dni?: string = '';
    numero_empleado?: string = '';
    idioma?: string = '';
    Greeting?: string = '';
    Gender?: string = '';
    DOB?: string = '';
    REFFA?: string = '';
    REFFB?: string = '';
    REFFC?: string = '';
    REFFD?: string = '';
    REFFE?: string = '';
    REFFF?: string = '';
    REFFG?: string = '';
    lock?: boolean;
}