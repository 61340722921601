import { useEffect, useState } from "react";

import Styles from "./c-modal-schedule.module.css";
import iconClose from "../../assets/icon-close.svg";
import iconDelete from "../../assets/icon-delete.svg";
import iconPause from "../../assets/icon-pause.svg";

import CDropDown from "../c-dropdown/c-dropdown";
import CModal from "../c-modal/c-modal";
import CTextInput from "../c-text-input/c-text-input";
import { CButton } from "../c-button/c-button";

import { t } from "i18next";
import Frequencies from "../../services/schedule/s-frequencies";
import TravelAgent from "../../services/travel-agent/s-travel-agent";
import Schedule from "../../services/schedule/s-schedule";
import { useNavigate } from "react-router-dom";

export default function CModalSchedule(props: any) {
  const navigate = useNavigate()

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalResult, setShowModalResult] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState<any>(false);
  const [showModalFailed, setShowModalFailed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [enableSaveButton1, setEnableSaveButton1] = useState(false);
  const [enableSaveButton2, setEnableSaveButton2] = useState(false);
  const [selectedData, setSelectedData] = useState(false);
 
  const [frecuencies, setFrecuencies] = useState(false);
  const [times, setTimes] = useState(false);
  const [travelAgentsWOrg, setTravelAgentsWOrg] = useState(false);

  const handleClose = () => {
    setShowModalSuccess(false);
    setShowModalFailed(false);
    setShowModalResult(false);
    props.openModal(false, props.modalType);
    if(props.callbackRefresh){
      props.callbackRefresh();
    }
  }

  const handleDeleteButton = async () => {
    await setShowModalResult(true);
    await setShowModalDelete(true);
  }

  const handleActions = (del: boolean, active: boolean) => {
    let act;
    if(active){
      act = !props.data.active;
    }
    Schedule.updateSchedule(props.data, del, act, navigate).then((resp: any) => {
      props.openModal(false, "modalSchedule", null,null,null,null,null,null,null, props.callbackRefresh);
        setShowModalSuccess(resp.result.info);
    }).catch(() => {
      setShowModalFailed(true);
    })
  }

  const handleSaveChanges = async () => {
    if(props.modalType === "modalSchedule"){
      Schedule.updateSchedule(props.data, null, null, navigate).then((resp: any) => {
        props.openModal(false, "modalSchedule");
        setShowModalSuccess(resp.result.info);
      }).catch(() => {
        setShowModalFailed(true);
      })
    }else if(props.modalType === "modalCreateTravelAgent"){
      console.log('Fix');
      // await setShowModalResult(true);
      // TravelAgent.create(props.data.travel_agent, props.data.fields, navigate).then((resp: any) => {
      //   setShowModalSuccess(true);
      // }).catch(() => {
      //   setShowModalFailed(true);
      // })
    }
  }

  useEffect( () => {
    if(props.data && loading){
      let tmp = {
        ...props.data
      }
      setSelectedData(tmp);
      
      Frequencies.getFrecuencies(navigate).then((resp: any) => {
        let frecs = resp.data;

        frecs = frecs.map((value: any) => {
            return {
              id: value.id,
              name: t(value.name)
            }
        })
        setFrecuencies(frecs);
        Frequencies.getTimes(navigate).then((resp: any) => {
          setTimes(resp.data);
          TravelAgent.getTravelAgentsWOrg(navigate).then((resp: any) => {
            let agents = resp.data;
            agents = agents.map((value: any) => {
              return {
                id: value.id,
                name: `${value.user.name} ${value.user.lastName} (${value.name})`
              }
            });            
            setTravelAgentsWOrg(agents);
            setLoading(false);
          }).catch((err: any) => {
            console.log(err);
          });
        }).catch((err: any) => {
          console.log(err);
        });
      }).catch((err: any) => {
        console.log(err);
      });
      
    }
  }, [loading, props.data]);

  return (
    <div>
    {showModalSuccess && 
      <CModal 
        show={showModalSuccess} width="w-[620px]" height="h-[177px]" 
        title={t(showModalSuccess)}
        primaryBtn={t("MODAL.ACCEPT_BTN.CREATE_SCHEDULE")}
        close={handleClose}
        modalType={"modalCreateTravelAgent"}
        callback={handleClose}
        type={"success"}
      />
    } 
    {showModalFailed && 
      <CModal 
        show={showModalFailed} width="w-[620px]" height="h-[177px]" 
        title={t("MODAL.FAILED.CREATE_SCHEDULE.TITLE")}
        primaryBtn={t("MODAL.ACCEPT_BTN.CREATE_SCHEDULE")}
        close={handleClose}
        callback={handleClose}
        modalType={"modalCreateTravelAgent"}
        type={"failed"}
      />
    }
     {props.show && !showModalResult  && !loading ?
      props.modalType === "modalNewSchedule" ? 
        <><div className={Styles.c_modal_background}></div>
          <div className={`${Styles.c_modal_container} ${twStyles.c_modal_container_create}`}>
            <header className={Styles.c_modal_header}>
              <button onClick={() => props.openModal(false, "modalCreateTravelAgent")}>
                <img src={iconClose} alt="close" />
              </button>
            </header>
            <div className={Styles.c_modal_group}>
              <div className={Styles.c_modal_title}>
                <p>{t("MODAL.CREATE_SCHEDULE.TITLE")}</p>
              </div>
              <div className={`${Styles.c_modal_information_group} ${twStyles.c_modal_information_group_create}`}>
                <div className={Styles.c_modal_information_create}>
                <p>{t("MODAL.CREATE_SCHEDULE.INFO")}</p>
                </div>
              </div>
              <div className={Styles.c_modal_schedule_group}>
                <label className={Styles.c_modal_schedule_text}>
                  {t("MODAL.CREATE_SCHEDULE.FREQUENCY")}
                </label>
                <CDropDown 
                  placeholder={t("MODAL.CREATE_SCHEDULE.FREQUENCY_PLACEHOLDER")} className="w-[100%]" options={frecuencies} selected={props.data.frequency} 
                  setSelected={async (frequency: any) => {
                    props.data.frequency = frequency;
                    setEnableSaveButton1(true);
                  }}
                />
              </div>
              <div className={Styles.c_modal_schedule_group}>
                <label className={Styles.c_modal_schedule_text}>
                  {t("MODAL.CREATE_SCHEDULE.HOUR")}
                </label>
                <CDropDown 
                  placeholder={t("MODAL.CREATE_SCHEDULE.HOUR_PLACEHOLDER")} className="w-[100%]" options={times} selected={props.data.hour}
                  setSelected={(hour: any) => {
                    props.data.hour = hour
                    setEnableSaveButton2(true);
                  }}  
                />
              </div>
              <div className={Styles.c_modal_schedule_group}>
                <label className={Styles.c_modal_schedule_text}>
                  {t("MODAL.CREATE_SCHEDULE.TRAVEL_AGENT")}
                </label>
                <CDropDown 
                  placeholder={t("MODAL.CREATE_SCHEDULE.HOUR_PLACEHOLDER")} className="w-[100%]" options={travelAgentsWOrg} selected={props.data.travel_agent}
                  setSelected={(ta: any) => {
                    props.data.travel_agent = ta
                    setEnableSaveButton2(true);
                  }}  
                />
              </div>
              <div className={Styles.c_modal_schedule_button_group}>
                <div className={Styles.c_modal_schedule_button_create}>
                  {enableSaveButton1 && enableSaveButton2  ?
                    <CButton placeholder={t("MODAL.CREATE_SCHEDULE.SAVE_BTN")} action={() => {
                      if(props.callback){
                        props.callback(props.data);
                      }else{
                        props.close(false, props.modalType)
                      }
                    }} /> : 
                    <CButton placeholder={t("MODAL.CREATE_SCHEDULE.SAVE_BTN")} type="disabled" /> 
                }
                </div>
              </div>
            </div>
          </div></>
      :
      (props.editable ?
      // Edit schedule
        <><div className={Styles.c_modal_background}></div>
          <div className={`${Styles.c_modal_container} ${twStyles.c_modal_container}`}>
            <header className={Styles.c_modal_header}>
              <button onClick={() => props.close(false, "modalSchedule")}>
                <img src={iconClose} alt="close" />
              </button>
            </header>
            <div className={Styles.c_modal_group}>
              <div className={Styles.c_modal_title}>
                <p>{t("MODAL.EDIT_SCHEDULE.TITLE")}</p>
              </div>
              <div className={`${Styles.c_modal_information_group} ${twStyles.c_modal_information_group}`}>
                <div className={Styles.c_modal_information}>
                <p>{t("MODAL.EDIT_SCHEDULE.INFO")}</p>
                </div>
                {/* <span className={Styles.c_modal_detele_group} onClick={handleDeleteButton}> */}
                <span className={Styles.c_modal_detele_group} onClick={() => {
                    if(props.callbackDelete){
                      props.callbackDelete(props.data);
                    }else{
                      props.close(false, props.modalType)
                    }
                  }}>
                  <img src={iconDelete} alt="close" className={Styles.c_modal_delete_icon}/>
                  <p className={Styles.c_modal_detele_text}>
                    {t("MODAL.EDIT_SCHEDULE.DELETE")}
                  </p>
                </span>
              </div>
              <div className={Styles.c_modal_schedule_group}>
                <label className={Styles.c_modal_schedule_text}>
                  {t("MODAL.EDIT_SCHEDULE.FREQUENCY")}
                </label>
                <CDropDown 
                  placeholder={""} className="w-[100%]" options={frecuencies} selected={props.data.frequency} 
                  setSelected={async (frequency: any) => {
                    props.data.frequency = frequency;
                    setEnableSaveButton1(true);
                  }}
                />
              </div>
              <div className={Styles.c_modal_schedule_group}>
                <label className={Styles.c_modal_schedule_text}>
                  {t("MODAL.EDIT_SCHEDULE.HOUR")}
                </label>
                <CDropDown 
                  placeholder={""} className="w-[100%]" options={times} selected={props.data.hour}
                  setSelected={(hour: any) => {
                    props.data.hour = hour
                    setEnableSaveButton1(true);
                  }}  
                />
              </div>
              <div className={Styles.c_modal_schedule_group}>
                <label className={Styles.c_modal_schedule_text}>
                  {t("MODAL.EDIT_SCHEDULE.TRAVEL_AGENT")}
                </label>
                <div className="w-[100%]">
                  <CTextInput value={props.data.travel_agent.name} disabled/>
                </div>
              </div>
              <div className={Styles.c_modal_schedule_button_group}>
                <div className={Styles.c_modal_schedule_button}>
                  {enableSaveButton1  ?
                    // <CButton placeholder={t("MODAL.EDIT_SCHEDULE.SAVE_BTN")} action={handleSaveChanges} /> : 
                    <CButton placeholder={t("MODAL.EDIT_SCHEDULE.SAVE_BTN")} action={() => {
                      if(props.callback){
                        props.callback(props.data);
                      }else{
                        props.close(false, props.modalType)
                      }
                    }} /> : 
                    <CButton placeholder={t("MODAL.EDIT_SCHEDULE.SAVE_BTN")} type="disabled" /> 
                }
                </div>
              </div>
            </div>
          </div></> 
        : (props.cancelable && props.modalType !== "modalCreateTravelAgent"?
          <><div className={Styles.c_modal_background}></div>
            <div className={`${Styles.c_modal_container} ${twStyles.c_modal_container}`}>
              <header className={Styles.c_modal_header}>
                <button onClick={() => props.openModal(false, "modalSchedule")}>
                  <img src={iconClose} alt="close" />
                </button>
              </header>
              <div className={Styles.c_modal_group}>
                <div className={Styles.c_modal_title}>
                  <p>{t("MODAL.EDIT_EXCEL.TITLE")}</p>
                </div>
                <div className={`${Styles.c_modal_information_group} ${twStyles.c_modal_information_group}`}>
                  <div className={Styles.c_modal_information_cancelable}>
                  <p>{t("MODAL.EDIT_EXCEL.INFO")}</p>
                  </div>
                </div>
                <div className={Styles.c_moda_cancelable_btn_group}>
                  <CButton 
                    placeholder={props.data.active ? t("MODAL.EDIT_EXCEL.BTN_PAUSE") : t("MODAL.EDIT_EXCEL.BTN_RESUME")} 
                    type="secondary" 
                    icon={iconPause} 
                    height={36} 
                    action={() => {handleActions(false, true)}}
                    />
                  <CButton 
                    placeholder={t("MODAL.EDIT_EXCEL.BTN_DESACTIVATE")} 
                    type="secondary" 
                    icon={iconDelete} 
                    height={36} 
                    action={() => {handleActions(true, false)}}
                  />
                </div>
                <div className={Styles.c_modal_schedule_group}>
                  <label className={Styles.c_modal_schedule_text}>
                    {t("MODAL.EDIT_SCHEDULE.FREQUENCY")}
                  </label>
                  <CDropDown 
                    placeholder={""} className="w-[100%]" options={frecuencies} selected={props.data.frequency} 
                    setSelected={async (frequency: any) => {
                      props.data.frequency = frequency;
                      setEnableSaveButton1(true);
                    }}
                  />
                </div>
                <div className={Styles.c_modal_schedule_group}>
                  <label className={Styles.c_modal_schedule_text}>
                    {t("MODAL.EDIT_SCHEDULE.HOUR")}
                  </label>
                  <CDropDown 
                    placeholder={""} className="w-[100%]" options={times} selected={props.data.hour}
                    setSelected={(hour: any) => {
                      props.data.hour = hour
                      setEnableSaveButton1(true);
                    }}  
                  />
                </div>
                <div className={Styles.c_modal_schedule_group}>
                  <label className={Styles.c_modal_schedule_text}>
                    {t("MODAL.EDIT_SCHEDULE.TRAVEL_AGENT")}
                  </label>
                  <div className="w-[100%]">
                    <CTextInput value={props.data.travel_agent.name} disabled/>
                  </div>
                </div>
                <div className={Styles.c_modal_schedule_button_group}>
                  <div className={Styles.c_modal_schedule_button}>
                    {enableSaveButton1  ?
                      <CButton placeholder={t("MODAL.EDIT_SCHEDULE.SAVE_BTN")} action={handleSaveChanges} /> : 
                      <CButton placeholder={t("MODAL.EDIT_SCHEDULE.SAVE_BTN")} type="disabled" /> 
                  }
                  </div>
                </div>
              </div>
            </div>
          </>
        :  
        // Create schedule
        <><div className={Styles.c_modal_background}></div>
        <div className={`${Styles.c_modal_container} ${twStyles.c_modal_container_create}`}>
          <header className={Styles.c_modal_header}>
            <button onClick={() => props.openModal(false, "modalCreateTravelAgent")}>
              <img src={iconClose} alt="close" />
            </button>
          </header>
          <div className={Styles.c_modal_group}>
            <div className={Styles.c_modal_title}>
              <p>{t("MODAL.CREATE_SCHEDULE.TITLE")}</p>
            </div>
            <div className={`${Styles.c_modal_information_group} ${twStyles.c_modal_information_group_create}`}>
              <div className={Styles.c_modal_information_create}>
              <p>{t("MODAL.CREATE_SCHEDULE.INFO")}</p>
              </div>
            </div>
            <div className={Styles.c_modal_schedule_group}>
              <label className={Styles.c_modal_schedule_text}>
                {t("MODAL.CREATE_SCHEDULE.FREQUENCY")}
              </label>
              <CDropDown 
                placeholder={t("MODAL.CREATE_SCHEDULE.FREQUENCY_PLACEHOLDER")} className="w-[100%]" options={frecuencies} selected={props.data.frequency} 
                setSelected={async (frequency: any) => {
                  props.data.frequency = frequency;
                  props.data.travel_agent.schedule = {
                    ...props.data.travel_agent.schedule,
                    frequency: frequency.id,
                  };
                  setEnableSaveButton1(true);
                }}
              />
            </div>
            <div className={Styles.c_modal_schedule_group}>
              <label className={Styles.c_modal_schedule_text}>
                {t("MODAL.CREATE_SCHEDULE.HOUR")}
              </label>
              <CDropDown 
                placeholder={t("MODAL.CREATE_SCHEDULE.HOUR_PLACEHOLDER")} className="w-[100%]" options={times} selected={props.data.hour}
                setSelected={(hour: any) => {
                  props.data.hour = hour
                  props.data.travel_agent.schedule = {
                    ...props.data.travel_agent.schedule,
                    time: hour.id,
                  };
                  setEnableSaveButton2(true);
                }}  
              />
            </div>
            <div className={Styles.c_modal_schedule_group}>
              <label className={Styles.c_modal_schedule_text}>
                {t("MODAL.CREATE_SCHEDULE.TRAVEL_AGENT")}
              </label>
              <div className={Styles.c_modal_schedule_text_input}>
                <CTextInput
                  value={`${props.data.travel_agent.name} ${props.data.travel_agent.lastname} ${props.data.travel_agent.organization_name}`} 
                  disabled
                />
              </div>
            </div>
            <div className={Styles.c_modal_schedule_button_group}>
              <div className={Styles.c_modal_schedule_button_create}>
                {enableSaveButton1 && enableSaveButton2  ?
                  <CButton placeholder={t("MODAL.CREATE_SCHEDULE.SAVE_BTN")} action={() => {
                    if(props.callback){
                      props.callback(props.data);
                    }else{
                      props.close(false, props.modalType)
                    }
                  }} /> : 
                  <CButton placeholder={t("MODAL.CREATE_SCHEDULE.SAVE_BTN")} type="disabled" /> 
              }
              </div>
            </div>
          </div>
        </div></>
      )) : null }
    </div>
  );
};

const twStyles = {
  c_modal_container: 'sm:w-[373px] md:w-[490px] sm:h-[580px] w-[620px] h-[520px]',
  c_modal_container_create: 'sm:w-[373px] md:w-[490px] md:h-[580px] w-[620px] h-[520px]',
  c_modal_information_group: 'sm:py-[32px]',
  c_modal_information_group_create: 'md:py-[32px]'
}