import Styles from "./c-header.module.css";
import CLanguageDrop from "../c-language-drop/c-language-drop";
import CUserDrop from "../c-user-drop/c-user-drop";

import backIcon from "../../assets/icon-back.svg";

export default function CHeader(props: any) {

  return (
    <div className={Styles.c_header}>
      <div className={props.back ? Styles.c_header_container_b : Styles.c_header_container}>
        {props.back &&
          <span className={Styles.c_header_back_button} onClick={() => {props.callback()}}>
            <img
              src={backIcon}
              alt="back"
              className={Styles.c_header_user_avatar}
            />
          </span>
        }
        <div className={Styles.c_header_user_options_group}>
          <CUserDrop values={props.values} openModal={props.openModal} avatar={props.avatar}/>
          <CLanguageDrop />
        </div>
      </div>
    </div>
  );
}
