import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Styles from "./c-button.module.css";
interface Props {
  placeholder?: string;
  type?: string;
  action?: any;
  testid?: any;
  height?: number;
  width?: any;
  icon?: any;
}

export function CButton({ placeholder, action='', testid='', type="default", width='100%', height=48, icon}: Props) {

const DefaultButton = styled(Button)<ButtonProps>(() => ({
  width: '100%',
  height: 48,
  borderRadius: 10,
  color: '#fff',
  fontFamily: 'Poppins, sans-serif',
  fontSize: 14,
  fontWeight: '300',
  textAlign: 'center',
  letterSpacing: 0.1,
  backgroundColor: '#017ef3',
  '&:hover': {
    backgroundColor: '#017ef3',
  },
  boxShadow: 'none',
}));

const SecondaryButton = styled(Button)<ButtonProps>(() => ({
  width: width,
  height: height,
  color: '#017ef3',
  fontFamily: 'Poppins, sans-serif',
  fontSize: 14,
  fontWeight: '300',
  textAlign: 'center',
  letterSpacing: 0.1,
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: '#fff',
    borderColor: '#979797',
    borderWidth: 1,
  },
  '&:active': {
    backgroundColor: '#fff',
    borderColor: '#017ef3',
    borderWidth: 1,
  },
  borderColor: '#e5e7eb',
  borderWidth: 1,
  borderRadius: 10,
  boxShadow: 'none',
  textTransform: 'none'
}));

const LoadingButton = styled(Button)<ButtonProps>(() => ({
  width: '100%',
  height: 48,
  borderRadius: 10,
  color: '#fff',
  fontFamily: 'Poppins, sans-serif',
  fontSize: 14,
  fontWeight: '300',
  textAlign: 'center',
  letterSpacing: 0.1,
  backgroundColor: '#0171db',
  '&:hover': {
    backgroundColor: '#0171db',
  },
  boxShadow: 'none',
  cursor: 'default'
}));

const TextButton = styled(Button)<ButtonProps>(() => ({
  width: '100%',
  height: height,
  color: '#017ef3',
  fontFamily: 'Poppins, sans-serif',
  fontSize: 14,
  fontWeight: '500',
  textAlign: 'center',
  letterSpacing: 0.1,
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: '#fff',
    borderColor: 'transparent'
  },
  borderColor: 'transparent',
  borderWidth: 1,
  borderRadius: 10,
  boxShadow: 'none',
  textTransform: 'none'
}));

const DisabledButton = styled(Button)<ButtonProps>(() => ({
  width: '100%',
  height: 48,
  borderRadius: 10,
  color: '#fff',
  fontFamily: 'Poppins, sans-serif',
  fontSize: 14,
  fontWeight: '300',
  textAlign: 'center',
  letterSpacing: 0.1,
  backgroundColor: '#aaaaaa',
  '&:hover': {
    backgroundColor: '#aaaaaa',
  },
  boxShadow: 'none',
  cursor: 'default'
}));

const iconStyle = {
  width: 16,
  height: 16,
  marginRight: 12,
  filter: "invert(34%) sepia(83%) saturate(3681%) hue-rotate(194deg) brightness(100%) contrast(100%)",
};
const iconStyleText = {
  width: 22,
  height: 24,
  marginRight: 12,
};

const returnButton = (type: any) => {
  switch(type) { 
    case "file": { 
      return (
        <SecondaryButton data-testid={testid} variant="outlined" disableElevation  component="label" >
          {icon &&
            <img src={icon} alt='' style={iconStyle}/>
          }
          {placeholder}
          <input
            type="file"
            hidden
            onChange={(e) => {action(e)}}
          />
        </SecondaryButton>
      );
    }
    case "secondary": { 
      return (
        <SecondaryButton data-testid={testid} variant="outlined" disableElevation onClick={() => {action()}} >
          {icon &&
            <img src={icon} alt='' style={iconStyle}/>
          }
          {placeholder}
        </SecondaryButton>
    );
    } 
    case "loading": { 
      return (
        <LoadingButton data-testid={testid} variant="contained" disableElevation disableRipple>
          <div className={Styles.c_button_animation_container}>
            <div className={Styles.c_button_animation_dot}></div>
            <div className={Styles.c_button_animation_dot}></div>
            <div className={Styles.c_button_animation_dot}></div>
          </div>
        </LoadingButton>
      );
    } 
    case "disabled": { 
      return (
        <DisabledButton data-testid={testid} variant="contained" disableElevation disableRipple>
          {placeholder}
        </DisabledButton>
      );
    } 
    case "text": {
      return (
        <TextButton data-testid={testid} variant="outlined" disableElevation onClick={() => {action()}} >
          {icon &&
            <img src={icon} alt='' style={iconStyleText}/>
          }
          {placeholder}
        </TextButton>
      );
    } 
    default: { 
      return (
        <DefaultButton data-testid={testid} variant="contained" disableElevation onClick={() => {action();}}>
          {placeholder}
        </DefaultButton>
      );
    } 
 } 
}

  return (
    <div>
      {returnButton(type)}
    </div>
  );
}