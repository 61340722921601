import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HCaptcha from '@hcaptcha/react-hcaptcha';

import Styles from "./v-login.module.css";
import CLanguageDrop from "../../components/c-language-drop/c-language-drop";
import CTextInput from "../../components/c-text-input/c-text-input";
import { CButton } from "../../components/c-button/c-button";

import LoginImg from "../../assets/LoginImg.png";
import Logo from "../../assets/logo@3x.png";

import { BAD_CREDENTIALS } from "../../services/utils/s-error-constants";
import Auth from '../../services/auth/s-auth'
import { UserModel } from '../../model/m-user';
import User from "../../services/utils/s-user";
import { selectMenuItem, selectMenu } from "../../store/slices/menuSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import CModal from "../../components/c-modal/c-modal";

<script src="https://js.hcaptcha.com/1/api.js" async defer></script>

export default function VLogin() {
  let user = new UserModel();
  let menu = useAppSelector(selectMenu);
  const dispatch = useAppDispatch();

  const instanceUser = new User();
  const recaptchaRef = useRef<any>(null);

  const refEmail = useRef<any>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isVerified, setIsverified] = useState<boolean>(false);
  const [failLogin, setFailLogin] = useState<boolean>(false);
  const [loadinButton, setLoadingButton] = useState<boolean>(false);
  const [showDefaultButton, setShowDefaultButton] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [openModalForgotPassword, setOpenModalForgotPassword] = useState<boolean>(false);
  const [openModalResetPassword, setOpenModalResetPassword] = useState<boolean>(false);
  const [openModalForgotPasswordResponse, setOpenModalForgotPasswordResponse] = useState<boolean>(false);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState<boolean>(false);
  const [changePasswordFailed, setChangePasswordFailed] = useState<boolean>(false);

  const handleCaptchaSubmission = (value: any) => {
    if (recaptchaRef.current) {
      setIsverified(true);
    }
  };

  const saveData = (value: any) => {
    const {email, name, lastname, rol, language, operations, organizations, lastUploadStatus, lastUpload} = value;
    user = {
      email,
      name,
      lastname,
      rol: rol.name,
      language,
      operations,
      organizations,
      lastUploadStatus,
      lastUpload
    }
    localStorage.setItem("user", JSON.stringify(user));
    instanceUser.setUser(user);
  }

  const doLogin = async () => {
    setShowDefaultButton(false);
    setLoadingButton(true);
    setFailLogin(false);
    await Auth.doLogin(email, password).then((resp: any) => {
      if(resp.data.changePassword){
        setOpenModalResetPassword(true);
      }else {
        if (resp.data.rol.name === "ROL.SUPER_ADMIN.NAME"){
          saveData(resp.data);
          return navigate("/dashboard");
        }else if (resp.data.rol.name === "ROL.TRAVEL_AGENT.NAME"){
          saveData(resp.data);
          return navigate("/dashboard-ta");
        }
      }
    }).catch((err: any) => {
      setLoadingButton(false);
      setShowDefaultButton(true);
      if(err.data.result.code === BAD_CREDENTIALS){
        setFailLogin(true);
        setErrorMsg(t(err.data.result.info))
      }
    })
  };

  const handlePasswordModal = () => {
    setOpenModalForgotPassword(true)
  }

  const callRequestChangePassword = (email: any) => {
    if(email){
      Auth.requestChangePassword(email)
      .then((resp: any) => {
        setOpenModalForgotPassword(false);
        setOpenModalForgotPasswordResponse(true);
      })
      .catch((err: any) => {
        setOpenModalForgotPassword(false);
        setChangePasswordFailed(true);
        setLoadingButton(false);
        setShowDefaultButton(true);  
      })
    }
  }

  const callRequestResetPassword = (newPassword: any) => {
    Auth.changePassword(email, newPassword, password)
    .then((resp: any) => {
      setOpenModalResetPassword(false);
      setChangePasswordSuccess(true);
      setLoadingButton(false);
      setShowDefaultButton(true);    
    })
    .catch((err: any) => {
      setOpenModalResetPassword(false);
      setChangePasswordFailed(true);
      setLoadingButton(false);
      setShowDefaultButton(true);    
    })
  }
 

  return (
    <div>
      <CModal
        width="sm:w-[385px] w-[620px]" 
        height="h-[267px] sm:h-[320px]" 
        show={openModalForgotPassword} 
        title={t("LOGIN.FORGOT_PASSWORD_TITLE")} 
        subtitle={t("LOGIN.FORGOT_PASSWORD_SUBTITLE")}
        primaryBtn={t("LOGIN.FORGOT_BUTTON")}
        close={() => {
          setLoadingButton(false);
          setShowDefaultButton(true);   
          setOpenModalForgotPassword(false)
        }}
        modalType={"forgotPassword"}
        callback={callRequestChangePassword}
        type={"forgotPassword"}
      />
      <CModal
        width="sm:w-[385px] w-[620px]" 
        height="h-[337px] sm:h-[330px]" 
        show={openModalResetPassword} 
        title={t("LOGIN.RESET_TITLE")} 
        subtitle={t("LOGIN.RESET_SUBTITLE")}
        primaryBtn={t("LOGIN.FORGOT_BUTTON")}
        close={() => {
          setLoadingButton(false);
          setShowDefaultButton(true);   
          setOpenModalResetPassword(false);
        }}
        modalType={"resetPassword"}
        callback={callRequestResetPassword}
        type={"resetPassword"}
      />
      <CModal
        width="sm:w-[385px] w-[620px]" 
        height="h-[210px] sm:h-[280px]" 
        show={openModalForgotPasswordResponse} 
        title={t("LOGIN.RESPONSE_TITLE")} 
        primaryBtn={t("LOGIN.RESPONSE_BUTTON")}
        close={() => {
          setLoadingButton(false);
          setShowDefaultButton(true);   
          setOpenModalForgotPasswordResponse(false);
        }}
        modalType={"passengerSuccessCreated"}
        callback={() => {setOpenModalForgotPasswordResponse(false)}}
        type={"success"}
      />
      <CModal
        width="sm:w-[385px] w-[620px]" 
        height="h-[177px] sm:h-[190px]" 
        show={changePasswordSuccess} 
        title={t("LOGIN.RESET_SUCCESS_TITLE")} 
        primaryBtn={t("LOGIN.RESPONSE_BUTTON")}
        close={() => {
          setLoadingButton(false);
          setShowDefaultButton(true);   
          setChangePasswordSuccess(false);
        }}
        modalType={"passengerSuccessCreated"}
        callback={() => {setChangePasswordSuccess(false)}}
        type={"success"}
      />
      <CModal
        width="sm:w-[385px] w-[620px]" 
        height="h-[177px] sm:h-[190px]" 
        show={changePasswordFailed} 
        title={t("LOGIN.ERROR")} 
        primaryBtn={t("LOGIN.RESPONSE_BUTTON")}
        close={() => {
          setLoadingButton(false);
          setShowDefaultButton(true);
          setChangePasswordFailed(false);
        }}
        modalType={"passengerSuccessCreated"}
        callback={() => {setChangePasswordFailed(false)}}
        type={"failed"}
      />
      <div id="refButton" className={Styles.v_login}>
        <div className={Styles.v_login_form_container}>
          <div className={Styles.v_login_logo_container}>
            <img src={Logo} className={Styles.v_login_logo} alt="Logo" />
            <CLanguageDrop />
          </div>
          <div className={Styles.v_login_form}>
            <h1 className={Styles.v_login_form_title}>{t("LOGIN.TITLE")}</h1>
            <div className={Styles.v_login_form_input_group}>
              <label
                htmlFor="v_login_email"
                className={Styles.v_login_form_label}
              >
                {t("LOGIN.USER_LABEL")}
              </label>
              <CTextInput
                refa={refEmail}
                id="v_login_email"
                type="text"
                placeholder={t("LOGIN.USER_PLACEHOLDER")}
                value={email}
                setValue={(email: any) => {
                  setEmail(email);
                  setFailLogin(false);
                }}
                failed={failLogin}
              />
            </div>
            <div className={Styles.v_login_form_input_group}>
              <label
                htmlFor="v_login_password"
                className={Styles.v_login_form_label}
              >
                {t("LOGIN.PASSWORD_LABEL")}
              </label>
              <CTextInput
                id="v_login_password"
                type="password"
                placeholder={t("LOGIN.PASSWORD_PLACEHOLDER")}
                value={password}
                setValue={(password: any) => {
                  setPassword(password);
                  setFailLogin(false);
                }}
                setClick={setPassword}
                failed={failLogin}
              />
              {errorMsg && 
                <span className={Styles.v_login_error_msg}>{errorMsg}</span>
              }
            </div>
              <div className={Styles.v_login_form_input_group_f} onClick={handlePasswordModal}>
                <p className={Styles.v_login_forgot_text}>
                  {t("LOGIN.FORGOT-PASSWORD_TEXT")}
                </p>
                <p className={Styles.v_login_forgot_link_text}>
                  {t("LOGIN.FORGOT-PASSWORD_LINK_TEXT")}
                </p>
              </div>
            <div className={Styles.v_login_form_input_group}>
              <div className={Styles.v_login_captcha}>
                 <HCaptcha
                  sitekey="08020dd0-f290-4788-bba5-0480a86d4bfd"
                  onVerify={handleCaptchaSubmission}
                  ref={recaptchaRef}
              />
              </div>
            </div>
            <div className={Styles.v_login_form_input_group}>
              {showDefaultButton &&
                <CButton 
                  placeholder={t("LOGIN.BUTTON")} 
                  action={doLogin} 
                  type={email && password && isVerified ? ""  : "disabled"}
                />
              }
              {loadinButton &&
                <CButton type="loading"/>
              }
            </div>
          </div>
        </div>
        <div className={Styles.v_login_image_container}>
          <img src={LoginImg} alt="plane" />
        </div>
      </div>
    </div>
  );
}
