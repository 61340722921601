import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

export interface MenuState {
  value: number;
}

const initialState: MenuState = {
  value: 1,
};

export const MenuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    selectMenuItem: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
  },
});

export const { selectMenuItem } = MenuSlice.actions;

export const selectMenu = (state: RootState) => state.menu.value;

export default MenuSlice.reducer;
