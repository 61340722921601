import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Styles from "./v-manage-travel-agent-edit.module.css";
import CTextInput from "../../../components/c-text-input/c-text-input";
import { CButton } from "../../../components/c-button/c-button";
import CDropDown from "../../../components/c-dropdown/c-dropdown";

import addIcon from "../../../assets/icon-add.svg";
import deleteIcon from "../../../assets/icon-delete.svg";

import TravelAgent from "../../../services/travel-agent/s-travel-agent";
import { TravelAgentModel } from "../../../model/m-travel-agent";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function VManageTravelAgentEdit(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const alphaFormat = /^([a-zA-Z ]+)$/
  const [clientName, setClientName] = useState<any>({});
  const [nif, setNif] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [organizations, setOrganizations] = useState<any>([]);
  const [organization, setOrganization] = useState<any>({});

  const [formatNameError, setFormatNameError] = useState(false);
  const [formatSurnameError, setFormatSurnameError] = useState(false);
  
  const [travelAgent, setTravelAgent] = useState(new TravelAgentModel());
  const [fields, setFields] = useState<any>([]);
  const [taData, setTAData] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const saveData = () => {
    let tmpTAData = JSON.parse(JSON.stringify(taData));

    tmpTAData = tmpTAData.map((t: any) => {
      if(t.content){
        t.content = t.content.split(',');
      }else{
        t.content = [];
      }
      return t;
    })

    travelAgent.id = props.travelAgentId;
    travelAgent.email = email;
    travelAgent.name = name;
    travelAgent.lastname = surname;
    travelAgent.organization = {
      id: organization.id,
      selection: tmpTAData,
    };
  }

  const deleteAgent = () => {
    TravelAgent.deleteTravelAgent(props.travelAgentId, navigate)
    .then((resp: any) => {
      props.openModal(true, "modalResponseSuccess", null, false, () => {
        props.back();
        props.from(false);
        props.openModal(false, "modalResponseSuccess");
      }, null, false, null, resp.result.info);
    }).catch((err: any) => {
      props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
    });
  }

  const updateAgent = () => {

    saveData();
    TravelAgent.updateTravelAgent(props.travelAgentId, travelAgent, navigate)
    .then((resp: any) => {
      props.openModal(true, "modalResponseSuccess", null, false, () => {
        props.back();
        props.from(false);
        props.openModal(false, "modalResponseSuccess");
      }, null, false, null, resp.result.info);
    })
    .catch((error: any) => {
      props.openModal(true, "failed")
    });
  };

  const handleMandatory = (value: any) => {
    let tmpTAData: any = taData;
    if(!value.mandatory && tmpTAData){
      tmpTAData = tmpTAData.map((ta: any) => {
        if(ta.field_id === value.id){
          ta.mandatory = !ta.mandatory;
          ta.hide = false;
        }
        return ta;
      }); 
    }
    setTAData(tmpTAData);
  }
  
  const handleHide = (value: any) => {
    if(!value.mandatory && taData){
      let tmpTAData: any = taData;
      tmpTAData = tmpTAData.map((ta: any) => {                      
        if(ta.field_id === value.id){
          if(!ta.mandatory){
            ta.hide = !ta.hide;
          }
        }
        return ta;
      });
      setTAData(tmpTAData);
    }
  }

  const handleMultiSelect = (value: any) => {
    if(taData){
      let tmpTAData: any = taData;
      tmpTAData = tmpTAData.map((ta: any) => {                      
        if(ta.field_id === value.id){
          ta.multiselect = !ta.multiselect;
        }
        return ta;
      });
      setTAData(tmpTAData);
    }
  }

  const updateName = (data: any) => {
    setName(data);
    if (!alphaFormat.test(data)){
      setFormatNameError(true);
    }else{
      setFormatNameError(false);
    }
  }
  
  const updateSurname = (data: any) => {
    setSurname(data);
    if (!alphaFormat.test(data)){
      setFormatSurnameError(true);
    }else{
      setFormatSurnameError(false);
    }
  }

  const changeOrganization = (value: any) => {
    setOrganization(value);
    TravelAgent.getTravelAgentDetail(props.travelAgentId, navigate)
    .then((resp: any) => {
      let orgs = [];
      for (let i = 0; i < resp.data.organizations.length; i++) {
        let element = resp.data.organizations[i];
        let tmp = {
          id: element.id,
          name: `${element.name} - ${element.cifNif}`
        }
        if(element.id === value.id){
          let tmpTAData = element.selection;
          tmpTAData = tmpTAData.map((t: any) => {
            t.content = t.content.join(',');
            return t;
          })
          setClientName(element.name);
          setNif(element.cifNif);
          setEmail(resp.data.email);
          setName(resp.data.name);
          setSurname(resp.data.lastName);
          setTAData(tmpTAData);
        }
        orgs.push(tmp);
      }
      setOrganizations(orgs);
    })
    .catch((err: any) => {
      console.log(err);
    })
  }
  
  useEffect(() => {
    if(loading){
      TravelAgent.getFields(navigate).then((resp: any) => {
        setFields(resp.data);
        TravelAgent.getTravelAgentDetail(props.travelAgentId, navigate)
        .then((resp: any) => {
          let orgs = [];
          for (let i = 0; i < resp.data.organizations.length; i++) {
            let element = resp.data.organizations[i];
            let tmp = {
              id: element.id,
              name: `${element.name} - ${element.cifNif}`
            }
            if(element.selected){
              setClientName(element.name);
              setOrganization(tmp);
              
              let tmpTAData = element.selection;

              tmpTAData = tmpTAData.map((t: any) => {
                t.content = t.content.join(',');
                if(t.coclis && t.coclis.length > 0){
                  let tmp = t.coclis_objs;
                  tmp = tmp.map((c: any) => c.name);
                  let tmpNames = tmp.join('@@');
                  t.cocliListNames = tmpNames;
                  if(tmpNames.length > 30){
                    tmpNames = tmpNames.substring(0,30).concat('...');
                  }
                  t.cocliListCodes = tmpNames;
                }
                return t;
              })
              setTAData(tmpTAData);
              setNif(element.cifNif);
              setEmail(resp.data.email);
              setName(resp.data.name);
              setSurname(resp.data.lastName);
            }
            orgs.push(tmp);
          }
          setOrganizations(orgs);
          setLoading(false);
        })
        .catch((err: any) => {
          console.log(err);
        })
      }).catch((err: any) => {
        props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, "RESPONSE.ERROR");
      });      
    }
  },[travelAgent, clientName, loading, navigate, props]);

  return (
    !loading ?
      <div className={Styles.v_manage_travel_agent_edit}>
        <div className={Styles.v_manage_travel_agent_edit_title_group}>
          <div className={Styles.v_manage_travel_agent_edit_title_subgroup}>
            <h1 className={Styles.v_manage_travel_agent_edit_title}>
              {t("MANAGE_TRAVEL_AGENTS.EDIT.FIRST_FORM_TITLE")}
            </h1>
            <CButton
              placeholder={t("MANAGE_TRAVEL_AGENTS.EDIT.BTN_DELETE")} 
              icon={deleteIcon} 
              type="text" 
              action={()=> {
                props.openModal(true, "taDelete", null, false, 
                () => {deleteAgent()}, 
                () => {props.openModal(false, "passengerDelete")}, true)
              }
            }
            />
          </div>
          <p className={Styles.v_manage_travel_agent_edit_subtitle}>
            {t("MANAGE_TRAVEL_AGENTS.EDIT.FIRST_FORM_SUBTITLE")}
          </p>
          <div className={Styles.v_manage_travel_agent_edit_input_group}>
            <label
              className={Styles.v_manage_travel_agent_edit_input_label}
              htmlFor="client-org"
              >
                {t("MANAGE_TRAVEL_AGENTS.EDIT.SELECT_ORGANIZATION")}
            </label>
            <CDropDown
              placeholder={t("MANAGE_TRAVEL_AGENTS.EDIT.SELECT_ORGANIZATION")} 
              secondaryPlaceholder={t("MANAGE_PASSENGERS.PLACEHOLDER_SEARCH")}
              titleButton={t("MANAGE_PASSENGERS.FINDER_BUTTON")}
              className="w-[100%] sm:w-[326px]"
              options={organizations} 
              selected={organization} 
              setSelected={(value: any) => {changeOrganization(value)}}
            />
          </div>
        </div>
        <div className={Styles.v_manage_travel_agent_edit_form}>
          <div className={Styles.v_manage_travel_agent_edit_form_group}>
            <div className={Styles.v_manage_travel_agent_edit_input_group}>
              <label
                className={Styles.v_manage_travel_agent_edit_input_label}
                htmlFor="client-name"
              >
                {t("CREATE_TRAVEL_AGENT.FIRST_FORM_CLIENT_LABEL")}
              </label>
              <CTextInput
                id="client-name"
                type="text"
                placeholder={t("CREATE_TRAVEL_AGENT.FIRST_FORM_CLIENT_LABEL")}
                disabled
                value={clientName}
              />
            </div>
            <div className={Styles.v_manage_travel_agent_edit_input_group}>
              <label
                className={Styles.v_manage_travel_agent_edit_input_label}
                htmlFor="nif"
              >
                {t("CREATE_TRAVEL_AGENT.FIRST_FORM_NIF_CIF")}
              </label>
              <CTextInput
                id="nif"
                type="text"
                placeholder="NIF / CIF"
                disabled
                value={nif}
              />
            </div>
          </div>
          <div className={Styles.v_manage_travel_agent_edit_form_group}>
            <div className={Styles.v_manage_travel_agent_edit_input_group}>
              <label
                className={Styles.v_manage_travel_agent_edit_input_label}
                htmlFor="name"
              >
                {t("CREATE_TRAVEL_AGENT.FIRST_FORM_NAME_LABEL")}
              </label>
              <CTextInput
                id="name"
                type="text"
                placeholder={t("CREATE_TRAVEL_AGENT.FIRST_FORM_NAME_LABEL")}
                value={name}
                setValue={updateName}
                failed={formatNameError}
                failedText={t("CREATE_TRAVEL_AGENT.FORM_FORMAT_ERROR")}
              />
            </div>
            <div className={Styles.v_manage_travel_agent_edit_input_group}>
              <label
                className={Styles.v_manage_travel_agent_edit_input_label}
                htmlFor="surname"
              >
                {t("CREATE_TRAVEL_AGENT.FIRST_FORM_SURNAME_LABEL")}
              </label>
              <CTextInput
                id="surname"
                type="text"
                placeholder={t("CREATE_TRAVEL_AGENT.FIRST_FORM_SURNAME_LABEL")}
                value={surname}
                setValue={updateSurname}
                failed={formatSurnameError}
                failedText={t("CREATE_TRAVEL_AGENT.FORM_FORMAT_ERROR")}
              />
            </div>
          </div>
          <div className={Styles.v_manage_travel_agent_edit_form_group}>
            <div className={Styles.v_manage_travel_agent_edit_input_group}>
              <label
                className={Styles.v_manage_travel_agent_edit_input_label}
                htmlFor="email"
              >
                {t("CREATE_TRAVEL_AGENT.FIRST_FORM_EMAIL_LABEL")}
              </label>
              <CTextInput
                id="email"
                type="email"
                placeholder={t("CREATE_TRAVEL_AGENT.FIRST_FORM_EMAIL_LABEL")}
                disabled
                value={email}
              />
            </div>
          </div>
        </div>
        <div className={Styles.v_manage_travel_agent_edit_title_group}>
          <h1 className={Styles.v_manage_travel_agent_edit_title}>
            {t("CREATE_TRAVEL_AGENT.SECOND_FORM_TITLE")}
          </h1>
          <p className={Styles.v_manage_travel_agent_edit_subtitle}>
            {t("MANAGE_TRAVEL_AGENTS.EDIT.SECOND_FORM_SUBTITLE")}
          </p>
        </div>
        <div className={Styles.v_manage_travel_agent_edit_data_form}>
          <p
            className={`${Styles.v_manage_travel_agent_edit_data_form_field_column},
            ${Styles.v_manage_travel_agent_edit_data_form_title}`}
          >
            {t("CREATE_TRAVEL_AGENT.FORM_COLUMN_FIELD_TITLE_TEXT")}
          </p>
          <p
            className={`${Styles.v_manage_travel_agent_edit_data_form_content_column},
            ${Styles.v_manage_travel_agent_edit_data_form_title}`}
          >
            {t("CREATE_TRAVEL_AGENT.FORM_COLUMN_CONTENT_TITLE_TEXT")}
          </p>
          <p
            className={`${Styles.v_manage_travel_agent_edit_data_form_mandatory_column}, ${Styles.v_manage_travel_agent_edit_data_form_title}
            `}
          >
            {t("CREATE_TRAVEL_AGENT.FORM_COLUMN_MANDATORY_TITLE_TEXT")}
          </p>
          <p
            className={`${Styles.v_manage_travel_agent_edit_data_form_hide_field_column}, ${Styles.v_manage_travel_agent_edit_data_form_title}
            `}
          >
            {t("CREATE_TRAVEL_AGENT.FORM_COLUMN_HIDE_FIELD_TITLE_TEXT")}
          </p>
          <p
            className={`${Styles.v_manage_travel_agent_edit_data_form_multiselect_field_column}, ${Styles.v_manage_travel_agent_edit_data_form_title}
            `}
          >
            {t("CREATE_TRAVEL_AGENT.FORM_COLUMN_MULTISELECT_FIELD_TITLE_TEXT")}
          </p>
        </div>
      
        {fields.map((value: any, index: any) => {
          return (
            <div
              key={value.id}  
              className={Styles.v_manage_travel_agent_edit_data_form_item_group}
            >
              <div
                className={Styles.v_manage_travel_agent_edit_data_form_field_column_item}
              >
                {value.hasContent ?
                <Tooltip title={t('CREATE_TRAVEL_AGENT.TOOLTIP')}>
                  <p>{value.mandatory ? `${value.name}*`  : value.name}</p>
                </Tooltip>
                : 
                  <p>{value.mandatory ? `${value.name}*`  : value.name}</p>
                }
              </div>
              {value.cocli ?
                <div className={Styles.v_manage_travel_agent_edit_data_form_content_column_item}>
                  <div className={Styles.v_manage_travel_agent_edit_data_form_content_column_item_values}>
                    <CButton 
                      placeholder={t("MANAGE_COCLIS.LIST.BTN_ASOC_COCLI")} 
                      type="secondary"
                      height={36} 
                      width={155}
                      icon={addIcon} 
                      action={() => {
                        props.openModal(true, "selectCocli", 
                        {
                          cocliList: taData ? taData.find((ta: any) => ta.field_id === value.id).coclis : [], 
                          allCoclis: taData ? taData.find((ta: any) => ta.field_id === value.id).all_coclis : false,
                          cocliListObjs: taData ? taData.find((ta: any) => ta.field_id === value.id).coclis_objs : [], 
                          multiselect: true
                        }, false, (allCoclis: any, cocliList: any, cocliListCodes: any) => {

                          if(taData){
                            let tmpTAData = taData;
                            tmpTAData = tmpTAData.map((ta: any) => {
                              if(ta.field_id === value.id){
                                ta.coclis = cocliList;
                                ta.all_coclis = allCoclis;
                                let tmp = cocliListCodes;
                                ta.coclis_objs = tmp;
                                tmp = tmp.map((c: any) => c.name);
                                let tmpNames = tmp.join('@@');
                                // setCocliListNames(tmpNames);
                                ta.cocliListNames = tmpNames;
                                if(tmpNames.length > 30){
                                  tmpNames = tmpNames.substring(0,30).concat('...');
                                }
                                ta.cocliListCodes = tmpNames;
                                // setCocliListCodes(tmpNames);
                              }
                              return ta;
                            });    
                            setTAData(tmpTAData);
                          }
                          props.openModal(false, "selectCocli");
                        }, 
                        () => {
                          props.openModal(false, "selectCocli");
                        }, false, null, null);
                      }}
                    />
                    <input
                      className="appearance-none border rounded-[10px] w-full h-[36px] py-2 px-3 text-[#1b1b1e] leading-tight focus:outline-none focus:shadow-outline text-[14px] font-normal"
                      type="text"
                      title={taData ? taData.find((ta: any) => ta.field_id === value.id).cocliListNames : ''}
                      placeholder={value.name}
                      value={taData ? taData.find((ta: any) => ta.field_id === value.id).cocliListCodes : ''}
                      disabled
                      readOnly
                    />
                  </div>

                </div>
              : value.hasContent ?
                <div
                  className={
                    Styles.v_manage_travel_agent_edit_data_form_content_column_item
                  }
                >
                  <input
                    className="appearance-none border rounded-[10px] w-full h-[30px] py-2 px-3 text-[#1b1b1e] leading-tight focus:outline-none focus:shadow-outline text-[14px] font-normal"
                    type="text"
                    placeholder={t('CREATE_TRAVEL_AGENT.PLACEHOLDER')}
                    value={taData ? taData.find((ta: any) => ta.field_id === value.id).content : ''}
                    onChange={(e: any) => {
                      if(taData){
                        let tmpTAData = taData;
                        tmpTAData = tmpTAData.map((ta: any) => {
                          if(ta.field_id === value.id){
                            ta.content = e.target.value;
                          }
                          return ta;
                        });    
                        setTAData(tmpTAData);
                      }
                    }}
                  />
                </div>
                : null
              }
              <div
                className={
                  Styles.v_manage_travel_agent_edit_data_form_mandatory_column_item
                }
              >
                <input
                  type="checkbox"
                  className="accent-[#3498f5] h-[16px] w-[16px]"
                  checked={
                    value.mandatory ?
                    true
                    :
                    (taData ? taData.find((ta: any) => ta.field_id === value.id).mandatory : false)
                  }
                  disabled={value.mandatory}
                  onChange={() => {handleMandatory(value)}}              
                  />
                {t("CREATE_TRAVEL_AGENT.FORM_CHECKBOX_TEXT")}
              </div>
              <div
                className={
                  Styles.v_manage_travel_agent_edit_data_form_hide_field_column_item
                }
              >
                <input
                  type="checkbox"
                  className="accent-[#3498f5] h-[16px] w-[16px]"
                  checked={taData ? taData.find((ta: any) => ta.field_id === value.id).hide : false}
                  disabled={value.mandatory ? true : (taData ? taData.find((ta: any) => ta.field_id === value.id).mandatory : false)}
                  onChange={() => {handleHide(value)}} 
                />
                {t("CREATE_TRAVEL_AGENT.FORM_CHECKBOX_TEXT")}
              </div>
              {value.hasContent &&
                <div
                  className={
                    Styles.v_manage_travel_agent_edit_data_form_multiselect_field_column_item
                  }
                >
                  <input
                    type="checkbox"
                    className="accent-[#3498f5] h-[16px] w-[16px]"
                    checked={taData ? taData.find((ta: any) => ta.field_id === value.id).multiselect : false}
                    disabled={
                      value.cocli && 
                      ((taData && taData.find((ta: any) => ta.field_id === value.id).coclis.length > 1) || 
                      taData.find((ta: any) => ta.field_id === value.id).all_coclis) ? false :                      (!taData.find((ta: any) => ta.field_id === value.id).content && 
                      taData.find((ta: any) => ta.field_id === value.id).content === '' ? 
                      true 
                      : 
                      (taData.find((ta: any) => ta.field_id === value.id).content && 
                      taData.find((ta: any) => ta.field_id === value.id).content.split(',').length > 1 ? false : true))}

                    onChange={() => {handleMultiSelect(value)}} 
                  />
                  {t("CREATE_TRAVEL_AGENT.FORM_CHECKBOX_TEXT")}
                </div>
              }
            </div>
          )
        })}
        <div className={Styles.v_manage_travel_agent_edit_data_form_button}>
          {clientName &&
          nif &&
          name &&
          surname &&
          email &&
          !formatNameError &&
          !formatSurnameError ? (
            <CButton placeholder={t("MANAGE_TRAVEL_AGENTS.EDIT.FORM_BUTTON")} action={updateAgent}/>
          ) : (
            <CButton placeholder={t("MANAGE_TRAVEL_AGENTS.EDIT.FORM_BUTTON")} type="disabled" />
          )}
        </div>
      </div>
    : null
  );
}
