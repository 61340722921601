import axios from 'axios';
import {BASE_URL_EXPORTS, TIMEOUT} from '../utils/s-constants';
import FormData from 'form-data';

class Exports {
    static downloadExcelTemplate = (id: string, navigate: any, filename: string) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios({
            // .create()
            // .request({
            url: `/api/v1/excel/${id}`,
            method: 'GET',
            baseURL: BASE_URL_EXPORTS,
            headers: newHeaders,
            timeout: TIMEOUT,
            responseType: 'blob'
            })
            .then(async res => {
                const url = window.URL.createObjectURL(new Blob([res.data],{type:'application/xlsx'}));
                const link: any = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                await link.click();
                await link.parentNode.removeChild(link);
                resolve({req: res});
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    };
    static generateExcelTemplate = (idOrg: number, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/excel/${idOrg}/template`,
            method: 'GET',
            baseURL: BASE_URL_EXPORTS,
            headers: newHeaders,
            timeout: TIMEOUT,
            })
            .then(async req => {
                 await this.downloadExcelTemplate(req.data.data.id, navigate, req.data.data.filename);
                resolve({});
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    };
    static uploadExcel = (idOrg: number, file: any, navigate: any) => {

        let formData = new FormData();
        formData.append("file", file);

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/excel/${idOrg}/upload`,
            method: 'POST',
            baseURL: BASE_URL_EXPORTS,
            headers: newHeaders,
            timeout: 15000,
            maxBodyLength: Infinity,
            data: formData,
            })
            .then(async req => {
                const {data} = req;
                resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    };
    static validateExcel = (idOrg: number, id: number, valid_import: boolean, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/excel/${idOrg}/valid/${id}`,
            method: 'POST',
            baseURL: BASE_URL_EXPORTS,
            headers: newHeaders,
            timeout: TIMEOUT,
            data: {valid_import}
            })
            .then(async req => {
                const {data} = req;
                resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    };
    static generateExcelFullDB = (idOrg: number, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/excel/${idOrg}/file`,
            method: 'GET',
            baseURL: BASE_URL_EXPORTS,
            headers: newHeaders,
            timeout: TIMEOUT,
            })
            .then(async req => {
                await this.downloadExcelTemplate(req.data.data.id, navigate, req.data.data.filename);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    };
    static generateRecoveryUpdates = (idOrg: number, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/excel/${idOrg}/restore`,
            method: 'GET',
            baseURL: BASE_URL_EXPORTS,
            headers: newHeaders,
            timeout: TIMEOUT,
            })
            .then(async req => {
                const {data} = req;
                resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    };
    static recoveryUpdates = (idOrg: number, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
            url: `/api/v1/excel/${idOrg}/restore`,
            method: 'PUT',
            baseURL: BASE_URL_EXPORTS,
            headers: newHeaders,
            timeout: TIMEOUT,
            })
            .then(async req => {
                const {data} = req;
                resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    };
}
export default Exports;