import { useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";

import Styles from "./c-user-drop.module.css";

import editIcon from "../../assets/icon-edit.svg";
import arrowIcon from "../../assets/icon-arrow-3.svg";

import { t } from "i18next";

export default function CUserDrop(props: any) {
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef<any>();

  const dp_options = [
    {
      id: 0,
      name: t('USER.DROPDOWN.EDIT_AVATAR'),
      icon: editIcon,
      action: 'showEditModalAvatar'
    },
  ]

  const handleOptions = () => {    
    setShowOptions(!showOptions);
  };

  const callAction = (action: any) => {
    switch(action) {
        case "showEditModalAvatar": {
            return (
                props.openModal(true, "editModalAvatar", null, false, null, () => {
                  props.openModal(false, "editModalAvatar");
                })
            );
        }
    }
  }

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowOptions(false);
      }
    }
        
    if(showOptions){
      document.removeEventListener('click', checkIfClickedOutside);
    }else{
      document.addEventListener('click', checkIfClickedOutside);
    }
  },[showOptions])

  return (
    <div>
      <div
        className={Styles.c_user_drop_selected}
        ref={ref}
        onClick={() => handleOptions()
        }
      >
        {props.avatar ?
          <img
              src={`data:;base64,${props.avatar}`}            
              alt="avatar"
              className={Styles.c_user_drop_avatar}
          />
          : null
        }
        <div className={Styles.c_user_drop_user}>
            <p className={Styles.c_user_drop_roll}>{t(props.values.rol)}</p>
            <div className={Styles.c_user_drop_name_group} onClick={() => {}}>
                <p className={Styles.c_user_drop_name}>{props.values.name + ' ' + props.values.lastname}</p>
                <ReactSVG src={arrowIcon} />
            </div>
        </div>
      </div>
      {showOptions === true ? (
        <div className={Styles.c_user_main_drop_options}>
            <div className={Styles.c_user_drop_options}>
            {dp_options.map((l: any) => (
                <div
                key={l.id}
                className={Styles.c_user_drop_options_item}
                onClick={() => {callAction(l.action)}}
                >
                <ReactSVG src={l.icon} />
                <p>{l.name}</p>
                </div>
            ))}
            </div>
        </div>
      ) : null}
    </div>
  );
}
