import axios from 'axios';
import {BASE_URL, TIMEOUT} from '../utils/s-constants';

class Schedule {
    static getSchedule = (navigate: any, sort?: any, order?: any, page?: any, status?: any, travel_agent?: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };
        
        let params: any = {}
        let data: any = {
          status,
          travel_agent
        };

        if(page){
          params.pageNumber = page;
        }
        if(sort){
          params.sortedBy = sort;
        }
        if(order){
          params.orderBy = order.toLocaleUpperCase();
        }

        return new Promise((resolve, reject) => {
            axios
              .create()
              .request({
                url: '/api/v1/schedule',
                method: 'POST',
                baseURL: BASE_URL,
                headers: newHeaders,
                timeout: TIMEOUT,
                params,
                data,
              })
              .then(async req => {
                const {data} = req;
                resolve(data);
              })
              .catch(error => {
                console.log(error);
                if(error.response){
                  if(error.response.status === 401){
                      localStorage.clear();
                      return navigate('/')
                  }
                  else if (error.response.status === 500){
                      return navigate('/500')
                  }
                }
              reject({error});
              });
          });
    };
    static updateSchedule = (info: any, del?: any, active?: any, navigate?: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };
        let data: any = {}

        if(info){
          data.frequency = info.frequency.id;
          data.time = info.hour.id;
        }
        if(del){
          data.delete = del;
        }
        // if(active){
          data.active = active;
        // } 

        return new Promise((resolve, reject) => {
            axios
              .create()
              .request({
                url: `/api/v1/schedule/${info.id}`,
                method: 'PUT',
                baseURL: BASE_URL,
                headers: newHeaders,
                timeout: TIMEOUT,
                data,
              })
              .then(async req => {
                const {data} = req;
                resolve(data);
              })
              .catch(error => {
                console.log(error);
                if(error.response){
                  if(error.response.status === 401){
                      localStorage.clear();
                      return navigate('/')
                  }
                  else if (error.response.status === 500){
                      return navigate('/500')
                  }
              }
              reject({error});
              });
          });
    };
    static createSchedule = (frequency: number, time: number, userOrganizationId: number, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
            axios
              .create()
              .request({
                url: '/api/v1/schedule/new',
                method: 'POST',
                baseURL: BASE_URL,
                headers: newHeaders,
                timeout: TIMEOUT,
                data: {
                   frequency,
                   time,
                   userOrganizationId
                },
              })
              .then(async req => {
                const {data} = req;
                resolve(data);
              })
              .catch(error => {
                console.log(error);
                if(error.response){
                  if(error.response.status === 401){
                      localStorage.clear();
                      return navigate('/')
                  }
                  else if (error.response.status === 500){
                      return navigate('/500')
                  }
              }
              reject({error});
              });
          });
    };
    static retrySchedule = (id: number, navigate: any) => {

      let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

      return new Promise((resolve, reject) => {
          axios
            .create()
            .request({
              url: `/api/v1/schedule/${id}/retry`,
              method: 'GET',
              baseURL: BASE_URL,
              headers: newHeaders,
              timeout: TIMEOUT,
            })
            .then(async req => {
              const {data} = req;
              resolve(data);
            })
            .catch(error => {
              console.log(error);
              if(error.response){
                if(error.response.status === 401){
                    localStorage.clear();
                    return navigate('/')
                }
                else if (error.response.status === 500){
                    return navigate('/500')
                }
            }
            reject({error});
            });
        });
  };
}
export default Schedule;