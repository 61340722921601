let instance: any;
let user: any;

export default class UserInstance {
  constructor() {
    if (instance) {
      return this;
    }
    instance = this;
  }

  getInstance() {
    return this;
  }

  getUser() {
    if(!user){
      user = JSON.parse(localStorage.getItem("user")  || '');
    }
    return user;
  }

  setUser(value: any) {
    user = value;
  }
}
