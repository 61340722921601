import axios from 'axios';
import {BASE_URL, TIMEOUT} from '../utils/s-constants';

class Fields {
    static getFields = (id: number, navigate: any) => {

        let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };

        return new Promise((resolve, reject) => {
        axios
            .create()
            .request({
                url: `/api/v1/selectedfield/${id}`,
                method: 'GET',
                baseURL: BASE_URL,
                headers: newHeaders,
                timeout: TIMEOUT,
            })
            .then(async req => {
                const {data} = req;
                resolve(data);
            })
            .catch(error => {
                console.log(error);
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.clear();
                        return navigate('/')
                    }
                    else if (error.response.status === 500){
                        return navigate('/500')
                    }
                }
                reject({error});
            });
        });
    }
}
export default Fields;