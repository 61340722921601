import axios from 'axios';
import {BASE_URL, TIMEOUT, defaultHeaders} from '../utils/s-constants';
class Auth {
    static doLogin = async (email: string, password: string) => {

        const credentials = {
          email,
          password
        }
    
        return new Promise((resolve, reject) => {
            axios
              .create()
              .request({
                url: '/public/api/v1/auth/login',
                method: 'POST',
                baseURL: BASE_URL,
                headers: defaultHeaders,
                timeout: TIMEOUT,
                params: {},
                data: credentials,
              })
              .then(async req => {
                const {data} = req;
                localStorage.setItem("user_token", data.data.token);
                resolve(data);
              })
              .catch(error => {
                  const data = error?.response.data
                  reject({data});
              });
          });
    };
    static doRefresh = async () => {

      let newHeaders =  {'Authorization': `Bearer ${localStorage.getItem("user_token")}` };
    
        return new Promise((resolve, reject) => {
            axios
              .create()
              .request({
                url: '/api/v1/auth/refresh',
                method: 'GET',
                baseURL: BASE_URL,
                headers: newHeaders,
                timeout: TIMEOUT,
                params: {},
              })
              .then(async req => {
                const {data} = req;
                await localStorage.removeItem("user_token");
                await localStorage.setItem("user_token", data.data.token);
                resolve(data);
              })
              .catch(error => {
                const data = error?.response.data
                reject({data});
              });
          });
    };
    static doLogout = async (navigate: any) => {
        localStorage.removeItem('user_token');
        localStorage.removeItem('user');
        navigate('/');
    }
    static requestChangePassword = async (email: string) => {

      const credentials = {
        email
      }

      return new Promise((resolve, reject) => {
        axios
          .create()
          .request({
            url: '/public/api/v1/auth/change_password/validate',
            method: 'POST',
            baseURL: BASE_URL,
            headers: defaultHeaders,
            timeout: TIMEOUT,
            params: {},
            data: credentials,
          })
          .then(async req => {
            const {data} = req;
            resolve(data);
          })
          .catch(error => {
            console.log(error)
            const data = error?.response.data
            reject({data});
          });
      });
    }
    static changePassword = async (email: string, new_password: string, old_password: string) => {

      const credentials = {
        email, 
        new_password,
        old_password
      }

      return new Promise((resolve, reject) => {
        axios
          .create()
          .request({
            url: '/public/api/v1/auth/change_password/change',
            method: 'POST',
            baseURL: BASE_URL,
            headers: defaultHeaders,
            timeout: TIMEOUT,
            params: {},
            data: credentials,
          })
          .then(async req => {
            const {data} = req;
            resolve(data);
          })
          .catch(error => {
            const data = error?.response.data
            reject({data});
          });
      });
    }
}
export default Auth;