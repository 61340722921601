import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Styles from "./v-manage-passengers.module.css";
import CDropDown from "../../components/c-dropdown/c-dropdown";

import VManagePassengersList from "./v-manage-passengers-list/v-manage-passengers-list";
import TravelAgent from "../../services/travel-agent/s-travel-agent";
import Passengers from "../../services/passengers/s-passengers";
import { useNavigate } from "react-router-dom";

export default function VManagePassengers(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true);
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [travelAgent, setTavelAgent] = useState(false);
  const [passegersList, setPassengersList] = useState<any>();
  const [showNewView, setShowNewView] = useState(false);
  const [showActualView, setActualView] = useState(true);
  const [travelAgentsWOrg, setTravelAgentsWOrg] = useState(false);

  const callPassengerList = (id: any) => {
    setEnableSaveButton(true);
      Passengers.getPassengersByTravelAgent(id, {}, navigate)
      .then((resp: any) => {
        setPassengersList(resp.data);
        setShowNewView(true);
        setActualView(false);
      })
      .catch((err: any) => {
        props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
      });
  }

  const callTravelManagers = () => {
    TravelAgent.getTravelAgentsWOrg(navigate).then((resp: any) => {
      let agents = resp.data;
      agents = agents.map((value: any) => {
        return {
          id: value.id,
          name: `${value.user.name} ${value.user.lastName} (${value.name})`
        }
      });            
      setTravelAgentsWOrg(agents);
      setLoading(false);
    }).catch((err: any) => {
      props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
    });
  }

  useEffect(() => {
    if(loading){
      callTravelManagers();
    }
  });

  return (
    <div>
      {!loading && showNewView && 
        <VManagePassengersList 
          back={props.back}
          openModal={props.openModal}
          data={passegersList} 
          travelAgents={travelAgentsWOrg} 
          travelAgentSelected={travelAgent}
        />
      }
      {!loading && showActualView && 
        <div className={Styles.v_manage_passengers}>
          <div className={Styles.v_manage_passengers_title_group}>
            <h1 className={Styles.v_manage_passengers_title}>
              {t("MANAGE_PASSENGERS.FIRST_FORM_TITLE")}
            </h1>
            <p className={Styles.v_manage_passengers_subtitle}>
              {t("MANAGE_PASSENGERS.FIRST_FORM_SUBTITLE")}
            </p>
          </div>

          <div className={Styles.v_manage_passengers_group}>
            <label className={Styles.v_manage_passengers_title_finder}>
              {t("MANAGE_PASSENGERS.PLACEHOLDER_DROPDOWN")}
            </label>
            <CDropDown 
              placeholder={t("MANAGE_PASSENGERS.PLACEHOLDER_DROPDOWN")} 
              secondaryPlaceholder={t("MANAGE_PASSENGERS.PLACEHOLDER_SEARCH")}
              titleButton={t("MANAGE_PASSENGERS.FINDER_BUTTON")}
              finder
              className="w-[100%] sm:w-[326px]"
              options={travelAgentsWOrg} 
              selected={travelAgentsWOrg} 
              setSelected={(value: any) => {setTavelAgent(value)}}
              action={(value: any) => {callPassengerList(value.id)}}
            />
          </div>
        </div>
      }
    </div>
  );
}
