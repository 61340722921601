import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Styles from "./v-manage-coclis-edit.module.css";
import deleteIcon from "../../../assets/icon-delete.svg";
import CTextInput from "../../../components/c-text-input/c-text-input";
import { CButton } from "../../../components/c-button/c-button";

import { CocliModel } from "../../../model/m-cocli";
import { useNavigate } from "react-router-dom";
import Cocli from "../../../services/cocli/s-cocli";

export default function VManageCoclisEdit(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const alphaFormat = /^([a-zA-Z ]+)$/
  const [cocliCode, setCocliCode] = useState("");
  const [cocliName, setCocliName] = useState("");

  const [formatCocliNameError, setFormatCocliNameError] = useState(false);
  const [formatCocliError, setFormatCocliError] = useState(false);
  
  const [cocli, setCocli] = useState(new CocliModel());
  const [loading, setLoading] = useState(true);

  const deleteCocli = () => {
    Cocli.deleteCocli(props.cocliId, navigate)
    .then((resp: any) => {
      props.openModal(true, "modalResponseSuccess", null, false, () => {
        props.back();
        props.from(false);
        props.openModal(false, "modalResponseSuccess");
      }, null, false, null, resp.result.info);
    }).catch((err: any) => {
      props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, err.error.response.data.result.info);
    });
  }

  const saveData = () => {
    cocli.code = cocliCode;
    cocli.name = cocliName;
  }

  const updateCocli = () => {
    saveData();
    Cocli.updateCocli(props.cocliId, cocli, navigate)
    .then((resp: any) => {
      props.openModal(true, "modalResponseSuccess", null, false, () => {
        props.back();
        props.from(false);
        props.openModal(false, "modalResponseSuccess");
      }, null, false, null, resp.result.info);
    })
    .catch((error: any) => {
      props.openModal(true, "failed")
    });
  };

  const updateName = (data: any) => {
    setCocliName(data);
  }
  
  const updateCode = (data: any) => {
    setCocliCode(data);
    if (alphaFormat.test(data)){
      setFormatCocliError(true);
    }else{
      setFormatCocliError(false);
    }
  }
  
  useEffect(() => {
    if(loading){
      Cocli.getCocliDetail(props.cocliId, navigate)
      .then((resp: any) => {
        setCocliName(resp.data.name);
        setCocliCode(resp.data.code);
        setLoading(false);
      })
      .catch(() => {
        props.openModal(true, "modalResponseFailed", null, false, null, null, false, null, "RESPONSE.ERROR");
      })
    }
  },[cocli, loading, navigate, props]);

  return (
    !loading ?
      <div className={Styles.v_coclis_edit}>
        <div className={Styles.v_coclis_edit_title_group}>
          <div className={Styles.v_coclis_edit_title_subgroup}>
            <h1 className={Styles.v_coclis_edit_title}>
              {t("MANAGE_COCLIS.EDIT.FIRST_FORM_TITLE")}
            </h1>
            <CButton
              placeholder={t("MANAGE_COCLIS.EDIT.BTN_DELETE")} 
              icon={deleteIcon} 
              type="text" 
              action={()=> {
                props.openModal(true, "cocliDelete", null, false, 
                () => {deleteCocli()}, 
                () => {props.openModal(false, "passengerDelete")}, true)}
              }
            />
            </div>
          <p className={Styles.v_coclis_edit_subtitle}>
            {t("MANAGE_COCLIS.EDIT.FIRST_FORM_SUBTITLE")}
          </p>
          <div className={Styles.v_coclis_edit_input_group}>
          </div>
        </div>
        <div className={Styles.v_coclis_edit_form}>
          <div className={Styles.v_coclis_edit_form_group}>
            <div className={Styles.v_coclis_edit_input_group}>
              <label
                className={Styles.v_coclis_edit_input_label}
                htmlFor="cocli-name"
              >
                {t("MANAGE_COCLIS.EDIT.FIRST_FORM_COCLI_NAME_LABEL")}*
              </label>
              <CTextInput
                id="cocli-name"
                type="text"
                placeholder={t("MANAGE_COCLIS.EDIT.FIRST_FORM_COCLI_NAME_LABEL")}
                value={cocliName}
                setValue={updateName}
              />
            </div>
            <div className={Styles.v_coclis_edit_input_group}>
              <label
                className={Styles.v_coclis_edit_input_label}
                htmlFor="cocli-code"
              >
                {t("MANAGE_COCLIS.EDIT.FIRST_FORM_COCLI_CODE_LABEL")}*
              </label>
              <CTextInput
                id="cocli-code"
                type="text"
                placeholder={t("MANAGE_COCLIS.EDIT.FIRST_FORM_COCLI_CODE_LABEL")}
                value={cocliCode}
                setValue={updateCode}
              />
            </div>
          </div>
        </div>
        <div className={Styles.v_coclis_edit_data_form_button}>
          {cocliCode &&
          cocliName &&
          cocliName !== "" &&
          !formatCocliNameError &&
          !formatCocliError ? (
            <CButton placeholder={t("MANAGE_COCLIS.EDIT.FORM_BUTTON")} action={updateCocli}/>
          ) : (
            <CButton placeholder={t("MANAGE_COCLIS.EDIT.FORM_BUTTON")} type="disabled" />
          )}
        </div>
      </div>
    : null
  );
}
